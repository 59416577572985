import axios from 'axios'

export async function post(url, data, jwt_flg=false, hdr={}, method='post'){
	let headers = {
		'Content-Type'     : 'application/json',
		'pragma'           : 'no-cache',
		'Cache-Control'    : 'no-cache',
		'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT',
	}
	if(jwt_flg){
		let jwt = localStorage.getItem('jwt')
		headers['SP-Auth'] = 'BEARER ' + jwt
	}

	Object.keys(hdr).forEach(key => headers[key] = hdr[key])

	return await axios({
		method: method,
		url:  url + '.json',
		data: data,
		headers: headers,
		responseType: 'json',
	})
	.then(function(res){
		//todo セッションタイムアウト
		return res.data
	})
	.catch(function(e){
		console.warn(e)
		if(e.response.status == 401){
			location.href = '/site_401.html'
		}else if(e.response.data.code){
			return e.response.data
		}else{
			return {code: 500, message: 'ネットワークの接続に失敗しました。再度実施してください。'}
		}
	})
}

/*3桁カンマ区切り*/
export function comma(num, dispDec=0) {
	let strs = String(num).split('.')
	let str_int = strs[0].replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
	let zeroPad = '0'.repeat(dispDec)
	if(dispDec == 0){
		return str_int
	}else{
		let str_dec = strs.length < 2 ? zeroPad : (strs[1] + zeroPad).slice(0, dispDec)
		//加工用に配列で返す
		return [str_int, str_dec]
	}
}

/*日付フォーマット*/
export function dtfmt(dt){
	let d = new Date(dt)
	let y = d.getFullYear()
	let m   = '0' + (d.getMonth() + 1)
	let day = '0' + d.getDate()
	return y + '-' + m.substr(-2) + '-' + day.substr(-2)
}

/*日付フォーマット2*/
export function dtfmt2(dnum){
	let now = new Date()
	//秒
	let s = parseInt(now.getTime()/1000) - dnum
	if(s < 20) return 'now'
	//分
	if(s < 3600) return (parseInt(s / 60) + 1) + '分前'
	//時間
	if(s < 86400) return (parseInt(s / 60 / 60) + 1) + '時間前'
	//日付
	let d = new Date(dnum*1000)
	let mi = ('0' + d.getMinutes()).slice(-2)
	return d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日 ' + d.getHours() + ':' + mi
}

/*絵文字チェック*/
export function isEmoji(str){
	let ranges = [
		'\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
		'\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
		'\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
	];
	if(str.match(ranges.join('|'))){
		return true;
	} else {
		return false;
	}
}

/*CSVファイルダウンロード*/
export function download(data, name){
	// utf8
	const bom = '\uFEFF';

	const blob = new Blob([bom, data], { type: 'text/csv' });
	const anchor = document.createElement('a');

	// ie
	if(window.navigator.msSaveBlob){
		window.navigator.msSaveBlob(blob, name);
	// chrome, firefox, etc.
	}else if(window.URL && anchor.download !== undefined){
		anchor.download = name;
		anchor.href = window.URL.createObjectURL(blob);
		document.body.appendChild(anchor);
		anchor.click();
		anchor.parentNode.removeChild(anchor);
  }else{
		window.location.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom + data);
	}
}

/*銘柄マスタを取得・キャッシュ・オブジェクト化*/
export async function get_products(ary_flg=true){
	let products = JSON.parse(sessionStorage.getItem('products'))
	//sessionStorageになければ取得してsessionStorageに保存する
	if(products == null){
		//1件で取得すること（sessionStorageには入れない）
		let res = await post('/webapi/client/products', {}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		sessionStorage.setItem('products', JSON.stringify(res.list))
		products = res.list
	}
	//配列のまま返す場合はそのまま返す
	if(ary_flg) return products

	//オブジェクト化
	let obj = {}
	for(let i=0,l=products.length; i<l; i++){
		let p = products[i]
		obj[p.c_code] = p
	}
	return {ary: products, obj: obj}
}

/*ユーザ情報を取得してsessionStorageに入れる*/
export async function get_user(force_flg=false){
	let user = null
	if(!force_flg){
		let d = sessionStorage.getItem('user')
		if(d != null) user = JSON.parse(d)
	}
	if(user == null){
		let res = await post('/webapi/client/user', {}, true)
		if(res.code == 0){
			user = res.user
			sessionStorage.setItem('user', JSON.stringify(user))
		}
	}
	return user
}

/*SDGsカテゴリを取得してsessionStorageに入れる*/
export async function get_sdgs_categories(){
	//sdgsCategories
	if(sessionStorage.getItem('sdgsCategories') == null){
		let res = await post('/webapi/client/sdgs/category', {}, true)
		if(res.code != 0){
			return []
		}
		sessionStorage.setItem('sdgsCategories', JSON.stringify(res.list))
	}
	return JSON.parse(sessionStorage.getItem('sdgsCategories'))
}

/*お知らせを取得してsessionStorageに入れる、最新の日付はlocalStorageに入れる->クライアントでやる*/
export async function get_news(force_flg=false){
	let news = null
	if(!force_flg){
		let d = sessionStorage.getItem('news')
		if(d != null)  news = JSON.parse(d)
	}
	if(news == null){
		let res = await post('/webapi/client/news/index', {}, true)
		if(res.code == 0){
			news = res.list
			sessionStorage.setItem('news', JSON.stringify(news))
		}
	}
	return news
}

/*titleやdescriptionを更新*/
export function updateMetaHead(title='', desc='') {
	let myTitle = '愛され企業さーち | Powered by STOCKPOINT'
	let myDesc = '「愛され企業さーち」は、企業のサービスや製品、またその企業が取り組む社会貢献についてご紹介しているユーザー参加型のウェブサイトです。'
	if (title != '') {
		myTitle = `${title} | 愛され企業さーち | Powerd by STOCKPOINT`
	}
	if (desc != '') {
		myDesc = desc
	}
	document.title = myTitle
	document.querySelector("meta[property='og:title']").setAttribute('content', myTitle)
	document.querySelector("meta[name='description']").setAttribute('content', myDesc)
	document.querySelector("meta[property='og:description']").setAttribute('content', myDesc)
}

