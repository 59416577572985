<template>
<div class="footer">

	<p class="title">運営会社</p>
	<a class="normalLink name" href="https://www.stockpoint.co.jp/" target="_blank">
    <span>STOCK POINT株式会社</span><img width="14" height="14" alt="" :src="$cdn + '/img/main/launch-24px.svg'">
  </a>

	<p class="address">東京都港区六本木1-4-5 アークヒルズサウスタワー16F WeWork</p>
	<div class="links">
		<router-link class="normalLink" :to="{ name: 'Terms' }">利用規約</router-link>
		<a class="normalLink" href="https://www.stockpoint.co.jp/privacy.html" target="_blank">プライバシーポリシー</a>
		<a class="normalLink" href="https://stockpoint5969.zendesk.com/hc/ja" target="_blank">お問い合わせ</a>
	</div>

	<p class="copy">Copyright&copy; STOCK POINT Inc. All Rights Reserved.</p>
</div>
</template>

<style scoped>
.footer{
	width: 100%;
	padding: 20px;
	background-color: #F8F8F8;
	margin-top: 40px;
}
.title{
	font-size: 12px;
	font-weight: 600;
	line-height: 22px;
}
.name{
	cursor: pointer;
}
.name span{
	color: var(--col-main);
	font-size: 12px;
	font-weight: 600;
	line-height: 22px;
	vertical-align: middle;
	margin-right: 5px;
}
.name img{
	height: 14px;
}
.address{
	font-size: 10px;
	font-weight: 300;
	line-height: 22px;
}
.links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.links a{
  display: inline-block;
	font-size: 10px;
	font-weight: 300;
	line-height: 22px;
	margin-right: 10px;
	color: var(--col-main);
	cursor: pointer;
}
.sns {
  display: flex;
  flex-wrap: nowrap;
}
.snsIcon {
  width: auto;
  height: auto;
  border: none;
  background: none;
  line-height: initial;
  max-width: initial;
  margin: 0;
  display: inline-block;
  padding: 8px;
}
.snsIcon_img {
  width: 32px;
  height: 32px;
}
.copy{
	font-size: 8px;
	color: #999999;
	line-height: 24px;
}
</style>

<script>
export default {
	methods: {
	},
}
</script>
