<template>
<div class="main">

	トリビア1

</div>
</template>

<style scoped>
</style>


<script>
import {post} from '../../../../lib/utils'

export default {
	data: function(){
		return {
			cdn: CDN,
		}
	},

	mounted: function(){
		console.log('mounted')
	},

	methods: {
	},

	components: {
	},
}
</script>
