<template>
<div class="wrapper">
	<div class="header">
		<div class="icon">
			<img  v-lazy="l.icon != 10 ? $cdn + '/img/main/talk_icon' + l.icon + '.svg' : l.icon_url">
		</div><!--icon-->
		<div class="hWrp">
			<p class="nickname">{{l.nickname.length > 18 ? l.nickname.slice(0,18) + '...' : l.nickname}}</p>
			<p class="themeWrp">
				<span class="dt">{{dtFmt()}}</span></span>
			</p>
		</div>
	</div><!--header-->

	<div class="main">
		<p class="name"><span @click="linkTo">{{l.product.name}}</span>へのコメント</p>
		<pre class="comment"><img :src="$cdn + '/img/main/c_theme_flg' + l.c_theme + '.png'">{{l.comment.slice(0, 99)}}{{l.comment.length >= 100 ? '...' : ''}}</pre>
	</div><!--main-->

	<Favorite :favs="favs" :myMark="myMark" @fav="fav" />

</div>
</template>

<style scoped>
.wrapper{
	width: 355px;
	height: 240px;
	text-align: center;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
}
.header{
	height: 50px;
}
.icon{
	position: relative;
	display: block;
	float: left;
	margin-top: 10px;
	margin-left: 10px;
	width: 32px;
	height: 32px;
	object-fit: cover;
}
.icon img{
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
}
.hWrp{
	display: block;
	float: left;
	width: calc(100% - 52px);
	margin-top: 10px;
	margin-left: 10px;
}
.nickname{
	display: block;
	text-align: left;
	color: #333333;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}
.themeWrp{
	line-height: 14px;
	text-align: left;
}
.dt{
	font-size: 10px;
	line-height: 14px;
	opacity: 0.6;
}
.main{
	height: calc(100% - 50px - 32px - 10px);
	overflow: hidden;
	padding: 10px;
}
.name{
	height: 28px;
	line-height: 28px;
	background-color: #F8F8F8;
	padding-left: 10px;
	font-size: 14px;
	text-align: left;
}
.name span{
	font-size: 14px;
	line-height: 28px;
	color: var(--col-main);
	cursor: pointer;
}
pre.comment{
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	white-space: pre-wrap;
}
pre.comment>img{
	height: 20px;
	margin-right: 5px;
}
</style>


<script>
import {dtfmt2} from '../../../lib/utils'
import Favorite from '../../components/Favorite3.vue'

export default {
	props: {
		l: {type: Object, default: {} },
	},

	watch: {
		l: function(n, o){
			this.favs = n.favs
			this.myMark = n.my_mark
		},
	},

	data: function(){
		return {
			favs: 0,
			myMark: -1,
		}
	},

	mounted: async function(){
		this.favs = this.l.favs
		this.myMark = this.l.my_mark
	},

	methods: {
		linkTo: function(){
			this.$router.push({name: 'SearchDetail', params:{c_code: this.l.c_code}})
			return true
		},

		dtFmt: function(){
			return dtfmt2(this.l.date)
		},

		fav: async function(){
			let d = {
				id: this.l.id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/comment/fav', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			this.favs ++
			this.myMark = 0
		},

	},

	components: {
		Favorite,
	},
}
</script>
