import { comma, get_products, updateMetaHead } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import PostComment from '../../components/PostComment3.vue'
import Comment from '../../components/Comment/Comment.vue'
import SortFilter from '../../components/SortFilter.vue'
import RankingItem from '../../components/RankingItem/RankingItem.vue'

export default {
	data: function(){
		return {
			isReady: false,
			commentList:[], //コメントリスト
			product: {
				topics: [],
				name: '',
			},
			ranking: [],
			page: 0,
			isMore: true,
			replies: {},
			dispNumMax: 0,
		}
	},

	mounted: async function(){
		//銘柄データ取得
		const prdObj = await get_products(false)
		this.product = prdObj.obj[this.$route.params.c_code]

		updateMetaHead(`${this.product.name}へのコメント`)

		this.isReady = true

		//comments
		await this.getComments()

		//ランキング取得
		this.getRanking()

		//無限スクロール
		this.initDispNumMax()
		this.setObserver()
	},

	methods: {
		backToDetail: function(){
			this.$router.push({name: 'SearchDetail', params:{c_code: this.$route.params.c_code}})
		},

		delCb: function(id){
			//クライアントデータを削除
			for(let i=0, l=this.commentList.length; i<l; i++){
				let m = this.commentList[i]
				if(m.id == id){
					this.commentList.splice(i, 1)
					break
				}
			}
		},

		getRanking: async function(){
			//ランキング
			const res = await this.$post('/webapi/client/ranking', {rank_type: 5}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.ranking = res.list.splice(0, 5)
		},

		postDone: async function(params){
			let d = {
				c_code:   this.product.c_code,
				nickname: params.nickname,
				comment:  params.comment,
				c_theme:  params.c_theme,
			}
			const res = await this.$post('/webapi/client/comment/create', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()

			//投稿コメントをリストに追加
			this.commentList.unshift(res.comment)
		},

		comma: function(num){
			return comma(num)
		},

		getComments: async function(){
			let sf = this.$refs.sortFilter
			let d = {
				c_code: this.$route.params.c_code,
				sort:   sf.sort,
				page:   this.page,
				all:    true, //全件取得
			}
			if(sf.cTheme != -1) d.c_theme = sf.cTheme
			let res = await this.$post('/webapi/client/comment/search_detail', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			//10件未満になったらもっとみるを削除
			this.isMore = (res.list.length >= 10)

			//最初のページの場合はクリアする
			if(this.page == 0) this.commentList = []

			for(let i=0, l=res.list.length; i<l; i++){
				this.commentList.push(res.list[i])
			}
			//返信コメント
			const ids = this.commentList.map( (c) => c.id )
			res = await this.$post('/webapi/client/reply/list', {comments_id: ids}, true)
			if(res.code != 0){
				return false
			}
			this.replies = res.replies
		},

		changeSort: async function(){
			this.page = 0
			this.initDispNumMax()
			await this.getComments()
		},

		moreComments: async function(){
			this.page++
			await this.getComments()
		},

		setObserver: function(){
			const handler = (entries) => {
				if (entries[0].isIntersecting) {
					this.addDispNumMax()
				}
			}
			const observer = new window.IntersectionObserver(handler)
			observer.observe(this.$refs.scrollEndPoint)
		},

		addDispNumMax: function(){
			const ADD_NUM = 20
			this.dispNumMax += ADD_NUM
			if (this.dispNumMax > this.commentList.length) {
				this.dispNumMax = this.commentList.length
			}
		},

		initDispNumMax: function(){
			const INITIAL_NUM = 20
			this.dispNumMax = INITIAL_NUM
		},

	},

	components: {
		Header,
		PostComment,
		Comment,
		SortFilter,
		RankingItem,
	},

}
