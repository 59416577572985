let data = [
    [159139,"u1377","orange","良い香りで満たされます","ハーブの種買ってます。庭からタイムやらバジルやら摘めるのは本当に便利だし、良い香りで満たされます。","まるな","サカタのタネ"],
    [159265,"u2002","blue","卵アレルギーのある孫が来ても安心","「コツのいらない天ぷら粉」は卵を使っていないので、卵アレルギーのある孫が来ても安心して使えます。バッターがわりにしてとんかつなんか作るのにも重宝しています。","泉ちゃんの塩","日清製粉グループ本社"],
    [159420,"u2201","red","体があったまります","今の時期、ココアがおいしいです。体があったまります。","て","森永製菓"],
    [160717,"u2206","orange","ロースト塩バター味が美味しい","久しぶりにプリッツ食べました。ロースト塩バター、美味しかった。","よーさん","江崎グリコ"],
    [159257,"u2211","blue","誕生日ケーキと自分のご褒美に","子供の誕生日ケーキのときや自分のご褒美に利用させてもらってます！いつも美味しいスイーツありがとうございます✨","あ","不二家"],
    [160436,"u2212","red","高校生の時、共同開発でケーキを作った思い出","安城市の農業高校生の時、共同開発でケーキを作らせてもらいました。卒業後も文化祭で販売していて、嬉しかったです。何年も続くといいなと思っています。これからも宜しくお願いいたします。","星野","山崎製パン"],
    [158868,"u2267","orange","挑戦する人","ヤクルトの坂本龍馬のCMいいですね。挑戦する人にヤクルト1000ですね。","とし","ヤクルト本社"],
    [159376,"u2229","blue","親子2代で大ファン","かっぱえびせんじゃがりこポテトチップス幼い頃からカルビーのお菓子でお世話になり自分の子供もカルビーのお菓子の大ファン親子2代で、おやつタイムを楽しんでいます！これからも変わらぬ美味しさ期待しております！","ヒロスケ","カルビー"],
    [160731,"u4902","red","売り注文を出し忘れた日に急上昇","毎年、カレンダーをありがとうございます。損切りしようと思っていながら売り注文を出し忘れた日に株価が急上昇。まだしばらく持ち続けることにしました。応援しています！","ゆきこ","コニカミノルタ"],
    [159384,"t8058p3","orange","これだけで保有し続けている","記念品の静嘉堂文庫のカレンダーが毎年の楽しみです。これだけで保有し続けている。","ヒカワモモオー","【推しカブ】三菱商事"],
    [159180,"u8058","blue","株式分割手続き完了","株式分割手続き完了のお知らせハガキが着きました。これからも更なる御発展を期待します。","5時から","三菱商事"],
    [160112,"u8002","red","いろんな事に挑戦している","丸紅さん、私はあなたの事を良く知りません。でも、いろんな事に挑戦しているんですね。これからもずっと頑張って下さい。","ミルキィ","丸紅"],
    [159594,"u8088","orange","水素もやってるんですね！","カセットコンロのイメージだったけど、水素もやってるんですね！頑張ってほしいです💪🏻","green","岩谷産業"],
    [158196,"u8267","blue","庶民の味方","トップバリュは庶民の味方です‼️","Zet","イオン"],
    [160171,"u8267","red","育児休業中の社員の手取り収入を100%補償","育児休業を取得する社員の手取り収入を100%補償する制度を導入するそうです。","洋なし","イオン"],
    [157895,"u9104","orange","帰省するとき利用","今年も北海道に帰省するとき利用したいと思います頑張って下さい","ある","商船三井"],
    [159573,"u9104","blue","カジュアルクルーズ楽しみ","さんふらわあ　カジュアルクルーズ　楽しみにしてます😸😸😸","お勉強中人","商船三井"],
    [160567,"u9432","red","優待のdポイント","ずっと握りしめて優待のdポイントいただきます。","いで","日本電信電話"],
    [159428,"u9433","orange","ポンタポイントだとストックポイントに投資出来るので最高","今年からの株主優待の変更、最初は戸惑いましたが、ポンタポイントだとストックポイントに投資出来るので最高ですね！！","kitanosora","KDDI"],
    [160535,"u9433","blue","移動（IDO）通信時代からお世話に","移動（IDO）通信時代からお世話になっております。","win8","KDDI"],
    [158874,"u9602","red","時間があると","時間があるとよく映画鑑賞しています。これからもよろしく！","かるは","東宝"],
    [160243,"u9672","orange","関係者全てにありがとう","つらい事が続く中、競馬がなかったら　わいしんでた競馬に感謝！！競走馬、馬主、調教師、騎手、厩務員、生産者、関係者全てに　ありがとうを伝えたい！","万波遣","東京都競馬"],
    [160882,"u9684","blue","もうすぐ発売！楽しみ","もうすぐ【ファイナルファンタジーVII リバース】の発売ですね。楽しみです。","シノ","スクエニＨＤ"],
    [160745,"u9684","red","雪まつりのスライム","雪まつりのスライム良かったです","とみん","スクエニＨＤ"],
    [159280,"u9766","orange","時代に合わせた経営に尊敬","若い頃、スーパーファミコンで夢中になったパワプロシリーズがソシャゲになってコナミさんの収益に貢献している姿を見て、時代に合わせた経営が出来るコナミさんを尊敬しております！！","kitanosora","コナミグループ"],
    [158839,"u8050","blue","大谷さんがはめているのを見て","私も大谷さんがはめてらっしゃるのを見て欲しくなりました。最高のプレゼンでした。","ecoice0408","セイコーグループ"],
    [159313,"u7936","red","これからも日本人に合う素晴らしい靴を","アシックスファン歴25年、バッシュもランニングシューズもアシックスです。小学生の子供の初バッシュもアシックスにしました！これからも日本人に合う素晴らしい靴を作ってください。","サイタマン","アシックス"],
    [159295,"u7936","orange","おすすめされたマジックスピード","会社の同僚が購入したマジックスピードがいいとのこと！最近足首痛いので購入検討中です！！","マサ","アシックス"],
    [159610,"u9843","blue","本当にお値段以上の買い物","家電や寝具も益々充実して来てますね。昨年販売開始したチューナーレスTVを購入しましたが、画質もサイズも良く、本当にお値段以上の買い物だと感じました。","ゆん","ニトリホールディングス"],
    [158465,"ISRG","red","全世界で今後普及する事は間違いない","今期最高値！手術支援ロボットの需要は、これから新興国も含め全世界で今後普及する事は間違いありません。これから益々企業価値は高まって行くと思います。","鎌倉涼太郎","インテュイティブサージカル"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })