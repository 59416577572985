let data = [
    [156778,"SPCE","blue","地球外にも目を向ける年","地球外にも皆さんが目を向ける年になるのでは、ないでしょうか？期待します、","超老","ヴァージン・ギャラクティック・ホールディングス"],
    [155499,"3313188","red","宇宙の謎など明るい話題を提供して","今年は更に宇宙に関しての情報や活動が期待されます。宇宙の謎と新しい惑星の探索など明るい話題を提供して下さい。","GUN","ｅＭＡＸＩＳ Ｎｅｏ 宇宙開発"],
    [156400,"SBUX","orange","ナンテ画期的！","自前カップ使えるなんて、ナンテ画期的！今まで手間を惜しんでやってこなかったことをやらなきゃ、地球は生き残れないスタバがやることで影響力も絶大。応援します","風のふく日","スターバックス"],
    [155293,"BA","blue","旅客者に快適な旅客機の開発・製造を","世界の旅客機の二大メーカーですね。ボーイング社とエアバス社の旅客機に甲乙付け難いのですが、旅客者に快適な旅客機の開発・製造をお願い致します。","バレンタイン","ボーイング"],
    [156451,"u7270","red","伝統と技術を継承している","中島飛行機の伝統と技術を継承していて成長する企業だと思います","poohiro","ＳＵＢＡＲＵ"],
    [155313,"u2897","orange","現地味のカップヌードル","海外旅行先でも現地味のカップヌードルを買っています！","のんきち","日清食品"],
    [155991,"u2651","blue","旅行客にも広めたい","からあげくんを旅行客にも広めたい","ななめねこ","ローソン"],
    [154326,"u9104","red","カジュアルクルーズ行ってみよ〜","明けましておめでとうございます✨今年はカジュアルクルーズ行ってみよ〜😸色んな事、楽しみにしてます😊","お勉強中人","商船三井"],
    [154704,"u9202","orange","枚数増を期待","株主優待の枚数増を期待して待ってます","Matthew","ＡＮＡＨＤ"],
    [155104,"u9064","blue","旅行で帰りも楽に","旅行先で荷物が増えて、思うように動けない時、ひと足先に荷物だけ自宅に送ったあとは観光も行けるし、帰りも楽になります。感謝","平","ヤマトＨＤ"],
    [155025,"AAPL","red","ついに買いました！","今日アップルウォッチを、ついに買いました！アップルウォッチとともに、最高の一年にします！","わーたろう","アップル"],
    [154157,"bitcoin","orange","ビットコインイヤー","明けましておめでとうございます。2024はビットコインイヤーになりますようお祈り申し上げます。","Yuma ","bitcoin"],
    [156403,"bitcoin","blue","買い増します！","ETF承認、来ましたね！けっこう待ちました…買い増します！","yumyum","bitcoin"],
    [156028,"COIN","red","世界的に有効利用されることを望む","コインベースは規制に順応しながら成長しています。米国だけだなくヨーロッパにも進出し更に発展を遂げようとしています。暗号資産が世界的に有効利用されることを望みます。","おいっちょ","コインベース・グローバル"],
    [154874,"u7453","orange","福缶当選しました！","雑貨も服も愛用しています3年目にして初めて福缶当選しました！これから受け取りですが、中身を開けるのが今からとっても楽しみです！","めだか","良品計画"],
    [154602,"u7974","blue","株のゲームがあれば勉強になります！","あけましておめでとうございます！今年も任天堂を応援してます。子供や大人も夢中になってしまうゲームの魅力。株のゲームがあれば勉強になります！","ポンポコポン","任天堂"],
    [156094,"u4661","red","誰か一緒に行きませんか？","株主優待のパークチケットが届きました！いつもありがとうございます。今回は長期保有特典の４枚がプラスされて、合計6枚のチケットが届きました。チケット使いきれるかな〜。誰か一緒に行きませんか？www","あつぞう。","オリエンタルランド"],
    [156183,"u3086","orange","重宝しています","お買い物割引カードを重宝しています","目指せ配当王","Ｊフロント"],
    [154499,"u7867","blue","限定リカちゃんとトミカをもらうのが夢","1000株以上保有して限定リカちゃんとトミカをもらうのが夢です✨良い商品を作ってくれてありがとうございます!","ポン","タカラトミー"],
    [154625,"u8173","red","1株株主でも優待","1株株主でも優待がおトク！！関西企業の筆頭を応援します！！","ギターヒーロー(嘘)","上新電機"],
    [156128,"u1803","orange","やっと3株持てました","やっと3株持てました。今年も宜しくお願い致します。堅実に前進してくださいね。","テル","清水建設"],
    [154653,"u5020","blue","毎月1〜2株ずつ増やしています","日々の生活で得たポイントを振り分けて、毎月1〜2株ずつ増やしています世界情勢が不透明、新春早々の大地震など不安要素がありますが、今年もよろしくお願いします","ま〜るちゃん","ＥＮＥＯＳホールディングス"],
    [155422,"u8410","red","ポイントで100株超え","ポイントで積み立て投資中100株超えてうれしい","おしんしん","セブン銀行"],
    [156552,"u9432","orange","ようやく100株到達","コツコツポイント積み上げてようやく100株到達しました。これからの時代も世の中の中心になる企業と思いますので、増やして持ち続けていきたいです。","こばかつ","日本電信電話"],
    [155577,"u7867","blue","子供がトミカを毎年楽しみに","100株のみ保有ですが、子供がトミカを毎年楽しみにしています。500株まで増やしたいですが株価がどんどん上がり、手が出せません(´-ω-`)。でもこの調子で頑張って下さい！","タカラ取りー","タカラトミー"],
    [156135,"NVDA","red","購入して本当に良かった","コロナショック時に購入しました。投資を始めるまで全く存じない企業でしたが購入して本当に良かったです。","わかめ","NVIDIA"],
    [154752,"u9613","orange","技術力を期待","以前から株を持ってます。技術力を期待してます。","kazu","ＮＴＴデータグループ"],
    [155756,"t6861p3","blue","日本の象徴の銘柄","技術大国日本の象徴の銘柄、応援してます","たださん","【推しカブ】キーエンス"],
    [156693,"u2922","red","１日のストレスを解消したい","美味しいつまみとお酒で１日のストレスを解消したいので、新商品をたくさん販売してください。","ピース","なとり"],
    [155200,"u7751","orange","実際に現地にいるかのよう","毎週､世界遺産を楽しみに観ています。繊細で迫力がある映像は実際に現地にいるかのようです。これからも先進技術を駆使し、生活支援､医療などあらゆる分野で功績をあげられることを期待しています。","Ichipy","キヤノン"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })