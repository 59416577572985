import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'

import Page1 from './pages/Page1.vue'
import Page2 from './pages/Page2.vue'
import Page3 from './pages/Page3.vue'
import Page4 from './pages/Page4.vue'

export default {
	data: function(){
		return {
			isLoading: false,
			linkable: true,
			w: 400,
			leftPage: {pos: -1, component: '', anim: false},
			mainPage: {pos: 0, component: '', anim: false},
			rightPage: {pos: 1, component: '', anim: false},
			components: ['Page1', 'Page2', 'Page3', 'Page4'],
			current: 0,
		}
	},

	mounted: async function(){
		this.moveTo(parseInt(this.$route.params.event_id))
		this._resize()
		window.addEventListener('resize', this._resize)
	},

	methods: {
		_resize: function(){
			this.w = window.innerWidth
		},

		_calcPages: function(){
			let l = this.components.length
			let prev = this.current - 1
			if(prev < 0) prev = l - 1
			let next = this.current + 1
			if(next >= l) next = 0

			this.leftPage = {pos: -1, component: this.components[prev], anim: false}
			this.mainPage = {pos: 0, component: this.components[this.current], anim: false}
			this.rightPage = {pos: 1, component: this.components[next], anim: false}
		},

		moveTo: function(event_id){
			this.current = parseInt(event_id) % this.components.length
			this._calcPages()
			this.$refs.main.scrollTo({top: 0})
		},

		link: function(add){
			//アニメーション終了まで無効にする
			if(! this.linkable) return false
			this.linkable = false

			//アニメーション
			if(add == 1){
				this.rightPage.pos = 0
				this.mainPage.pos = -1
				this.rightPage.anim = true
			}else{
				this.leftPage.pos = 0
				this.mainPage.pos = 1
				this.leftPage.anim = true
			}
			this.mainPage.anim = true

			//現在の表示ページNumを更新する
			this.current += add
			let l = this.components.length
			if(this.current < 0){
				this.current = l - 1
			}else if(this.current >= l){
				this.current = 0
			}

			//アニメーションが終わったら反映する
			setTimeout(()=>{
				this._calcPages()
				this.linkable = true
				this.$refs.main.scrollTo({top: 0})
			}, 370)
		},
	},

	components: {
		Header,
		TopCap,
		Page1,
		Page2,
		Page3,
		Page4,
	},

}
