import { render, staticRenderFns } from "./PostComment3.vue?vue&type=template&id=0d2934ce&scoped=true"
import script from "./PostComment3.vue?vue&type=script&lang=js"
export * from "./PostComment3.vue?vue&type=script&lang=js"
import style0 from "./PostComment3.vue?vue&type=style&index=0&id=0d2934ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2934ce",
  null
  
)

export default component.exports