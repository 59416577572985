import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import List from './components/List.vue'
import Toribia1 from './components/Toribia1.vue'

export default {
	components: {
		Header,
		List,
		Toribia1,
	},

	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			comp: '',
		}
	},

	mounted: async function(){
		const id = this.$route.params.id
		this.comp = id != null ? 'Toribia' + id : 'List'
		this.isReady = true
	},

	methods: {
	},

}
