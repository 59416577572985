<template>
<div class="item">
	<a :href="l.url" target="_blank">
		<img :src="cdn + '/img/rensai/interview/' + l.img">
		<p class="title">{{l.title}}</p>
		<p class="dt">{{dtFmt(l.dt)}}</p>
	</a>
</div><!--item-->
</template>

<style scoped>
.item{
	& a{
		display: block;
		width: 100%;
		height: auto;
		background-color: transparent;
		border: none;
		margin: 0 auto;
		max-width: 375px;
		text-align: left;
		line-height: 27px;
		padding: 20px 0;

		& img{
			display: block;
			width: 100%;
		}
		.title{
			font-size: 20px;
			font-weight: bold;
			margin: 10px 0 5px 0;
		}
		.dt{
			font-size: 12px;
		}

	}
}
</style>

<script>
export default {
	props: {
		l: {type: Object, default: () => { return {} } },
	},

	data: function(){
		return {
			cdn: CDN,
		}
	},

	mounted: function(){
	},

	methods: {
		dtFmt: function(str){
			const dt = new Date(str)
			return [dt.getFullYear(), String(dt.getMonth() + 1).padStart(2, '0'), String(dt.getDate()).padStart(2, '0')].join('.')
		},

	},
}
</script>
