import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import List from './components/List.vue'
import Yasu1 from './components/Yasu1.vue'
import Talk1 from './components/Talk1.vue'
import Nobu1 from './components/Nobu1.vue'
import Yasu2 from './components/Yasu2.vue'
import Talk2 from './components/Talk2.vue'
import Nobu2 from './components/Nobu2.vue'
import Yasu3 from './components/Yasu3.vue'
import Talk3 from './components/Talk3.vue'
import Nobu3 from './components/Nobu3.vue'
import Yasu4 from './components/Yasu4.vue'
import Talk4 from './components/Talk4.vue'
import Yasu5 from './components/Yasu5.vue'

export default {
	components: {
		Header,
		List,
		Yasu1,
		Talk1,
		Nobu1,
		Yasu2,
		Talk2,
		Nobu2,
		Yasu3,
		Talk3,
		Nobu3,
		Yasu4,
		Talk4,
		Yasu5,
	},

	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			comp: 'List',
		}
	},

	mounted: async function(){
		const tp = this.$route.params.type
		const id = this.$route.params.id
		if(tp == 'nobu'){
			this.comp = 'Nobu' + id
		}else if(tp == 'yasu'){
			this.comp = 'Yasu' + id
		}else if(tp == 'talk'){
			this.comp = 'Talk' + id
		}
		this.isReady = true

		setTimeout( () => {
			//初期化
			this.$refs.comp.init()
		}, 0)
	},

	methods: {
	},

}
