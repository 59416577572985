<template>
<div class="wrapper">
	<div class="header">
		<img class="icon" width="50" height="50" alt="" :src="l.product.img_url">
		<div class="nWrp">
			<p class="name" @click="linkTo">{{l.product.name}}</p>
			<p class="market">{{l.product.market}}</p>
		</div><!--nWrp-->
	</div><!--header-->

	<div class="main">
		<p class="nType">{{l.n_type}}</p>
		<pre class="contents" v-html="l.contents"></pre>
	</div><!--main-->

	<p class="dt">{{dtFmt()}}</p>
	<Favorite :favs="favs" :myMark="myMark" @fav="fav" />

</div>
</template>

<style scoped>
.wrapper{
	width: 305px;
	height: 340px;
	text-align: center;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
}
.header{
	height: 56px;
	background-color: #F8F8F8;
}
.icon{
	display: block;
	float: left;
	margin-top: 10px;
	margin-left: 10px;
	width: 36px;
	height: 36px;
	border-radius: 18px;
	object-fit: cover;
}
.nWrp{
	display: block;
	float: left;
	width: calc(100% - 56px);
	margin-top: 10px;
	margin-left: 10px;
}
.name{
	display: block;
	text-align: left;
	color: #28CFB5;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.market{
	display: block;
	text-align: left;
	font-size: 10px;
	line-height: 16px;
	color: #999;
}
.main{
	height: calc(100% - 56px - 32px - 10px);
	overflow: hidden;
	padding-bottom: 10px;
}
.nType{
	display: block;
	white-space: nowrap;
	text-align: left;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: white;
	background-color: var(--col-main);
	padding: 5px;
}
.dt{
	float: left;
	font-size: 10px;
	line-height: 20px;
	opacity: 0.6;
	margin-left: 10px;
}
pre.contents{
	margin: 10px 10px 0 10px;
	clear: both;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	white-space: pre-wrap;
}
pre.contents>>>a{
	display: inline;
	color: blue;
	background-color: transparent;
	text-decoration: underline;
	margin: 0;
	padding: 0;
	border: none;
	font-weight: normal;
	height: auto;
	line-height: 20px;
}

</style>


<script>
import Favorite from '../../components/Favorite3.vue'

export default {
	props: {
		l: {type: Object },
	},

	data: function(){
		return {
			favs: this.l.favs,
			myMark: this.l.my_mark,
		}
	},

	mounted: function(){
	},

	methods: {
		linkTo: function(){
			this.$router.push({name: 'SearchDetail', params:{c_code: this.l.c_code}})
			return true
		},

		dtFmt: function(){
			let dt = new Date(this.l.created_at * 1000)
			return dt.getFullYear() + '/' + (dt.getMonth() + 1) + '/' + dt.getDate()
		},

		fav: async function(){
			let d = {
				id: this.l.id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/company_news/fav', d)
			if(res.code == 0){
				this.favs += 1
				this.myMark = 1
			}
		},

	},

	components: {
		Favorite,
	},
}
</script>
