<template>
<div class="main">

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/MV.png'">
	</div><!--MVWrp-->

	<img class="cap1" :src="cdn + '/img/rensai/esg/cap1.png'">

	<div class="grey">

		<div v-for="l in list" class="item" @click="linkTo(l.num)" v-if="checkDisp(l.date)">
			<div class="cap2Wrp">
				<img class="cap2" :src="cdn + '/img/rensai/esg/esg' + l.num + '/megami.png'">
			</div><!--cap2Wrp-->
			<p class="title" v-html="l.title"></p>
			<p class="dt"><span>NEW</span>{{dispDt(l.date)}}</p>
			<div class="foot">
				コラムを読む
				<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_right_white.png'">
			</div>
		</div><!--item-->

	</div><!--grey-->

</div><!--main-->
</template>



<style scoped>
.MVWrp{
	position: relative;
}
.MVWrp .back{
	display: block;
	height: 400px;
	width: 100%;
	margin: auto;
	object-fit: cover;
}
.MVWrp .MV{
	position: absolute;
	width: 275px;
	top: 170px;
	left: 0;
	right: 0;
	margin: auto;

}

.cap1{
	display: block;
	width: 300px;
	margin: 40px auto;
}

.grey{
	background-color: #D7E9EB;
}

.item{
	position: relative;
	overflow: visible;
	width: 315px;
	border-radius: 20px;
	background-color: white;
	margin: 80px auto 60px auto;
	min-height: 320px;
	padding-top: 150px;
	cursor: pointer;
}
.item:before{
	content: '　';
	position: absolute;
	top: 3px;
	left: 3px;
	right: 3px;
	bottom: 3px;
	border: 1px solid #8DA1AC;
	border-radius: 18px;
}
.item .cap2Wrp{
	position: absolute;
	top: -40px;
	left: 0;
	right: 0;
	width: 128px;
	height: 150px;
	margin: auto;
}
.item .cap2Wrp .cap2{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.item .cap2Wrp p{
	position: absolute;
	width: 100%;
	height: 50px;
	line-height: 50px;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	bottom: 10px;
	color: white;
}
.item .title{
	display: block;
	width: 100%;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
}
.item .dt{
	margin: 40px auto 20px auto;
	width: 266px;
	color: #8197A4;
	font-size: 12px;
}
.item .dt span{
	display: none;
	color: white;
	background-color: #FC8786;
	width: 50px;
	height: 18px;
	border-radius: 20px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	text-align: center;
	margin-right: 5px;
	vertical-align: middle;
}
/*先頭のみ*/
.item:first-child .dt span{
	display: inline-block;
}
.item .foot{
	height: 60px;
	line-height: 60px;
	background-color: #8197A4;
	color: white;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}
.item .foot .arrow{
	display: inline-block;
	height: 14px;
	margin-left: 5px;
}
</style>


<script>
export default {
	data: function(){
		return {
			cdn: CDN,
			list: [
				{
					num: 6,
					title: '「ESG投資」は、批判に耳を傾け、<br>粘り強く分析し続ける意義がある',
					date: new Date('2023-12-20 10:00:00').getTime(),
				},
				{
					num: 5,
					title: '「E」は現実を見据えつつも、<br>投資判断には重要な要素である',
					date: new Date('2023-12-06 10:00:00').getTime(),
				},
				{
					num: 4,
					title: '「人権問題」の意識が低い、<br>日本の現状',
					date: new Date('2023-11-20 10:00:00').getTime(),
				},
				{
					num: 3,
					title: 'ＥＳＧは儲かるのか？<br>儲からないのか？',
					date: new Date('2023-10-25 10:00:00').getTime(),
				},
				{
					num: 2,
					title: 'ＥＳＧは「三方良し」',
					date: new Date('2023-10-06 10:00:00').getTime(),
				},
				{
					num: 1,
					title: 'ＥＳＧ、過去、現在、未来',
					date: new Date('2023-09-08 10:00:00').getTime(),
				},
			],
		}
	},

	methods: {
		//初期化
		init: function(){
		},

		linkTo: function(num){
			location.href = '/rensai/esg/' + num
		},

		checkDisp: function(dt){
			if(location.hostname != 'aisarekigyo.stockpoint.jp') return true
			const now = new Date()
			if(dt <= now) return true
			return false
		},

		dispDt: function(num){
			const dt = new Date(num)
			return [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()].join('/')
		},

	},

	components: {
	},
}
</script>
