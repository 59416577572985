<template>
<div class="main">

	トリビア

</div><!--main-->
</template>


<style scoped>
</style>


<script>
export default {
	data: function(){
		return {
			cdn: CDN,
			list: [
			],
		}
	},

	methods: {
	},

	components: {
	},
}
</script>
