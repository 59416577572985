import { comma } from '../../../lib/utils'


export default {
	props: {
		l: {type: Object, defautl: {}},
		dType: {type: Number, default: 0},
	},

	data: function(){
		return {
			imgUrl: '',
		}
	},

	mounted: function(){
		this.imgUrl = this.l.img_url
	},

	methods: {
		detail: function(c_code){
			this.$router.push({name: 'SearchDetail', params: {c_code: c_code}})
		},

		noImage: function(){
			this.imgUrl = this.$cdn + '/img/main/noimage.svg'
		},

		/*comma wrapper*/
		comma: function(num){ return comma(num) },
	},

}
