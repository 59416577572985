<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	短期投資コラム
</div><!--head-->

<h1>第1回<br>短期投資のポイント</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<h2>短期になればなるほど、投資の難易度は高くなる。</h2>

<pre>短期と一言で言っても、

<span class="red">超短期投資</span>…1日以内長くて数日で手仕舞って利益を確定させる
<span class="red">短期投資</span>…1年以内、数か月程度で利益を確定させる

で、それぞれ投資手法が異なります。

短期になればなるほど投資の難易度が高くなるという理由は、短期の株価は、企業の業績にも影響するけれども、それよりも、マーケットのその時の金利とか為替とか、誰がどんな発言をしたとか、本当に様々な要素で株価は上下するものだからです。
　
これが、<span class="blue">短期投資で安定した利益を得るのは、プロでも大変なこと</span>、なのです。</pre>


<h2>短期投資で成功するための、投資手法。例えばそれは…</h2>

<pre>長期投資のところでも触れましたが、企業は必ず決算を公開します。
また、<span class="blue">決算見込みも公表</span>していて、実際にそれよりも決算が良くなりそうであれば、<span class="blue">上方修正</span>を決算数字が見込よりも悪くなりそうなら、<span class="blue">下方修正</span>をします。

そして、例えばある企業が、通期決算を上方修正するような好決算を発表した場合、多くのケースで、その後1〜2ヵ月で株価が上昇する、という時間差が生じます。</pre>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>短期投資で成功する手法①

<span class="uline">上方修正が発表されてから
株価が上昇するまでの間を狙え！</span>
</pre>

	<hr class="down">
</div><!--point-->

<h2>実際、過去に約3ヵ月で2倍になった例もあります</h2>

<pre>例えば、円谷フィールズ（2767）の例を見て見ましょう。円谷というと、ウルトラマンが有名ですが、それ以外にパチンコ遊戯機販売などしている会社です。

この会社の約1年前からのチャートです。</pre>


<div class="grey">

<pre>円谷フィールズ 約1年前のチャート</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/nobu1/graph1.png'">

</div><!--grey-->

<pre>2月に、通期の業績が良くなる見込みであることを発表した後に、5月に実際に決算数字が発表されました。そして株価は、そこからジワジワと上がり、2月の時点から約2倍まで上昇しました。

短期投資は難易度が高い、と言いましたが、この手法であれば、企業業績の上方修正に注意していれば良い手法です。

ただし、この手法の「ミソ」は、<span class="blue">上方修正された情報が投資家の間に徐々に広がって行く過程で株価が徐々に上がっていくのを狙う戦略</span>ですので、先頭に居た投資家ほど大きなリターンを得られる可能性が高くなります。先に行った人勝ち！の戦略。</pre>


<h2>超短期投資で成功するためには。それは普通の人はない「素質」が必要</h2>


<pre>ちょっと難しい話になりますが。そもそも投資で成功するには、<span class="blue">リターンを生み出している原因はどこ</span>にあるのかを理解し、そして、<span class="blue">それがどういうメカニズムで生み出されているのか</span>の再現性ある理屈をちゃんと理解しておくことが必要です。

なんとなく、上がるか下がるかに賭けて、当たれば儲かった、というのは、投資ではありません。

例えば、長期投資の場合。

リターンを生み出す源泉は、まさしく、<span class="blue">その企業の拡大する利益</span>で、その源泉に対する再現性のある理屈は、<span class="blue">企業の利益が拡大すれば、その企業価値も大きくなる</span>というものです。

従って、<span class="blue">利益が拡大する企業に投資をする</span>という極めてシンプルな投資戦略が長期投資です。普通の個人投資家の方に長期投資をお勧めするのは、このシンプルなルールを意識するだけで、リターンを得る確率が大きくなるからです。

一方で、超短期投資では、長期投資のような「拡大していく利益」では時間軸が合いません。拡大していく利益を待っていては、超短期にリターンは生み出せない、のです。

では、<span class="blue">超短期投資で成功するには</span>どうしたら良いのか。

例えば、<span class="blue">銘柄毎の値動きの特徴とかクセ、価格のゆがみ、投資家の間違いや行き過ぎの状況の察知など</span>…これらは、見つけ出すのは簡単ではありませんし、しかも見つけた瞬間にトレードしないといけないというスピードも求められます。
これが、超短期投資は難易度が高いという理由です。</pre>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>超短期投資で成功する手法

① <span class="uline">銘柄毎の値動きのクセを理解</span>

②<span class="uline">マーケットの歪みをいち早く見つける</span>
</pre>
	<hr class="down">
</div><!--point-->

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/nobu1/chara1.png'">


<pre>それでも、超短期投資で継続的に利益を出しているスーパー投資家は居るので、彼らがどんなところを注目しているのかを、次回以降で紹介していきましょう。</pre>


<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>超短期投資の手法紹介 その１
「3日以内で勝負を決める
超短期モーメンタム投資」とは</pre>
	</div><!--next-->

</div><!--nextWrp-->

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #EF5C5C;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #EF5C5C;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
span.red{
	color: #EF5C5C;
}
span.blue{
	color: #2A78C6;
	font-weight: 600;
}
span.uline{
	background: linear-gradient(transparent 60%, #91C8FF 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #FCF2F2;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
}
.point hr{
	position: absolute;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #1A1A1A;
	margin: 0;
}
.point hr.up{
	top: 5px;
}
.point hr.down{
	bottom: 5px;
}
.point>img{
	height: 30px;
	position: absolute;
	top: -15px;
	left: -2px;
}
.point pre{
	font-weight: 600;
}

.grey{
	background-color: #FAFAFA;
	margin: 20px 0;
	padding: 20px 0;
}
.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #F2CBCB;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #F2CBCB;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 3,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d, true)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
