<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #06
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg6/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg6/MV.png'">

		<h1>「ESG投資」は、批判に耳を傾け、<br>粘り強く分析し続ける意義がある</h1>
		<p class="dt">2023/12/20</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">


<pre>PRI（Principle of Responsible Investments）とは国連の流れをくむ民間組織で、<span class="uline">ＥＳＧ投資の世界的な旗振り役だ。</span>PRIの原則に署名した資産運用会社はＥＳＧ投資の考えを自社の運用業務に反映するよう求められる。２００６年の発足以来、PRIの署名は２０２２年末で５３００機関を超え、その総運用資産は１２０超ドルを超える。<span class="uline">日本の国内総生産（ＧＤＰ）のおよそ３０倍に達する。</span>

　この署名機関に所属する人たちが年に１回集まり、ＥＳＧに関して様々な議論をする場がPRI in Personだ。今回の大会には１３００人程度が対面で集まった。このうち日本人は約４００人だったというから、海外からの参加者は半分をゆうに超えたことになる。実際、パネルの議論や会場の至るところでの雑談では、日本語がほとんど聞こえてこなかった。

　「国際」とか「グローバル」の名を冠したカンファレンスに参加することが時々あるが、大部分は日本人で、セッションもほとんどが日本語という「和製グローバル」なイベントが多い。その意味で、日本には珍しいカンファレンスだったと言える。

　こうした光景は<span class="uline">「ＥＳＧ」や「サステナブル金融」の持つ、人やお金を引きつける磁力の強さ</span>を示したとも言える。世界的にはブームの終焉がささやかれ、米国では政治色の強い「アンチＥＳＧ」のうねりも強まっている。ロシア・ウクライナ戦争やイスラエル・ハマス紛争などの厳しい現実は、数年前にＥＳＧがまとっていた「投資の力で世の中を変える」といった楽観論を吹き飛ばした。
　しかし、だからこそPRI in Person東京大会が世界に放ったメッセージには重いものがある。特に、岸田文雄首相が実際に会場に足を運び、<span class="uline">責任投資への支持を示唆するとともに、日本の公的年金がPRI署名の準備を始めると表明した事は意義深い。</span>会場にいた参加者が一様に驚くほどの踏み込んだ内容だった。

　個別セッションの議論では、２日目のUnderstanding  ESG Backlash（ＥＳＧの反動的な動きを理解する）が興味深かった。前述した米国の反ＥＳＧのうねりをどのように受けとめるかという問題意識に基づく議論だったが、議論の参加者の意見は実に建設的だった。

　米国の主に共和党の強い州を中心にＥＳＧの考え方を公的運用から閉め出す動きが出ていることに関しては、<span class="uline">「ＥＳＧ投資への試練であり鍛錬につながる」といった声があがった。批判をバネにさらに運用の専門性を高め、手法を磨き、情報開示をさらに進めよう</span>という趣旨だ。
　筆者もこの見方に賛同する。まだ歴史の浅いＥＳＧ投資はそもそも運用の哲学や成績測定など、不十分な点がある。１世紀を超える研究やデータ蓄積がある一般の資産運用とは比べるべくもない。だからこそ批判に耳を傾け、粘り強く分析を進める意義もある。
　折から、<span class="uline">官邸主導で「資産運用立国」なる構想</span>が動き出している。提示されたメニューは既視感の強いものばかりだが、<span class="uline">そこに「ESG／サステナビリティー」も盛りこまれたことには新味がある。</span>

投資信託を売るテーマに終わらせては、あまりにもったいない。</pre>

	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<p class="title">ESGの泉は今回で最終回となります。<br>お読みいただきありがとうございました。</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg6/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	background-color: #D7E9EB;
	margin: 20px auto;
	text-align: center;
	.title{
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 14px;
		font-weight: 600;
	}
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 12,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
