<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #01
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg1/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg1/MV.png'">

		<h1>ＥＳＧ、過去、現在、未来</h1>
		<p class="dt">2023/9/8</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">

	<pre>新聞記者を３０年余り続け、主にマーケットの取材をしてきた。最近よく思い出すのは、株式相場に浮かんでは消えた多くの流行語やキャッチフレーズだ。「債権大国」「インターネット革命」「アベノミクス」「貯蓄から投資へ」……。
　いくつかは語り継がれ、多くは根無し草のように流れ去った。

　さて、この言葉はどうか。「ＥＳＧ」だ。

　環境（Environment）、社会（Social）、企業統治(Governance）のそれぞれの英単語の頭文字を組み合わせた造語で、「ＥＳＧ投資」や「ＥＳＧ経営」のように使われる。
　環境問題の深刻化や社会の分断、企業不祥事の頻発などを背景として、人口に膾炙されるようになった。人によって微妙に異なるイメージを抱いているようだが、「企業にとっての金銭的な利益を超えた価値」くらいの意味で使われることが多い。

　一時期は「ＥＳＧ」が新聞紙面やネット画面に毎日のように踊った。日本経済新聞の本紙朝刊に「ＥＳＧ」を含む記事が何本掲載されているかを、年を追って調べてみた。２０１５年ごろから増え始め、１８年に１９０本、１９年が２７８本、２０年は４７１本と急増。そして２１年は７００本を超えた。（日経テレコンによる）</pre>

	<img class="img" :src="cdn + '/img/rensai/esg/esg1/graph1.png'">

	<pre>集計は朝刊だけを対象にしているので、夕刊や各種ニューズレターやウエブサイトを含めれば、この何倍にもなるに違いない。１年３６５日、毎日どこかで「ＥＳＧ」を目にしている感覚だ。
　３０余年の新聞記者人生で、これほど長きにわたってメディアに登場している造語はちょっと記憶にない。あえて言えば、有力新興国を示す「ＢＲＩＣｓ（ブラジル、ロシア、インド、中国）」だが、旬の時期は２～３年だった。

　「ＥＳＧ」という言葉の広がりが、社会の本質的な変化を示唆しているのではないか。

　最近はそう思うようになり、この全６回の連載で書き記すことにした。
手っ取り早い投資指南や、「○○でも分かる」式のコンパクト解説を期待されても、おそらく失望に終わるだろう。
　「ＥＳＧ」の記事数カウントの起点を０６年としたのは、この年に「ＥＳＧ」の言葉が初めて公式に世に出たからだ。国連系の組織が「ＰＲＩ（責任投資原則）」を発表し、世界の年金基金や資産運用会社に採択を促した。このＰＲＩの中核的な概念が「ＥＳＧ」で、株式・債券投資にＥＳＧ要素を加えるよう求めた。

　直後から環境や人権への意識が強い欧州で強く支持されるようになったが、日本は前述のグラフが示すようにほぼスルー。　１５年ごろから急増した理由は、この年に安倍晋三首相（当時）の肝煎り政策で企業統治（コーポレートガバナンス）改革が始まり、企業が順守すべきコードの中に「ＥＳＧ」が盛りこまれたことだ。しかし、当時はまだ「ＥＳＧって何？！」というのが一般の投資家の受けとめ方だった。

　後の息の長い大ブームは、人々の心に深く刺さるものを「ＥＳＧ」が内包していたことを示す。
　それが何であったか。

　次回以降で解き明かしていきたい。</pre>

	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<img :src="cdn + '/img/rensai/esg/esg1/next.png'">
		<p class="title">「ＥＳＧは三方良し」</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg1/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	min-height: 60px;
	background-color: #D7E9EB;
	margin: 20px auto;
}
.next img{
	position: absolute;
	width: 46px;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}
.next .title{
	margin-left: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: 600;
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}
</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 2,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
