let data = [
[123933 ,"u4755","orange","楽天市場は外国人の強い味方","いつもお世話になっております。部下の外国人達が自国の食材が買えると喜んでおります。日本に来て自国の食材はなかなか手に入らないのですが、楽天市場では可能なので本当にありがとうございます。","たぬきち","楽天グループ"],
[132786 ,"u4755","blue","遠方に住む姪っ子へ誕生日プレゼント","いつも利用させてもらってます。今回は、遠くにいる姪っ子の誕生日プレゼントを注文させていただきました。これからもよろしくお願いします。","仔猫","楽天グループ"],
[135871 ,"u4755","red","楽天メディカルに注目","様々な分野に挑戦していて、素晴らしいと思います。楽天メディカルの光免疫療法に注目しています！","オプティミスト","楽天グループ"],
[136347 ,"u4755","orange","楽天モバイルが軌道に乗れば！","業績は厳しいけどモバイルが軌道に乗ればぐんと上昇するはず。楽天市場で応援してます♪","のびた","楽天グループ"],
[139424 ,"u4755","blue","携帯料金がものすごく下がった！","最近、楽天モバイルを契約しました。とりあえず、問題無く使えています!携帯料金がものすごく下がって嬉しいです","sekky","楽天グループ"],
[139787 ,"u4755","red","ふるさと納税でポイントもゲット","楽天でふるさと納税してます。節税出来てポイントもらえるのでとてもお得です。","オリノ","楽天グループ"],
[10880 ,"NVDA","orange","伸びがえぐい！！","もう誰も「謎の半導体企業」なんてそろそろ言わなくなったんじゃ無いでしょうか？それにしても伸びがえぐい！！","ザンザン","NVIDIA"],
[10905 ,"NVDA","blue","予想を超えた","好業績予想の更に上を行く好業績をありがとう。","おさ","NVIDIA"],
[10927 ,"NVDA","red","GPU（画像処理装置）でNO.1","GPU他社の追随を許さず。","ひろ","NVIDIA"],
[136395 ,"t6758p3","orange","なんだかんだでスキ。","なんだかんだでSONYの製品スキです。","アザミ","【推しカブ】ソニーグループ"],
[136599 ,"t6758p3","blue","推し決定！","『推しカブ』にしました。応援してます。","ぱおん","【推しカブ】ソニーグループ"],
[137388 ,"t6758p3","red","SONYらしさに期待","品質とアイディアが大好きです！SONYらしさにこれからも期待！","是空","【推しカブ】ソニーグループ"],
[139376 ,"t6758p3","orange","世界と戦えるSONYのエンタメ","エンターテイメントで世界と戦えると期待しています","ノブ","【推しカブ】ソニーグループ"],
[133119 ,"u2211","blue","ショーケースに大好きなケーキ","最近、近隣スーパーに不二家が入りました😊ワッフルコーンが美味しそうなソフトクリームが売っていて、勿論ショーケースにはケーキが✨今までは車で15分走らないと不二家が無かったのでとっても嬉しいです😊","ハオ","不二家"],
[139306 ,"u3563","red","食べると1カ月は頑張れる！","スシローのマグロの盛り合わせ、食べると1カ月は頑張れる！","ニャンダフル","ＦＯＯＤ　＆　ＬＩＦＥ　ＣＯＭＰＡＮＩＥＳ(スシロー)"],
[130285 ,"u5332","orange","ホッカラリ床にリフォーム！","バスルームをリフォームしました。ホッカラリ床最高ですね！除菌水の床清掃も気に入ってます！これからも良い製品作り頑張ってください！","ホッカラリ","ＴＯＴＯ"],
[136528 ,"u5332","blue","トイレと言ったら","外国人にも人気のウォシュレット。トイレと言ったら  ＴOＴO  ‼️","ハルゴト","ＴＯＴＯ"],
[136664 ,"u6861","red","奨学金がすごい！","奨学金がすごい！","花梨","キーエンス"],
[136998 ,"u6861","orange","知らなかった","元素分析装置も作ってたんですね!これからも頑張ってください!","ポイ活行脚中","キーエンス"],
[128339 ,"u7762","blue","がん患者支援などの取組を知れた","配当時期に合わせた御社のレポートを拝見しました。NPO活動に取り組む団体をいくつか支援していることを知りました。今後も社会のために活動するさまざまな団体への支援をお願いしたいです。がん患者を支援する団体のことも知れて良かったです。","こうたろう","シチズン時計"],
[132044 ,"u7762","red","毎回企業レポートが楽しみ！","毎回配当金のお知らせに入っている企業レポートが楽しみです。細い株主にも丁寧に送ってくださり本当に素敵な企業だと思います！細々と応援しています！","ふう","シチズン時計"],
[134501 ,"u7762","orange","あと少しで、、","シチズンの時計が好きです。後半分で一株になるので頑張らないと","たく","シチズン時計"],
[136172 ,"u7762","blue","自分へのご褒美","アテッサの購入を考えていて…値段も手頃とはいいにくいが、機能は等から自分のへの褒美にしたい","ヒデ","シチズン時計"],
[138473 ,"u7762","red","父に良い贈り物ができた","　今夏に父にCITIZENのエコ・ドライブのソーラー電池で動く金時計をプレゼントしましたが、私が買った物をまず身に着けない父が良い時計だと言って身に付けてくれていますね。久し振りに父に良い贈り物が出来ました。　(^^ゞ","ペンネーム・1093","シチズン時計"],
[131057 ,"u9101","orange","船の姿はあの日のまま","日本郵船といえば、山下公園の氷川丸を身近に感じます。もう会えない人とかつて歩いた道。船の姿はあの日のままですわが身よにふるながめせしまに(^_^)V","ペロポネソスで会いましょう","日本郵船"],
[135169 ,"u9101","blue","こんな船ができたら素敵","いつの日か、海の海洋プラスチックを吸い上げて動力源にする船が出来たら素敵♪","もにゃりぽ","日本郵船"],
[134681 ,"u9202","red","一緒に応援！！","羽生結弦さんが大好きなので、ANAも一緒に応援します！","ららら","ＡＮＡＨＤ"],
[134908 ,"u9202","orange","Air Japanに期待","新ブランドAir Japan発表。利用者の選択肢が新たにでき期待しています。","どら","ＡＮＡＨＤ"],
[135434 ,"u9602","blue","久しぶりに劇場へ","子どもの頃から、映画にお世話になっております。先日、久しぶりに劇場で映画を見ました。普段はスマホの小さな画面で見ていましたが、やはり映画館の大きなスクリーンで見る映画は迫力が違いますね。これからも、素敵な映画を作り続け、配給し続けて行って頂きたいです。","ひろ","東宝"]
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

