<template>
<div class="replyWrp">
	<div class="icon">
		<img v-lazy="d.icon != 10 ? $cdn + '/img/main/talk_icon' + d.icon + '.svg' : d.icon_url">
	</div><!--icon-->
	<div class="ncdWrp">
		<p class="nickname">{{d.nickname}}</p>
		<p class="date"><span>{{ $apc.cTheme[d.c_theme] }}</span>　{{dispDate(d.date)}}</p>
	</div><!--ncdWrp-->

	<img class="subMenu" :src="$cdn + '/img/main/subMenu' + (isOpenSubMenu ? '2' : '1') + '.svg'"
		@click="isOpenSubMenu = ! isOpenSubMenu">
	<div class="subMenuList" v-show="isOpenSubMenu">
		<div class="ng" v-show="! d.my_flg" @click="repComment">
			<span>投稿を通報する</span>
			<img :src="$cdn + '/img/main/ng1.svg'">
		</div><!--ng-->
		<div class="ng" v-show="d.my_flg" @click="delComment">
			<span>投稿を削除する</span>
			<img :src="$cdn + '/img/main/ng1.svg'">
		</div><!--ng-->
	</div><!--subMenuList-->

	<pre class="comment">{{d.comment}}</pre>

	<Favorite :favs="favs" :myMark="myMark" @fav="fav" />
	
</div><!--wrp-->
</template>

<style scoped>
.replyWrp{
}
.icon{
	position: relative;
	display: block;
	float: left;
	width: 32px;
	height: 32px;
}
.icon img{
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
}
.ncdWrp{
	display: block;
	float: left;
}
.nickname{
	display: block;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}
.date{
	display: block;
	margin-left: 10px;
	font-size: 10px;
	font-weight: 300;
	color: #999999;
	line-height: 14px;
}
.date span{
	font-size: 10px;
	font-weight: 300;
	color: var(--col-main);
	line-height: 14px;
}
.comment{
	clear: both;
	margin-top: 38px;
	font-size: 14px;
	font-weight :300;
	line-height: 22px;
}
.subMenu{
	position: absolute;
	top: 0;
	right: 0;
	width: 24px;
	height: 24px;
}
.subMenuList{
	position: absolute;
	top: 25px;
	right: 10px;
	width: 180px;
}
.gomi, .ng{
	width: 180px;
	height: 56px;
	border: 1px solid #999999;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	border-radius: 5px;
	background-color: var(--col-white);
	z-index: 10;
	text-align: center;
	line-height: 56px;
}
.gomi span, .ng span{
	font-size: 14px;
	font-weight: 300;
}
</style>

<script>
import {dtfmt2} from '../../../lib/utils'
import Favorite from '../Favorite3.vue'

export default {
	props: {
		d: {type: Object, default: () => { return {} } },
	},

	data: function(){
		return {
			isOpenSubMenu: false,
			favs: 0,
			myMark: -1,
		}
	},

	mounted: async function(){
		this.favs = this.d.favs
		this.myMark = this.d.my_mark
	},

	methods: {
		dispDate: function(dnum){
			return dtfmt2(dnum)
		},

		repComment: async function(){
			this.isOpenSubMenu = false
			//サーバに通知
			const res = await this.$post('/webapi/client/reply/report', {reply_id: this.d.reply_id}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
		},

		delComment: async function(){
			this.isOpenSubMenu = false
			const res = await this.$post('/webapi/client/reply/delete', {reply_id: this.d.reply_id}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$emit('del', {reply_id: this.d.reply_id})
		},

		fav: async function(){
			let d = {
				id: this.d.reply_id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/reply/fav', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.favs++
			this.myMark = 0

			this.$emit('fav', {reply_id: this.d.reply_id})
		},

	},

	components: {
		Favorite,
	},
}
</script>
