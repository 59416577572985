import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import SearchBox from '../../components/SearchBox.vue'
import ProductItem from '../../components/ProductItem/ProductItem'

export default {
	data: function(){
		return {
			isReady: false,
			list: [],
			products: [],
		}
	},

	mounted: async function(){
		const key = 'products'
		let products = JSON.parse(sessionStorage.getItem(key))
		if(products == null){
			let res = await this.$post('/webapi/client/products', {}, true)
			if(res.code != 0){
				alert(res.message)
				return false
				products = res.list
				sessionStorage.setItem(key, JSON.stringify(res.list))
			}
		}
		this.products = products
		this.search(this.$route.params.searchText)
		this.isReady = true
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name, params: {c_code: 'u4755'}})
		},

		search: async function(searchText){
			const text = searchText.toLowerCase()
			this.list = []
			for(let i=0, l=this.products.length; i<l; i++){
				let p = this.products[i]
				if(p.keywords.toLowerCase().indexOf(text) >= 0){
					this.list.push(p)
				}
			}
		},

	},

	components: {
		Header,
		TopCap,
		SearchBox,
		ProductItem,
	},

}
