import { comma, get_products, get_user } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import ProductItem from '../../components/ProductItem/ProductItem.vue'
import MyComment from './MyComment.vue'
import CustomSort from './CustomSort.vue'

export default {
	data: function(){
		return {
			isReady: false,
			isSelectIcon: false,
			closingSelectIcon: false,
			myIcon: 1,
			iconUrl: '',
			nickname: 'ゲスト',
			oenList: [],
			products: {},
			commentList: [], //コメントリスト
			commentCount: 0, //コメント数
			page: 0,
			isMore: true,
		}
	},

	mounted: async function(){
		//ユーザ情報
		let user = await get_user()
		if(user != null){
			this.myIcon = user.my_icon
			this.iconUrl = user.icon_url
			this.nickname = user.nickname || 'ニックネームなし'
		}

		//銘柄データ取得
		let prdObj = await get_products(false)
		this.products = prdObj.obj

		//コメント取得
		await this.getComments()

		//ランキング取得
		let res = await this.$post('/webapi/client/ranking', {rank_type: 5}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.oenList = res.list.splice(0, 3)

		this.isReady = true
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name})
		},

		delCb: function(id){
			//クライアントデータを削除
			for(let i=0, l=this.commentList.length; i<l; i++){
				let m = this.commentList[i]
				if(m.id == id){
					this.commentList.splice(i, 1)
					break
				}
			}
			this.commentCount--
			if(this.commentCount < 0) this.commentCount = 0
		},

		getComments: async function(){
			let cSort = this.$refs.cSort
			let d = {
				sort: cSort.sort == 1 ? 'favs' : 'new',
				page: this.page,
			}
			let res = await this.$post('/webapi/client/comment/mypage', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			//コメント数
			this.commentCount = res.count

			//10件未満になったらもっとみるを削除
			this.isMore = (res.list.length >= 10)

			//最初のページの場合は新規
			if(this.page == 0) this.commentList = []

			for(let i=0, l=res.list.length; i<l; i++){
				let c = res.list[i]
				c.product = this.products[c.c_code]
				if(c.product == null) c.product = {name: ''}
				this.commentList.push(c)
			}
		},


		closeSelectIcon: async function(icon){
			let res = await this.$post('/webapi/client/user/update', {my_icon: icon}, true)
			if(res.code != 0){
				alert(res.message)
			}else{
				this.myIcon = icon
				this.closingSelectIcon = true
			}
			let user = await get_user()
			setTimeout(() => {
				this.isSelectIcon = false
				this.closingSelectIcon = false
			}, 350)
		},

		changeSort: async function(){
			this.page = 0
			await this.getComments()
		},

		moreComments: async function(){
			this.page++
			await this.getComments()
		},

		exLink: function(url){
			window.open(url, '_blank')
		},

		openSelectIcon: async function(){
			let res = await this.$post('/webapi/client/user/profile_image', {}, true)
			this.iconUrl = res.icon_url || ''
			this.isSelectIcon = true
		},

	},

	components: {
		Header,
		TopCap,
		ProductItem,
		MyComment,
		CustomSort,
	},

}
