<template>
<div ref="sliderWrapper" class="sliderWrapper" :style="{height: wH + 'px'}">
	<div ref="panels" class="panels" :class="{panim: isAnim}" :style="{left: left + 'px', width: (pW * pN) + 'px'}" @touchstart="start" @touchmove="move" @touchend="tend" @mousedown="mdown" @mousemove="mmove" @mouseup="mleave" @mouseleave="mleave">
		<slot></slot>
	</div><!--pannels-->
</div><!--sliderWrapper-->
</template>

<style scoped>
.sliderWrapper{
}
.panels{
	position: absolute;
	top: 0;
}
.panim{
	transition: left 300ms ease;
}
</style>

<script>
export default {
	data: function(){
		return {
			startPos: {x: 0, left: 0},
			left: 0,
			dif: 0,
			currentDispNum: 0,
			pN: 0,
			mStart: false,
			id: 0,
			pW: 280,
			wH: 300,
			isAnim: false,
		}
	},

	mounted: async function(){
		//await this.startSlider() クライアント側でスタートさせる
	},

	methods: {
		startSlider: async function(){
			//パネルの個数, パネルの幅（マージン含む）と高さ
			await this.waitUntil(()=>{
				if(! this.$refs.panels) return true
				this.pN = this.$refs.panels.getElementsByClassName('panel').length
				if(this.pN == 0) return false
				let el = this.$refs.panels.getElementsByClassName('panel')[0]
				let style = window.getComputedStyle(el)
				let w = style.getPropertyValue('width')
				let ml = style.getPropertyValue('margin-left')
				let mr = style.getPropertyValue('margin-right')
				let h = style.getPropertyValue('height')
				if(w != '' && ml != '' && mr != '' && h != '' && w != 'auto'){
					this.wH = parseInt(h)
					this.pW = parseInt(w) + parseInt(ml) + parseInt(mr)
					return true
				}
				return false
			})
			//表示幅
			await this.waitUntil(()=>{
				if(! this.$refs.sliderWrapper) return true
				let w = window.getComputedStyle(this.$refs.sliderWrapper).getPropertyValue('width')
				if(w != '' && w != 'auto'){
					return true
				}
				return false
			})
			//初期表示
			this.disp()
		},

		waitUntil: function(func){
			let times = 0
			return new Promise((resolve, reject) => {
				let id = setInterval(() => {
					if(func()){
						clearInterval(id)
						resolve()
					}
					times++
					if(times >= 10){
						clearInterval(id)
						console.warn('errrrr')
						resolve()
					}
				}, 200)
			})
			
		},

		start: function(e){
			this.startPos.x = e.touches[0].clientX
			this.startPos.left = this.left
			this.dif = 0
			this.isAnim = false
		},
		move: function(e){
			this.dif = e.touches[0].clientX - this.startPos.x
			this.left = this.startPos.left + this.dif
		},

		tend: function(){
			let inc = this.hantei()
			this.currentDispNum += inc
			if(this.currentDispNum > this.pN - 1) this.currentDispNum = this.pN - 1
			if(this.currentDispNum < 0) this.currentDispNum = 0
			this.disp()
		},

		mdown: function(e){
			this.startPos.x = e.clientX
			this.startPos.left = this.left
			this.dif = 0
			this.isAnim = false
			this.mStart = true
		},

		mmove: function(e){
			if(! this.mStart) return false
			this.dif = e.clientX - this.startPos.x
			this.left = this.startPos.left + this.dif
		},

		mleave: function(){
			if(! this.mStart) return false
			this.mStart = false
			let inc = this.hantei()
			this.currentDispNum += inc
			if(this.currentDispNum > this.pN - 1) this.currentDispNum = this.pN - 1
			if(this.currentDispNum < 0) this.currentDispNum = 0
			this.disp()
		},

		hantei: function(){
			if(this.dif <= -50){
				return 1
			}else if(this.dif >= 50){
				return -1
			}
			return 0
		},

		disp: function(){
			//アニメーションON
			this.isAnim = true
			//sliderWrapperの横幅を取得する
			let ww = parseInt(window.getComputedStyle(this.$refs.sliderWrapper).getPropertyValue('width'))
			//要素の横幅を取得する
			let asobi = (ww - this.pW) / 2
			this.left = - this.pW * this.currentDispNum + asobi
		},

	},
}
</script>
