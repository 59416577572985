import {dtfmt2} from '../../../lib/utils'
import Favorite from '../Favorite3.vue'
import Reply from './Reply.vue'
import ReplyPostComment from '../PostComment3.vue'

export default {
	props: {
		l: {type: Object, default: () => { return {} } },
		delCb: {type: Function, default: (id)=>{}},
		replies: {type: Object, default: () => { return {} } },
	},

	data: function(){
		return {
			isOpenSubMenu: false,
			replyDisp: false,
			favs: 0,
			myMark: -1,
			replyList: [],
		}
	},

	watch: {
		l: function(n, o){
			this.favs = n.favs
			this.myMark = n.my_mark
			this.isOpenSubMenu = false
			this.$refs.replyPostComment.isDone = true
		},
		replies: function(n, o){
			this.replyList = []
			if(n[this.l.id]){
				this.replyList = n[this.l.id]
			}
		},
	},

	mounted: async function(){
		this.$refs.replyPostComment.isDone = true
		this.favs = this.l.favs
		this.myMark = this.l.my_mark
		setTimeout( () => {
			this.replyList = this.replies[this.l.id]
		}, 100)
	},

	methods: {
		iconKingSrc: function(king){
			if(king == null || king == 0) return ''
			return this.$cdn + '/img/main/king_icon' + king + '.png'
		},

		delComment: async function(){
			this.isOpenSubMenu = false
			//サーバから削除
			let res = await this.$post('/webapi/client/comment/delete', {id: this.l.id}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//コールバック
			this.delCb(this.l.id)
		},

		repComment: async function(){
			this.isOpenSubMenu = false
			//サーバに通知
			let res = await this.$post('/webapi/client/comments/report', {id: this.l.id}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
		},

		dispDate: function(dnum){
			return dtfmt2(dnum)
		},

		replyDone: async function(params){
			let d = {
				comments_id: this.l.id,
				nickname:    params.nickname,
				comment:     params.comment,
				c_theme:     params.c_theme,
			}
			const res = await this.$post('/webapi/client/reply/create', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			//クライアント側に追加
			if(this.replyList == null) this.replyList = []
			this.replyList.unshift(res.reply)
			//終了処理
			this.$refs.replyPostComment.close()
		},

		replyDelCb: function(params){
			for(let i=0, l=this.replyList.length; i<l; i++){
				if(this.replyList[i].reply_id == params.reply_id){
					this.replyList.splice(i, 1)
					break
				}
			}
		},

		fav: async function(){
			let d = {
				id: this.l.id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/comment/fav', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.favCb()
		},

		favCb: function(){
			this.favs++
			this.myMark = 0
		},

		togglePostComment: function(){
			this.$refs.replyPostComment.isDone = !  this.$refs.replyPostComment.isDone
		},

	},

	components: {
		Favorite,
		Reply,
		ReplyPostComment,
	},
}

