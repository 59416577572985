import { comma, get_sdgs_categories } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import SearchTab from '../../components/SearchTab.vue'

export default {
	data: function(){
		return {
			categoryList: [],
		}
	},

	mounted: async function(){
		//sdgsCategories
		this.categoryList = await get_sdgs_categories()
	},

	methods: {
		searchTheme: function(theme){
			this.$router.push({name: 'SearchList', params:{theme: theme}})
		},

	},

	components: {
		Header,
		TopCap,
		SearchTab,
	},

}
