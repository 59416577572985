<template>
<div v-show="isReady">

<Header :isLink="false" />

<div class="main">
	<div>
		<img :src="$cdn + '/img/main/cap_column.png'">
	</div><!--banner2-->

  <h3>コラム記事一覧</h3>

	<ul>
		<li v-for="l in list" :key="l.url">
			<a @click="exLink(l.url)">
        <img :src="`${cdn}/img/column/${l.img}`">
        <div>
          <span v-if="isNew(l.yyyymmdd)">NEW</span>
          <h4>{{ l.title }}</h4>
          <span class="date">{{ `${l.yyyymmdd.slice(0, 4)}.${l.yyyymmdd.slice(4, 6)}.${l.yyyymmdd.slice(6, 8)}` }}</span>
        </div>
      </a>
		</li>
	</ul>
</div><!--main-->

</div>
</template>

<style scoped>
.main{
	margin-bottom: 100px;
}
ul{
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}
li {
  flex-basis: 250px;
  border-right: 1px dashed var(--col-grey-dark);
  border-bottom: none;
  margin: 10px 0;
  align-self: flex-start;
}
li:first-child {
  flex-basis: 100%;
  width: 100%;
  border-bottom: 1px dashed var(--col-grey-dark);
  border-right: none;
}
a {
  color: var(--col-black);
  background-color: initial;
  border: none;
  border-radius: initial;
  height: auto;
  line-height: 1.5;
  text-align: left;
  padding: 0;
  margin: 0;
  word-break: break-all;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: initial;
  height: 100%;
}
li:first-child a {
  flex-wrap: nowrap;
}
a img {
  max-width: 240px;
  padding: 20px;
  margin: auto;
}
li:first-child a img {
  max-width: 350px;
  margin: 0;
}
a div {
  padding: 20px;
}
a span {
  color: red;
  font-size: 18px;
}
a h4 {
  margin: 0;
  font-size: 20px;
}
a .date {
  color: var(--col-black);
  font-size: 12px;
}
@media screen and (max-width: 500px){
  li {
    border-right: none;
    border-bottom: 1px dashed var(--col-grey-dark);
    flex-basis: 100%;
  }
  li:first-child a {
    flex-wrap: wrap;
  }
  a img {
    max-width: 350px;
    margin: auto;
  }
  li:first-child a img {
    margin: auto;
  }
}
</style>

<script>
import Header from '../../components/Header.vue'
import axios from 'axios'

export default {
	data: function(){
		return {
			cdn: CDN,
			isReady: false,
      nowDate: NOW_DATE,
			list: [],
		}
	},

	mounted: async function(){
		this.isReady = true
		this.getList()
	},

	methods: {
		exLink: function(url){
			window.open(url, '_blank')
		},

    isNew: function(yyyymmdd){
      // 7日後の0時まではNEW
      const NEW_DAY_NUM = 7
      const d = new Date(this.nowDate)
      const yyyy = yyyymmdd.slice(0, 4)
      const mm = yyyymmdd.slice(4, 6)
      const dd = yyyymmdd.slice(6, 8)
			let t = new Date(`${yyyy}-${mm}-${dd} 00:00:00`)
      t.setDate(t.getDate() + NEW_DAY_NUM)
      return d < t
    },

		getList: function(){
			axios({
				url: '/static/column_list/list.json',
				responseType: 'json',
			}).then( (res) => {
				const now = new Date().getTime()
				const hn = location.hostname
				res.data.list.forEach( (d) => {
					const dt = new Date(d.releaseDate)
					if(hn === 'aisarekigyo.stockpoint.jp' && dt.getTime() > now) return
					this.list.push(d)
				})
			}).catch( (e) => {
				console.error(e)
			})
		},
	},

	components: {
		Header,
	},

}
</script>
