let data = [
[133473,"u7581","orange","定期的に食べに行きます","粉チーズ代ぐらい、払わせて下さいな。\n私に出来ることは定期的に食べに行くこと位ですが、応援しています。","よ～しIGZO","サイゼリヤ"],
[133306,"u7581","blue","粉チーズ有料化気にしません！","いつも大変お世話になってます！！粉チーズ有料化！全然構いません！応援してます！頑張ってください！！","カワフロ","サイゼリヤ"],
[135608,"u7581","red","庶民のミカタです！","粉チーズを有料化は、他の商品の金額アップを抑える為だったとテレビで見ました。サイゼリヤさんは、本当に庶民のミカタです!ありがとう!","令","サイゼリヤ"],
[133346,"u7581","orange","細部まで気配りされた素敵なお店","サイゼリヤの割れないグラス良いですね👍️『トライタン』というのですねガラス混入事故の防止、軽量化による作業効率アップ、洗浄作業時の破損ストレス軽減、お子様連れにも安心🥰ガラスに拘らない、発想の転換が繁盛の秘訣では？！細部まで気配りされた素敵なお店です。株主＆利用客として、微力ながら応援申し上げます🤗","ペロポネソスで会いましょう","サイゼリヤ"],
[133373,"u7581","blue","冷凍ミラノ風ドリア常備しています","よく利用させて頂いています冷凍のミラノ風ドリアとポップコーンシュリンプ、我が家の冷凍庫に常備しています","なおきち","サイゼリヤ"],
[134436,"u7581","red","考えられない美味しさ","ミラノ風ドリアこの金額では考えられない美味しさクオリティの高さサイドメニューのいろいろ変えて楽しんでます","美美","サイゼリヤ"],
[135451,"u7581","orange","青森県弘前市初オープン","来月ヒロロに弘前市初オープン！とても楽しみです","ちぐら","サイゼリヤ"],
[132978,"u2801","blue","なんにでもかけます","目玉焼きにもアジフライにもコロッケにもなんにでも醤油かけます。お醤油大好き❤","にゃんぱらり","キッコーマン"],
[132770,"u2809","red","株主優待でもらったものを愛用中","周年記念の株主優待でもらった壜のデザインが良くて、今でも詰め替えて利用しています。またあると良いな。","たけし","キユーピー"],
[132680,"u2502","orange","仕事の後の楽しみ","尿酸値を気にしつつ、仕事の後の一杯を楽しんでいます。","みのぱぱ","アサヒグループホールディングス"],
[132743,"u2501","blue","限定発売の男梅サワー飲みたい","AIを活用した男梅サワーに期待してます数量限定発売のためなかなか売っていないけど早く飲みたいです","ゆいくん","サッポロホールディングス"],
[134633,"u1332","red","推しは、焼きおにぎり","ここの商品は、どれも自然解凍でも美味しいので、非常に期待のもてる銘柄だと思います。個人的な推しは、焼きおにぎりです。","たけちゃん","ニッスイ"],
[135569,"u1332","orange","12株までになりました！","コツコツポイント投資して、一株になったらconnectに移して12株までになりました！これからも応援してます！","yo","ニッスイ"],
[134245,"u2804","blue","ほっぺがおちちゃう","夏祭りと言えばたこ焼き!これにブルドックソースをかければ最高!ほっぺがおちゃうほどだぁ～い好き💞","さくら","ブルドックソース"],
[134567,"u2804","red","ソース派てす","家は、目玉焼きにソース派てす","そら","ブルドックソース"],
[135456,"u2804","orange","毎日家族全員が使う","我が家では、毎日ブルドッグソースにお世話にならない日はありません。目玉焼き、野菜炒め、焼きそばなど、家族全員が使うので本当に感謝しています。","SF","ブルドックソース"],
[134334,"u7564","blue","街でよく見かける","ファンつきのウエア、最近街なかでよくみかけるようなりました。ワークマンさんの功績ですね。ひたすら暑さを我慢する時代は終わりにして、快適に過ごせる社会を期待できそうです！","ソウルボーイ","ワークマン"],
[134519,"u7564","red","長年愛用のチノパン","3年ほど前に購入したチノパン、擦り切れることもなく履きやすく、大変重宝しています！これからも作業着などに留まらず、沢山の素敵な商品待ってます！","ねこのて","ワークマン"],
[135652,"u7564","orange","コスパの高さ！","作業着のイメージを一新させたデザイン性の高さと機能性。そして何よりコストパフォーマンスの高さを感じさせる価格帯。これからも良い商品をたくさん提供してください！","キレる前にキリかえる","ワークマン"],
[132432,"u2899","blue","お茶漬けは永谷園！","朝茶漬け昼茶漬け夜茶漬け夜食茶漬け飲んだ後茶漬け寒い日にも茶漬け毎日暑い今年の夏も冷茶漬けお茶漬けは永谷園！これからも宜しくお願いいたします。応援しております！","satisfaction","永谷園ホールディングス"],
[134963,"u2899","red","子供のころから変わらない味","私は63歳の東京都民です。出身は埼玉県ですが、私の子供の頃から朝ごはんに、大人になってからはお酒を飲んだ後の締めにとお世話になって来ました。鮭や明太子など味のバリエーションも豊富になり、選ぶのに困るくらいになりました。これからも変わらない味を大事にしてください。","のりへー","永谷園ホールディングス"],
[134647,"u1377","orange","トマトと子供の成長が楽しみ","家庭菜園始めました。子供が選んだのがタカタのタネのミニトマトでした。ミニトマトと子供の両方の成長が楽しみです。","エリシオ","サカタのタネ"],
[135776,"u1377","blue","かたちが悪いのも愛おしい！","自分で育てた野菜って、一味ちがうように感じますよね。かたちが悪いのも愛おしい！","茶トラ軍団","サカタのタネ"],
[134520,"u7936","red","一番履きやすくて、一番安い","最近、足が痛いので、5足靴を買ったけど、アシックスが一番履きやすくて、値段は一番安かった。ありがとうございます。","ながワン","アシックス"],
[135036,"u7936","orange","青春のバッシュ","学生時代バッシュでお世話になりましたその頃は幅広甲高のバッシュが海外メーカーではあまり無かったので凄く助かっていました","ケロすけ","アシックス"],
[133308,"AMZN","blue","気遣いに感謝","配達ボックス埋まってるから明日届けますねーってわざわざ電話くれた！忙しいのにありがとう","マサ","アマゾン ドットコム"],
[134532,"u6645","red","健康管理に欠かせない！","毎日低周波治療器や血圧計を使用しています。健康管理には欠かせません。いつも有難うございます。これからも使い易い商品をお願いします。","トロ","オムロン"],
[135073,"u6645","orange","健康診断を機に血圧計を購入","今年の健康診断で高血圧との結果が出たため、オムロン製血圧計を買いました毎日測定すると、朝の最低血圧が高いことが判明塩分摂取を調整することにしました","556","オムロン"],
[134491,"t7203p3","blue","ずっと推します！","推します！ずっと推します！！ずっと推せるはずです！！！と初心者は自分に言い聞かせています！！！！","しん","【推しカブ】トヨタ自動車"],
[135595,"t7203p3","red","自動車整備士の友人が言ってた","車の個体差の幅が少ないのがトヨタだって自動車整備士の友人が言ってました．","tantakatan","【推しカブ】トヨタ自動車"],
[134525,"t7203p3","orange","営業利益1兆円！！","やはり世界のトヨタ。四半期で営業利益1兆円はすごい👍。応援します。","のんびりとーさん","【推しカブ】トヨタ自動車"],
[134540,"t7203p3","blue","もう一度楽しそうな車に乗りたい","20数年前乗っていたセリカST-205の後、60歳までにもう一度楽しそうな車に乗りたいと思っていたら見つけてしまったGRヤリス....WRC頑張って欲しいです！","ひ〜さん","【推しカブ】トヨタ自動車"],
[134878,"t7203p3","red","世界中で信頼されている車","新型ランクルのお披露目がありましたね！世界中の過酷な環境で信頼され使われている車であるランドクルーザー、まだまだBEVでは走破できない道が世界中にはたくさんあるので、頑張って欲しいです。","とも","【推しカブ】トヨタ自動車"],
[134681,"u9202","orange","羽生結弦さんもANAも一緒に応援","羽生結弦さんが大好きなので、ANAも一緒に応援します！","ららら","ＡＮＡＨＤ"],
[134682,"u9202","blue","入籍。。。びっくりです","羽生結弦さん、入籍発表が。。。びっくりです。","りを","ＡＮＡＨＤ"],
[134908,"u9202","red","利用者の選択肢が新たに","新ブランドAir Japan発表。利用者の選択肢が新たにでき期待しています。","どら","ＡＮＡＨＤ"],
[134735,"u9104","orange","海運で開運","海運で開運","定吉七番半","商船三井"],
[133109,"u9104","blue","配当金で船旅するのが夢！","高配当いつもありがとうございます配当金で船旅するのが夢です。これからも応援してます","いー","商船三井"],
[134539,"u9104","red","世界を巡る憧れ","海運は高田屋嘉兵衛からの日本の基幹産業です世界を巡るのは憧れますね。","ただの仙台人","商船三井"],
[135413,"u9104","orange","日本の長期的な成長に大切な企業","日本は島国。株をはじめてから、海運の重要性を知りました。日本の長期的な成長のためにも、大切な企業です。","ぐあじ","商船三井"]
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

