let data = [
[149093,"u7951","blue","ずっと前からつかっています","管楽器、ずっと前から使っています。吹きやすく音程も取りやすい。感謝感謝です♪","ゆい","ヤマハ"],
[148711,"u9783","red","お世話になりました","こどもの教材でお世話になりました。","まゆか","ベネッセホールディングス"],
[145483,"u9470","orange","還暦になりました","還暦になりました。これからもお世話になります。よろしくお願いしますねー♪","みのぱぱ","学研ホールディングス"],
[149172,"u2933","blue","水餃子","紀文さんの練り物は好きです。水餃子も有ることを今回初めて知った。今度見かけたら食べてみよう。此れからも応援します。","メロメ","紀文食品"],
[149513,"u2933","red","尾花沢観音様が目につきます","山形県最上郡出身です。帰省の時、国道13号線、尾花沢観音様が目につきます。帰ってきた気分になります。紀文さんありがとうございます。","おかワンコ","紀文食品"],
[149307,"u2801","orange","わが家","わが家はいつもわが家は焼肉屋さんつかってます！おかげでいつもわが家は焼肉屋さんです（笑）","きゃべつ","キッコーマン"],
[148929,"u2208","blue","絶対に裏切らない！","BOURBONさんのお菓子は、絶対に裏切らない！いつも安定の美味しさで、つい、手が出てしまいます。ありがとうございます。","くまくまぷー","ブルボン"],
[148562,"u2594","red","好きです","トアルコトラジャが好きです。","Nyasi","キーコーヒー"],
[149473,"u3197","orange","店長として","もとスカイラーカーです。ガストの立ち上げ時に店長として身体を張って頑張りました。良い企業になっていくことを応援します。","まる","すかいらーくホールディングス"],
[149258,"u7532","blue","通ってる","メガドンキもいいぞ。泥棒市場のころから通ってる","どすこい","パン・パシフィック・インター"],
[149554,"u8227","red","70周年","しまむら、70周年おめでとうございます♪オールシーズン、お世話になってます♪","のちい","しまむら"],
[149022,"u7564","orange","しかもお安い！！","暖かいおしゃれな普段着も多く取り揃えていて、しかもお安い！！人気のためか欲しいサイズがなかったりしますが、応援している企業なので、それも良し(^^)","ばっはっは","ワークマン"],
[148665,"u7780","blue","試してみたい","コンタクトは使用していないが、試してみたい。","スナフキン","メニコン"],
[149148,"u7762","red","彼女への","彼女のクリスマスプレゼント🎁に買います","アンディ","シチズン時計"],
[148779,"u8050","orange","いつか･･･","成人式にSEIKOの時計をお祝いでいただいてから、ずっとSEIKO推しになりました。いつかグランドSEIKOの時計を身に付けたいと思っています。これからも頑張ってください！","ごんた","セイコーグループ"],
[149532,"u9831","blue","優待券","年2回の優待券はお得な気分になります。引越しで電化製品を買い替えの時にははヤマダを利用します！","くう","ヤマダホールディングス"],
[148893,"u6367","red","快適な日々を過ごせる","昨年うるさら7から10に交換しました。7も快適でしたが、10は凄い進化していて、更に快適な日々を過ごせるようになりました。技術者の皆様ありがとうございました😊","みほパパ","ダイキン工業"],
[149069,"u6503","orange","エコキュートを買いました","三菱のエコキュートを買いました。家族がひとり減ったのありますが、電気代半分近く下がって助かってます。","花梨","三菱電機"],
[149505,"u4839","blue","これからも","音楽🎵ライヴ、いつも楽しみにしています。これからもわだいのアーティストの番組提供期待しています。","たら","ＷＯＷＯＷ"],
[149556,"AMZN","red","ブッラクフライデー","ブラックフライデーで気になってたグッズ買いました！","しずくん","アマゾン ドットコム"],
[148515,"BABA","orange","他には無い品揃え","たまに利用させてもらっています。商品が手元に届くまでにはちょっと時間を要しますが、楽天やアマゾンには無い品揃えで、買い物を楽しませてもらっています。","163","アリババ・グループ"],
[149194,"u3092","blue","何だかんだで","前澤さんがCD売ってた頃から利用してます♥何だかんだで衣類はほぼzozoでまかなってる感じです。これからも新しいブランドお願いします","ぱぴこ","ＺＯＺＯ"],
[149278,"u9064","red","より応援したくなりました！","先ほど、配達員の方が水に沈んだ軽自動車から女性を救出したというニュースを拝見しました。前から好印象な企業でしたが、より応援したくなりました！","むらみん","ヤマトＨＤ"],
[148927,"u8801","orange","とても親切","三井のリハウス四半世紀に渡りお世話になりました。とても親切な会社です。","リー","三井不動産"],
[148524,"u8058","blue","勝者","日本を代表する商社、勝者","Ta","三菱商事"],
[148527,"u9672","red","たまに","たまにですが大井競馬でのレースに投票してます。これからも楽しみを提供してくださいね。","たかたかぼんぼん","東京都競馬"],
[148798,"u8306","orange","60歳になってから転職","最近、60歳になってから就職しまして、給料振込の為、三菱UFJ銀行に口座を作りに行き、その時の担当していただいた方が、とてもわかり易く、丁寧に説明してくださったので、すぐに作ることができ感謝しています。","イラン","三菱UFJ"],
[148769,"u8410","blue","24時間営業","家の近所に銀行はありませんが、セブン銀行ATMがあるので助かります。しかも24時間営業！ありがとうございます！！","s178","セブン銀行"],
[149409,"u5020","red","単元株","私も単元株を目指してみようと思います。","まさお","ＥＮＥＯＳホールディングス"],
[148501,"RACE","orange","スーパーカー","マナビレベル23になったので買いました！スーパーカーは憧れをこれからも担ってほしい","mint","フェラーリ"],
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

