<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #02
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg2/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg2/MV.png'">

		<h1>ＥＳＧは「三方良し」</h1>
		<p class="dt">2023/10/6</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">

	<pre><span class="uline">ＥＳＧという言葉</span>が公式に初めて世に出たのは、国連系組織が責任投資原則（ＰＲＩ※）を発表した２００６年であることを前回記した。「公式に初めて」と断ったのは、その前の段階もあるからだ。
　※PRI：Principles for Responsible Investment

　２００４年１月、時のアナン国連事務総長は全世界の５０の金融機関に手紙を出した。
　国連のMＤＧｓ（ミレニアム開発目標）が掲げるジェンダー平等推進や乳幼児死亡率の削減、感染症の防止などを達成するための資金調達手段について、知恵を募るためだ。MＤGｓとは現在私たちがよく耳にするＳＤＧｓ（持続可能な開発目標）の前に策定されたグローバル経済社会のゴールだ。</pre>

	<div class="grey">
	<h4>ＭＤＧｓ（ミレニアム開発目標）</h4>

	<img class="img" style="margin-top: 10px;" :src="cdn + '/img/rensai/esg/esg2/pic1.png'">


<pre>１.極度の貧困と飢餓の撲滅
２.普遍的な初等教育の達成
３.ジェンダー平等の推進と女性の地位向上
４.乳児死亡率の削減
５.妊産婦の健康の改善
６.HIV/エイズ、マラリア、その他の疾病のまん延防止
７.環境の持続可能性の確保
８.開発のためのグローバルなパートナーシップの推進
<span class="right">（国連ＷＦＰより）</span></pre>
	</div><!--grey-->


<pre>　アナン事務総長の手紙を受け取った５０社のうち、２０社と国連が議論を重ねた。
そして、２００４年に
<span class="uline">「Who Cares Wins」</span>
という報告書を発表。ここにＥＳＧという言葉が初めて記され、２年後のＰＲＩの礎となった。

　<span class="uline">ＥＳＧはマーケットの流行語として</span>例を見ないほど長きに渡って使われ続けたが、その大きな理由のひとつは、<span class="uline">公的機関の主導でつくり出されたものだった</span>という点だ。
　特定の金融機関が自社の投資アイディアや商品を売り込むために構想したものではなかった。

　しかし、ＥＳＧが爆発的に広まった理由は、国連の後ろ盾だけではないだろう。
　ＥＳＧが世に出た２１世紀初頭は、インターネットバブルの崩壊によって世界の市場が混乱。さらに、米同時テロ（９・１１）によって米国が体現してきた資本主義の価値観が大きく揺らいでいた。ＢＲＩＣｓ（ブラジル・ロシア・インド・中国）の言葉をつくったジム・オニール氏は「９・１１による世界秩序の変革が造語考案の理由だった」と語っているが、当時は米欧先進国が新しい価値観や世界秩序を包含する概念を追い求めている時代だった。
　そうかといって、旧ソ連のような社会主義経済が機能しないこともすでに分かっていた。
　とすれば、<span class="uline">市場経済の文脈に「環境保護」や「社会正義」といった要素をうまく組み込む術</span>を考えるべきではないか。そう考える金融市場の関係者が多かったのである。

　日本にもＥＳＧを受け入れる素地は十分にあった。
<span class="uline">「売り手良し、買い手良し、社会良し」で知られる近江商人の「三方良し」</span>は、事業の社会的な価値を重視する日本的経営を特徴づけるものだが、ＥＳＧに相通ずるものがある。

　欧州ではＥＳＧが世に出る前、「社会的責任投資（ＳＲＩ※）」という考え方も広まっていた。読んで字のごとし、投資家がビジネスの社会的側面にも注目して企業を評価する手法だ。南アフリカの黒人隔離政策（アパルトヘイト）に抗議する投資家が、企業に圧力をかけ南ア事業から撤退を求める動きも過去にはあった。
※SRI：Socially Responsible Investment

　このように、<span class="uline">投資に社会的な価値観を見いだす考え</span>は決して新しいものではなく、市場の歴史にくり返し表れてきた。ミレニアム（千年紀）の変わり目の<span class="uline">新たな価値観を求める空気が、「ＥＳＧ」を時代の全面 に押し出した</span>と見ることができる。</pre>


	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<img :src="cdn + '/img/rensai/esg/esg2/next.png'">
		<p class="title">「ＥＳＧは儲かるのか？<br>儲からないのか？」</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg2/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	min-height: 60px;
	background-color: #D7E9EB;
	margin: 20px auto;
}
.next img{
	position: absolute;
	width: 46px;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}
.next .title{
	margin-left: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: 600;
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 4,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
