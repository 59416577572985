<template>
<div class="main">

	<div class="MVWrp">
		<img class="MV" :src="cdn + '/img/rensai/nobu_to_yasu/MV2.png'">

		<pre>今、令和は変革の戦国世。
虎視眈々と天下を狙うは──この会社！
短期投資型のノブと長期投資型のヤスが
それぞれの投資についてお伝えしていきます。</pre>
	</div><!--MVWrp-->


	<Column ref="column" />


	<div class="bkPink">
		<p class="col">コーポ戦国荘のゆかいな住人たち</p>

		<div class="chara nobu">
			<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_nobu2.png'">
			<p class="name">ノブ</p>
			<pre>頭脳明晰で行動派、ちょっと短気。朝はすぐ着替える派。気分が上がると踊る。最新機器に目がなくスマートなふるまいだが、それらに常に散財しているため、短期投資で儲けようと思っている。</pre>
		</div><!--left-->

		<div class="chara yasu">
			<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_yasu2.png'">
			<p class="name">ヤス</p>
			<pre>じっくり思考ののんびり屋さん。朝からエプロンしてミルからコーヒーを作るのが日課。肩にたぬきがのっている。家庭菜園のなすが育っていくのが楽しみ。投資は長い目でみたいタイプ。</pre>
		</div><!--right-->
		<br>
		<div class="chara hide">
			<img width="32px" :src="cdn + '/img/rensai/nobu_to_yasu/chara_new.png'">
			<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_hide2.png'">
			<p class="name">ヒデ</p>
			<pre>Sengoku Eatsの配達員で大成していくため奮闘中。チップをたくさんもらっている優秀な配達員でノブとヤスが住む戦国荘によく配達に来る。投資はじっくりと行いたいタイプのため、投信信託をしている。</pre>
		</div>


		<div class="talkWrp">

			<h4>連載小話　ノブとヤス</h4>

			<ul class="talk">
				<li @click="linkTo('talk', 4)" v-if="isTalkShow()">
					<img :src="cdn + '/img/rensai/nobu_to_yasu/talk4.png'">
					<p class="num">第4話</p>
					<p class="title">新キャラ登場！</title>
					<p class="dt">1/19 更新</p>
				</li>

				<li @click="linkTo('talk', 3)">
					<img :src="cdn + '/img/rensai/nobu_to_yasu/talk3.png'">
					<p class="num">第3話</p>
					<p class="title">それぞれ</title>
					<p class="dt">12/4 更新</p>
				</li>


				<li @click="linkTo('talk', 2)">
					<img :src="cdn + '/img/rensai/nobu_to_yasu/talk2.png'">
					<p class="num">第2話</p>
					<p class="title">選べない</title>
					<p class="dt">10/20 更新</p>
				</li>

				<li @click="linkTo('talk', 1)">
					<img :src="cdn + '/img/rensai/nobu_to_yasu/talk1.png'">
					<p class="num">第1話</p>
					<p class="title">それが日常</title>
					<p class="dt">9/8 更新</p>
				</li>

			</ul>

		</div><!--talk-->


	</div><!--bkPink-->



</div><!--main-->
</template>



<style scoped>
.main{
	margin-bottom: 100px;
}

.MVWrp{
	position: relative;
	background-color: #FCFAEF;
	padding-bottom: 40px;
}

.MVWrp .MV{
	display: block;
	max-width: 100%;
	height: 580px;
	object-fit: cover;
	object-position: bottom center;
	margin: -80px auto 0 auto;
}
.MVWrp pre{
	text-align: center;
}


.bkPink{
	background-color: #FCF2F2;
	text-align: center;
	padding-bottom: 40px;
}
.bkPink .col{
	margin: 20px auto;
}
.bkPink .chara{
	display: inline-block;
	width: 156px;
}
.bkPink .chara.nobu{
	margin-right: 20px;
}
.bkPink .chara .name{
	font-size: 12px;
}
.bkPink .chara pre{
	font-size: 12px;
	line-height: 20px;
	text-align: left;
}

.talkWrp{
	background-color: white;
	max-width: 335px;
	margin: 0 auto;
}

.talk li{
	position: relative;
	min-height: 62px;
	cursor: pointer;
	max-width: 295px;
	margin: auto;
	margin-bottom: 20px;
}
.talk li img{
	position: absolute;
	top: 0;
	left: 0;
	width: 70px;
	height: 62px;
	object-fit: cover;
	border: 1.5px solid #1A1A1A;
}
.talk li .num{
	display: inline-block;
	min-width: 45px;
	margin-left: 90px;
	margin-top: 8px;
	line-height: 24px;
}
.talk li .title{
	display: inline-block;
	line-height: 24px;
}
.talk li .dt{
	display: block;
	margin-left: 90px;
	font-size: 12px;
	line-height: 20px;
}

.talk li:first-child{
	min-height: 140px;
	border: 1.5px solid #1A1A1A;
}
.talk li:first-child .num{
	position: relative;
	display: block;
	width: 100%;
	height: 50px;
	line-height: 50px;
	text-align: center;
	border-bottom: 1.5px solid #1A1A1A;
	margin: 0;
}
.talk li:first-child img{
	width: 100%;
	height: 88px;
	bottom: 0;
	left: 0;
	top: auto;
}
.talk li:first-child .title{
	position: absolute;
	bottom: 30px;
	left: 0;
	width: 100%;
	text-align: center;
	color: white;
	font-size: 16px;
}
.talk li:first-child .dt{
	position: absolute;
	top: 15px;
	left: 10px;
	background-color: #1A1A1A;
	color: white;
	width: 75px;
	height: 20px;
	line-height: 20px;
	margin: 0;
	text-align: center;
}

</style>


<script>
import Column from './Column.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			marks: [0,1,2],
		}
	},

	methods: {
		//初期化
		init: function(){
			this.$refs.column.getFavs()
		},

		linkTo: function(tp, num){
			location.href = '/rensai/nobu_to_yasu/' + tp + '/' + num
		},

		isTalkShow: function(){
			if(location.hostname != 'aisarekigyo.stockpoint.jp') return true
			const dt = new Date('2023-12-04T10:00:00+0900')
			const now = new Date()
			return dt <= now
		},
	},

	components: {
		Column,
	},
}
</script>
