<template>
<div>
<TopCap page="event" />

<h3>最新の「ステキなコメント投稿」会員様</h3>

<img class="mv" :src="$cdn + '/img/main/bnr_event_aisare_comment.jpg'">

<p class="notes">「ステキなコメント投稿」して下さった会員の皆様、TOP10です。</p>

<ul>
<li v-for="(l, i) in ranking" :key="i" @click="linkToPage(l.aisare_id)">
	<p class="num">{{i + 1}}</p>
	<p class="nickname">{{l.nickname.length > 12 ? l.nickname.slice(0, 12) + '...' : l.nickname}}</p>
	<div class="count"><img :src="$cdn + '/img/main/icon_comment_green.svg'"><span>{{comma(l.count)}}</span></div>
</li>
</ul>

<Footer />

</div>
</template>

<style scoped>
.mv{
	display: block;
	margin: 20px auto;
}
.notes{
	margin: 10px;
	font-size: 14px;
	line-height: 20px;
}
ul{
	margin: 20px auto;
}
li{
	height: 55px;
	width: calc(100% - 20px);
	margin: 0 10px;
	padding: 0 10px;
	border-bottom: 1px solid #E5E5E5;
	text-align: left;
}
.num{
	display: inline-block;
	width: 35px;
	font-size: 24px;
	font-weight: 600;
	color: var(--col-main);
	text-align: center;
	line-height: 55px;
	vertical-align: middle;
}
.nickname{
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	color: #333333;
	line-height: 55px;
	vertical-align: middle;
}
.count{
	position: absolute;
	top: 20px;
	right: 10px;
	text-align: right;
	height: 16px;
	line-height: 16px;
}
.count span{
	font-size: 10px;
	margin-left: 10px;
	line-height: 16px;
	color: var(--col-main);
	vertical-align: middle;
}
</style>

<script>
import {comma} from '../../../../lib/utils'
import TopCap from '../../../components/TopCap.vue'
import Footer from '../../../components/Footer.vue'

export default {
	data: function(){
		return {
			ranking: [],
			term: '',
		}
	},

	mounted: async function(){
		let res = await this.$post('/webapi/client/weekly_ranking/index', {id: 2}, true)
		if(res.code != 0){
			return false
		}
		this.term = res.term
		this.ranking = res.list
	},

	methods: {
		comma: function(num){ return comma(num) },

		linkToPage: function(aisare_id){
			const s = "abcdefghijklmnopqrstuvwxyz"
			const l = s.length
			let enc = ''
			for(let i=0 ; i<5; i++)
				enc += s[Math.floor(Math.random()*l)]
			enc += aisare_id.toString(16)
			this.$router.push({name: 'OtherPage', params:{enc_aisare_id: enc}})
		},

	},

	components:{
		TopCap,
		Footer,
	},
}
</script>
