import { comma, get_products } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import SearchTab from '../../components/SearchTab.vue'
import CommentItem from './CommentItem.vue'

export default {
	data: function(){
		return {
			isReady: false,
			cTheme: -1,
			themeList: [],
			products: {},
			commentList: [],
			page: 0,
			isMore: true,
		}
	},

	mounted: async function(){
		//銘柄マスタ
		let prdObj = await get_products(false)
		this.products = prdObj.obj

		//コメントを取得する
		await this.getComments()

		this.isReady = true
	},

	methods: {
		/*comma wrapper*/
		comma: function(num){ return comma(num) },

		getComments: async function(){
			let d = {
				page: this.page,
			}
			if(this.cTheme != -1) d.c_theme = this.cTheme
			let res = await this.$post('/webapi/client/comment/search_index', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//もっとみるの表示切り替え
			this.isMore =  res.list.length < 10 ? false : true

			//1ページ目を表示するときは初期化する
			if(this.page == 0) this.commentList = []

			for(let i=0, l=res.list.length; i<l; i++){
				let c = res.list[i]
				c.product = this.products[c.c_code]
				if(c.product == null){
					c.product = {name: ''}
				}
				this.commentList.push(c)
			}
		},

		moreComments: async function(){
			this.page++
			await this.getComments()
		},

		changeCTheme: async function(cTheme){
			if(this.cTheme == cTheme) return false
			this.cTheme = cTheme
			this.page = 0
			await this.getComments()
		},

	},

	components: {
		Header,
		TopCap,
		SearchTab,
		CommentItem,
	},

}
