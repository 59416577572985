let data = [
    [162722,"u8306","red","大谷翔平結婚おめでとう！！！","オオタニサン結婚おめでとう〜！！","オオヤ","三菱UFJ"],
    [162843,"u2764","orange","もちろん、接客も抜群","結婚式で利用させていただきました。特にお料理がめちゃめちゃ美味しく、参列者の方にも非常に喜んでいただくことができました。もちろん、接客も抜群でした！","やえば","ひらまつ"],
    [164925,"u8050","blue","大谷翔平モデルを身につけて","昨年から、アストロンネクスター(NEXTER)大谷翔平モデルを身につけています。デザインの良さ、精巧な技術に惚れ惚れしています！","銀次郎","セイコーグループ"],
    [163452,"u7762","red","案外身近なところにも","最近気付いた事があります家にあるメガネ洗浄機シチズン製でした案外身近なところにもシチズン製ってあったんですね感謝ですね（笑）","おっさんとポコちゃん","シチズン時計"],
    [164642,"NVDA","orange","愛着がある","NVIDIAと言えば、画像用半導体や画像用ボードだなぁ。今は、「自作PC」なんて流行らなくなったが、NVIDIAの名前は自作PCに凝っていた時代に知ったメーカーだった。愛着がある。今も頑張っているんだなぁと、応援したい！","a_lucky","NVIDIA"],
    [164728,"NVDA","blue","懐かしい","nVIDIAの頃が懐かしいf^_^;","はりはり漬け","NVIDIA"],
    [163285,"u8035","red","資産形成のエンジン","自分の資産形成のエンジンです。なんと言っても「国策銘柄」ですもの。","やどりき","東京エレクトロン"],
    [165022,"t6758p3","orange","ソニー好き歴40年","ソニー好き歴40年くらいです。スマホはXperia、テレビはBRAVIA、VAIOもWALKMANもまだ使ってます。通信キャリアはSo-net、NUROモバイル。生保、損保も有ります。日本が誇る企業の一つです。創業者の盛田さん、井深さんの精神を受け継いで、他に無い、革新的な事業を期待しています。","よっすぃ〜","【推しカブ】ソニーグループ"],
    [165131,"u9064","blue","素晴らしい理念","サービスが先！利益は後！ 素晴らしい理念です。","きよ","ヤマトホールディングス"],
    [162434,"AMZN","red","選ばれた","アマゾンがダウの構成銘柄に選ばれたの知ってはります？って京都弁で聞いてみた","牛心隊長","アマゾン ドットコム"],
    [164103,"u1546","orange","やはりコツコツ積立は強い","最初は時々マイナスだったけど着々と上がってきた。やはりコツコツ積立は強い。","i","米国株指数30種"],
    [163409,"IVV","blue","最大の利益に","IPOで得た利益の半分でS&P500を購入するのがマイルールです。結果、私の最大の資産かつ最大の利益になりました。ありがとうS&P500","チェリー","iシェアーズ・コア S&P 500 ETF"],
    [162954,"u1540","red","チャンスとして捉えて","順調に右肩上がりですね❕、コツコツ積立するには最適な感じです、更に何処かのタイミングで急落しても、それはチャンスとして捉えて地道に淡々と積み立てていきたいですね❗","ys","純金上場信託"],
    [164640,"bitcoin","orange","お小遣いになる事を期待","エイベックスの会長を見習って、コツコツ積立戦術で頑張ってきます。ポイント投資なので、あれほどのリターンはないけど、リスク許容もできるので、ちょっとしたお小遣いになる事期待してます！","Ku","bitcoin"],
    [163936,"u7309","blue","いつか使ってみたい","シマノのステラいつか普通に使ってみたいです。","13oz","シマノ"],
    [163421,"u6301","red","あまりのカッコ良さに","子供の頃近所の工事現場で稼働中のコマツ/パワーショベルを見てあまりのカッコ良さにオペレーターになりたいと思いました(笑)。","クワトロ","コマツ"],
    [164963,"u7832","orange","鳥山明作品追悼フィギュアを作成して欲しい","鳥山明作品のフィギュアを沢山作っているバンダイ！是非追悼フィギュアを作成して欲しいです！！","ハッピードラゴン","バンダイナムコホールディングス"],
    [164553,"u8136","blue","サンリオさんのおかげです","小学校低学年頃、目の病気を発症しました。辛い入院生活を支えてくれたのが、サンリオの様々なキャラクター達でした。今があるのは、サンリオさんのおかげです。ありがとうございます。今少しずつですが、株式を購入させていただいています。","燕","サンリオ"],
    [163819,"u4839","red","３０年以上利用","映画が好きでWOWOW３０年以上利用してます!今後も新作映画の放送期待してます!","スラムダンク","ＷＯＷＯＷ"],
    [165203,"u2217","orange","入院中の差し入れのチョコレート","今年のバレンタインは入院中。差し入れのチョコレートの美味しさが嬉しかった。","qp","モロゾフ"],
    [164720,"u2217","blue","バレンタインを根付かせた","愛されトリビアをよんで、初めて、日本にバレンタイン文化を根付かせたのが、モロゾフだと知りました。モロゾフがバレンタインを根付かせてくれたおかけで、2月になると、百貨店などでのバレンタインフェアで、世界・日本各国のチョコを楽しめると思うと、感謝感謝です(ㅅ´꒳` )あと、モロゾフといえば、プリンも美味しいですよね(´﹃｀)プリンのガラス容器は、リサイクル可能なものとの事でSDGsや環境に配慮されたもの。このプリンの容器が店頭回収可能になって、ポイントやスタンプがついたりして、次にモロゾフの商品を買う時に使えたり、引き換えできるととても嬉しいです!!","Lucas","モロゾフ"],
    [165284,"u2222","red","知らないだけで皆さんも","LeTAO大好きです🥺 他にもバターバトラーやドロスなど、知らないだけで皆さんの好きなお菓子はよくよく見てみれば寿スピリッツかも🤔 頑張ってください！ いつも美味しいお菓子をありがとうございます🤤","ちのすけ","寿スピリッツ"],
    [163655,"u2269","orange","子供の遠足でお世話に","きのこの山、たけのこの里など子供の遠足でお世話になりました。永く続きますように。","ksaoi38","明治ホールディングス"],
    [163882,"u2206","blue","必ずグリコのキャラメル","小生、66歳。小学生の学年ごとの遠足では、必ずグリコのキャラメルありました。マックス100円のお菓子選ぶのが大変迷ってました。懐かしい。","おかワンコ","江崎グリコ"],
    [164099,"u3382","red","はまっています","セブンブランドの亀田製菓とのコラボ柿の種はとても美味しいです。ピーナッツの割合が良いのか、あられの味付けなのかとてもバランスが良くて、はまっています。","とし","セブン＆アイ・ホールディングス"],
    [164487,"u2651","orange","母が歩いてよく買いに行ってくれた","拒食症だった時、唯一唐揚げくんが食べれた私、近くにローソンなんてなかったから、母が歩いてよく買いに行ってくれた。そんな私は親と喧嘩別れしたまま。今母親になってやっと母の気持ちも理解しました。改めて母は偉大だと日に日に思うようになりました。いつか、笑顔で会える日が来ることを望んでいます。","あゆち","ローソン"],
    [164530,"u2702","blue","子供にも分かる美味しさ","マクドナルドで３歳位のお兄ちゃんがハンバーガーが美味しいので半年位の弟に食べさせたらお母さんがダメ離乳食がまだ始まって無いお父さんが口から取りだそうしても物凄く噛みついて離さない。子供にも分かる美味しさ。","レモンミントキャンディ","日本マクドナルドホールディングス"],
    [163182,"u3038","red","ピザをつくりお客様から高評価","個人経営の飲食店なので業務スーパーはありがたいです。冷凍トルティーヤでピザをつくりお客様から高評価いただいております。お値段手頃の業務用食材が揃っているので安心です。","ゆうさん","神戸物産"],
    [164098,"u2802","orange","20年前のフライパンでもくっつかない餃子","味の素の冷凍餃子の開発、凄いですね。流石に、２０年前のフライパンでは、剥がれないのは当たり前。それをあそこまで執念を持って、くっつかない餃子を開発し続けるのは、素晴らしい会社です。冷凍餃子の値段はそんなに高いものでもないのに、あれほどの期間と労力を使っているとは、思ってもいませんでした。流石、プロ根性。沢山食べて応援しますので、負けないでください。","向日葵","味の素"],
    [164519,"u9024","blue","八海山までスキー","先日、初めて八海山までスキーに出かけました。西武だったのですね😳✨ゴンドラで上まで上がると絶景🏔´-40周年の記念ステッカーも頂いて満足でした。","ヨシ","西武HLD"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })