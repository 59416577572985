import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'

export default {
	data: function(){
		return {
		}
	},

	mounted: async function(){
	},

	methods: {
		exLink: function(url){
			window.open(url, '_blank')
		},

		back: function(){
			history.back()
		},
	},

	components: {
		Header,
		TopCap,
	},

}
