<template>
<div class="sampleMsg">

	<div class="msgWrp">
	<div class="msg" v-show="msgShow">
		<p class="pName">{{d.p_name}}</p>
		<pre>{{d.msg.slice(0,45)}}...<span>(一部抜粋)</span></pre>
		<p class="nickname">{{d.nickname}}</p>
		<div class="triangle"></div>
	</div><!--msg-->
	</div><!--msgWrp-->

	<div class="anim" :style="{bottom: bottom + 'px', width: width + 'px', height: height + 'px', opacity: opacity}">
	</div><!--anim-->

	<img class="img" width="293" height="203" alt="" :src="$cdn + '/img/main/index/msg' + d.img_id + '.png'" @load="loaded">

</div><!--sampleMsg-->
</template>

<style scoped>
.anim{
	position: absolute;
	background-color: white;
	bottom: 254px;
	left: 0;
	right: 0;
	margin: auto;
	width: 355px;
	height: 190px;
	border: 2.5px solid #F49392;
	border-radius: 20px;
	transition: all 800ms;
}

.sampleMsg{
	position: relative;
}
.msgWrp{
	width: 355px;
	height: 222px;
	overflow: hidden;
	margin: auto;
}
.msg{
	position: relative;
	width: 355px;
	padding: 20px 40px;
	background-color: #FEF7FF;
	border: 2.5px solid #F49392;
	border-radius: 20px;
	margin: 20px auto;
	overflow: visible;
}
.pName{
	font-size: 18px;
	font-weight: 600;
}
pre{
	font-size: 16px;
	font-weight: 300;
	height: 82px;
	overflow: hidden;
}
pre span{
	font-size: 12px;
	font-weight: 300;
}
.nickname{
	text-align: right;
	font-size: 16px;
	font-weight: 600;
}

.triangle{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -26px;
	margin: auto;
  background: #F49392;
  height: calc(tan(60deg) * 30px / 2);
  width: 30px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.triangle::after{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 5px;
	margin: auto;
  background: #FEF7FF;
  height: calc(tan(60deg) * 25px / 2);
  width: 24px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.img{
	display: block;
	width: 293px;
	min-height: 200px;
	margin: 0 auto 40px auto;
}
</style>

<script>
export default {
	props: {
		d: {type: Object, default: () => { return {} }},
		i: {type: Number, default: -1},
	},

	data: function(){
		return {
			bottom: 90,
			width: 60,
			height: 40,
			opacity: 1,
			msgShow: false,
		}
	},

	mounted: function(){
	},

	methods: {
		animStart: function(){
			this.bottom  = 90
			this.width   = 60
			this.height  = 40
			this.opacity = 1
			this.msgShow = false

			setTimeout( () => {
				this.bottom  = 264
				this.width   = 355
				this.height  = 180
				setTimeout( () => {
					this.msgShow = true
					this.opacity = 0
				}, 950)
			}, 200)

		},

		loaded: function(){
			this.$emit('loaded', {i: this.i})
		},
	},


}
</script>
