import Chart from 'chart.js'

let chartOptions = {
	type: 'line',
	data: {
		labels: [],
		datasets: [{
			data: [],
			borderColor: "#1cb397",
			backgroundColor: "rgba(50,50,50,0)",
			lineTension:0,
			pointRadius:0,
			borderWidth:2,
		}]
	},
	options: {
		legend: false,
		animation: {
			duration: 0,
		},
		title: {
			display: false,
		},
		tooltips: {
			mode: 'index',
			intersect: false,
		},
		scales: {
			xAxes: [{
				gridLines:{
					display: false,
					lineWidth:0.5,
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: 4,
					fontSize: 10,
					maxRotation: 0,
					minRotation: 0,
				}
			}],
 			yAxes: [{
				gridLines: {
					drawBorder: false,
					lineWidth: 0.5,
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: 5,
					fontSize: 10,
					callback: function(value, index, values){
						return  value
 					}
				}
			}]
		},
	}
}


export default {
	props: {
	},


	data: function(){
		return {
			isReady: false,
			data: [],
			labels: [],
			myChart: {},
			term: '1y',
			values: {
				'1y': [],
				'6m': [],
				'1m': [],
			},
			label: {
				'1y': [],
				'6m': [],
				'1m': [],
			},
		}
	},

	methods: {
		setData: function(data, labels){
			this.data = data
			this.labels = labels
			this.prepareChart()
			this.createChart()
		},

		prepareChart: function(){
			this.isReay = false
			let chart_data = {
				data:   this.data,
				labels: this.labels,
			}
			//1年
			this.values['1y'] = chart_data.data
			this.label['1y'] = chart_data.labels
			//1ヶ月
			this.values['6m'] = chart_data.data.slice(-120)
			this.label['6m'] = chart_data.labels.slice(-120)
			//1週間
			this.values['1m'] = chart_data.data.slice(-20)
			this.label['1m'] = chart_data.labels.slice(-20)
			this.isReady = true
		},

		createChart: function(){
			chartOptions.data.labels = this.label[this.term]
			chartOptions.data.datasets[0].data = this.values[this.term]
			let ctx = this.$refs.myChart
			this.myChart = new Chart(ctx, chartOptions)
		},

		changeTerm: function(term){
			this.term = term
			this.myChart.data.labels = this.label[this.term]
			this.myChart.data.datasets[0].data = this.values[this.term]
			this.myChart.update()
		},
	},

}
