<template>
<div class="sort">
	<div class="select">
		<p>{{sorts[sort]}}<img :src="$cdn + '/img/main/icon_down_green.svg'"></p>
		<select v-model="sort" @change="cb(sort)">
			<option v-for="(l, i) in sorts" :value="i">{{l}}</option>
		</select>
	</div><!--select-->
</div><!--sort-->
</template>

<style scoped>
.sort{
	margin: 10px;
	text-align: right;
}
.sort .select{
	display: inline-block;
	vertical-align: middle;
	width: 140px;
	height: 32px;
	overflow: hidden;
}
.sort p{
	width: 100%;
	height: calc(100% - 2px);
	padding-right: 20px;
	line-height: 29px;
	border: 1px solid var(--col-main);
	text-align: center;
	border-radius: 5px;
	color: var(--col-main);
	font-size: 14px;
	font-weight: 400;
}
.sort p img{
	position: absolute;
	width: 24px;
	height: 24px;
	top: 2px;
	right: 2px;
}
.sort select{
	position: absolute;
	z-index: 10;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	line-height: 30px;
	font-size: 10px;
}
</style>

<script>
export default {
	props: {
		cb: {type: Function, default: (sort)=>{}},
	},

	data: function(){
		return {
			sort: 0,
			sorts: ['新着順', 'いいねが多い順'],
		}
	},

}
</script>
