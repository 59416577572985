<template>
<div class="wrapper">
	<img class="icon" :src="l.product.img_url">
	<p class="name">{{dispName(l.product.name)}}</p>


	<pre class="comment">{{l.comment}}</pre>
	<p class="date"><span>{{ $apc.cTheme[l.c_theme] }}</span>　{{dispDate(l.date)}}</p>

	<Favorite :favs="favs" :myMark="myMark" @fav="fav" />

	<div class="replies" v-show="l.reply1 != '' || l.reply2 != ''">
		<p><img :src="$cdn + '/img/main/return.svg'"><span>STOCKPOINTからのコメント</span></p>
		<pre>{{l.reply1}}</pre>
		<pre>{{l.reply2}}</pre>
	</div><!--reply-->
</div>
</template>

<style scoped>
.wrapper{
	border-bottom: 1px solid #E5E5E5;
	padding: 20px 10px;
}

.icon{
	position: absolute;
	top: 20px;
	left: 10px;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	object-fit: cover;
}
.name{
	margin-left: 40px;
	margin-right: 20px;
	float: left;
	font-size: 14px;
	font-weight: 600;
	line-height: 32px;
	color: var(--col-main);
}
.subMenu{
	position: absolute;
	top: 20px;
	right: 10px;
	width: 24px;
	height: 24px;
}
.subMenuList{
	position: absolute;
	top: 45px;
	right: 10px;
	width: 180px;
}
.gomi{
	width: 180px;
	height: 56px;
	border: 1px solid #999999;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	border-radius: 5px;
	background-color: var(--col-white);
	z-index: 10;
	text-align: center;
	line-height: 56px;
}
.gomi span{
	font-size: 14px;
	font-weight: 300;
}
.comment{
	clear: both;
	margin-top: 38px;
	font-size: 14px;
	font-weight :300;
	line-height: 22px;
}
.date{
	font-size: 10px;
	font-weight: 300;
	color: #999999;
	line-height: 16px;
	width: 200px;
}
.date span{
	font-size: 10px;
	font-weight: 300;
	color: var(--col-main);
	line-height: 14px;
}

.replies{
	width: 100%;
	padding: 16px 16px 6px 16px;
	background-color: #F8F8F8;
	margin-top: 10px;
}
.replies p img{
	width: 16px;
}
.replies p span{
	font-size: 14px;
	font-weight: 600;
	color: var(--col-main);
	vertical-align: middle;
	margin-left: 8px;
}
.replies pre{
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 10px;
}
</style>

<script>
import {dtfmt2} from '../../../lib/utils'
import Favorite from '../../components/Favorite3.vue'

export default {
	props: {
		l: { type: Object, default: ()=>{return {}} },
		delCb: { type: Function, default: (id)=>{} },
	},

	watch: {
		l: function(n, o){
			this.favs = n.favs
			this.myMark = n.my_mark
		},
	},

	data: function(){
		return {
			isOpenSubMenu: false,
			favs: 0,
			myMark: -1,
		}
	},

	mounted: function(){
		this.favs = this.l.favs
		this.myMark = this.l.my_mark
	},

	methods: {
		delComment: async function(){
			this.isOpenSubMenu = false
			//サーバから削除
			const res = await this.$post('/webapi/client/comment/delete', {id: this.l.id}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//コールバック
			this.delCb(this.l.id)
		},

		dispName: function(name){
			if(name.length <= 18) return name
			return name.substring(0, 18) + '...'
		},

		dispDate: function(dnum){
			return dtfmt2(dnum)
		},

		fav: async function(){
			let d = {
				id: this.l.id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/comment/fav', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			this.favs ++
			this.myMark = 0
		},

	},

	components: {
		Favorite,
	},
}
</script>
