<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	長期投資コラム
</div><!--head-->

<h1>第3回<br>躍進するスーパー<br>スイーツカンパニー<br>「寿スピリッツ（2222）」</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<pre>前回もお話ししましたが、長期投資の銘柄選びのキモは、</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

長期投資で成功する法則

業績の良い企業を選べば間違いなし

</pre>
	<hr class="down">
</div><!--point-->

<pre>でした。

今回取り上げる「寿スピリッツ」という会社。多分、多くの方は、知らない会社名かもしれません。
でもこの会社、<span class="red">今後の成長が期待できそうな「スゴイ会社」</span>なんです。

その理由をご紹介していきましょう。
</pre>

<h2>1. 寿スピリッツって、どんな会社？</h2>

<pre>寿スピリッツは、お菓子の製造小売りをする会社です。

<span class="red">プレミアム・ギフト</span>と呼ばれる<span class="red">「大切な人・特別な人への、ちょっと高級なお土産・手土産」の市場に特化し、独自商品を開発・製造し、自社で販売している</span>会社です。

出張とか旅行で各地に出かけた時など、各地域のターミナル駅、空港、高速のサービスエリアなどの交通の要所、観光地など人が集まるエリアに出店しているのが特徴です。

例えば、東京のスイーツ「東京ミルクチーズ工房」ブランド。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/img1.png'">

<pre>こちらは、北海道のスイーツ「LeTAO」ブランド。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/img2.png'">

<pre>こちらは、中国地方のスイーツ「白桃のチーズケーキ」。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/img3.png'">

<pre>このように、日本全国各地に地域子会社を設立し、その子会社がそれぞれの地域の特徴・文化などを取り入れた<span class="red">その地域ならではのスイーツを開発して製造販売している</span>のが特徴です。
</pre>


<h2>2. 今までの姿を見てみよう</h2>

<pre>まずは、長期投資の基本のキ！のデータ、
会社の業績を見てみましょう。

過去10年間の売上と当期利益の推移です。
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart1.png'">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart2.png'">
</div><!--grey-->


<pre>コロナ禍は売上、利益ともに落ち込みましたが、その後は<span class="red">事業は順調に成長しています</span>。

これに伴い、寿スピリッツの株価の推移は下図の通り。
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart3.png'">
</div><!--grey-->

<pre><span class="red">もし過去に10万円投資をしていたら、それが10年で280万円になっていた</span>、という話です。

将来に向けて成長する企業を、「早い段階から見つける」と、株式は大きなリターンを生むのです。
</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

長期でリターンを狙うには

なるべく「早い段階」から、ちゃんと
成長していて、これからも成長期待できる
企業を見つけること。

</pre>
	<hr class="down">
</div><!--point-->


<h2>3. 寿スピリッツが「強い！」所以は</h2>

<pre>現在も事業が順調であることは、データで確認できました。但し、</pre>

<div class="yellow" style="text-align: center;">
	<span class="red">寿スピリッツがスゴイのは実は……<br>
　圧倒的に高い「利益率」</span>
</div>

<pre>なのです。

つまり、同じ売上高の会社と比べても、儲ける金額が圧倒的に高い、ということ。

お菓子業界の中での寿スピリッツの立ち位置を見てみましょう。
下図の通り、売上高では約500億円で業界８位。
</pre>


<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart4.png'">
</div><!--grey-->


<pre>当期利益で比較したのが下図です。
<span class="red">寿スピリッツの利益は、売上が2倍ある不二家より大きく、売上が6倍ある江崎グリコに迫る金額です。</span>
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart5.png'">
</div><!--grey-->

<pre>その違いをもっと分かりやすくすると、下図の「当期利益率」を比較したものです。
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu3/chart6.png'">
</div><!--grey-->

<pre>見て明らかなとおり、</pre>

<div class="yellow" style="text-align: center;">
	<span class="red">「圧倒的に高い利益率」</span>
</div>

<pre>なのです。
このスゴイところが、まだまだこれから成長の可能性がある、と思える所以です。
</pre>

<h2>4. ここが肝！です。寿スピリッツの強さを支えるポイント</h2>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

寿スピリッツの強さのポイント

<p><span class="l">①</span>「プレミアムギフト」と言う
特別な市場を選択していること</p>
<p><span class="l">②</span>「製造小売り」という
ビジネスモデル</p>
<p><span class="l">③</span>実行力ある2代目社長！</p>
</pre>
	<hr class="down">
</div><!--point-->


<pre><span style="font-weight: bold;">【理由1】
プレミアム・ギフトという市場を選択して、そこで選ばれる構造を作っている</span>

競争優位性を作る上で大切なことなのですが、<span class="red">成長しやすい市場を選んでそこで勝負する</span>、という選択と集中は事業を考える上でとても大切です。
実際には多くの企業が「成長しない市場」「小さな市場にたくさんのライバルがいる環境」の中で事業を行っているのが現実です。そうした市場からいち早く脱出し、成長しやすい市場に移動した点が、同社が成長してきた大きな理由のひとつです。

そして、それが、「プレミアム・ギフト」という市場だったのです。

<span class="red">「プレミアム・ギフト」＝大切な人への贈り物</span>、なので信頼できる品質であることはもちろんですが<span class="red">高い価格を設定しやすいマーケット</span>になっています。
寿スピリッツは、このような成長しやすく、利益率を高めやすい市場で戦っているのが重要なポイントです。

<span style="font-weight: bold;">【理由2】
圧倒的な戦力を生み出す、製造小売りという戦い方</span>

<span class="red">「製造小売り」とは、メーカーとして自ら商品を企画・開発し、それを製造（生産）。そして、（問屋に卸すのではなく）自社で直接販売する</span>というやり方です。
要は、すべてを一気通貫で行うビジネスモデルです。

このやり方は、業務負荷が大きくなり、また難易度も上がるのですが、それを完成させることができれば圧倒的な戦闘能力を持つことになります。
 
特に、以下の3つが大きいと考えます。

<span style="font-weight: bold;">【A】</span><span class="red">お客様の嗜好や（嗜好の）変化</span>を敏感に感じ取り、<span class="red">商品の開発や改善を有利に進める</span>ことができる。

<span style="font-weight: bold;">【B】</span><span class="red">お客様とダイレクトに接点</span>を持つことで、自社のブランドづくりやファンづくりに非常に効果的です。

<span style="font-weight: bold;">【C】</span><span class="red">問屋や小売店を介していないので、高い利益率</span>が実現できること。

実は同社は、もともとは「問屋に卸す」というビジネスモデルでした。<span class="red">それを少しずつ「直販」に切り替え、現在の製造小売りというやり方に転換してきました</span>（それでも、売上げの34％は卸売りが残っていますが）。
 
同社の利益率が向上している大きな原因はこの転換を実行したことなのです。

<span style="font-weight: bold;">【理由3】
実行力ある、2代目社長！</span>

これらの<span class="red">ビジネスにおける選択を、構想し、決断し、そして実行した現在の社長（2代目）はスゴイ</span>と思います。
そういう意味では、寿スピリッツの成長の原動力は、この社長さんがキーマンなのかもしれません。

そしてきっと、これからも新しい可能性を見つけ、さらなる成長へと同社を牽引してくれるのではないかと考えます。
</pre>

<h2>5. これからの成長について<br>期待を込めて考察してみましょう</h2>

<pre>まず、同社の5年後の売上目標は、1,000億円
つまり、今の売上の2倍になる、という計画です。
この実現性については、今までの同社の強みを考えあわせると、かなり期待できると思います。

そうなると、株価は……

<span class="red">控えめに見積もって、5年後の目標株価は、約2倍</span>を考えてもいいのではないでしょうか。
プレミアムギフト市場での優位性がさらに強固になり、例えば海外市場への進出なども話題になれば、もっと株価にはプラスになる可能性があります。さらに、<span class="red">増配や自社株買いなども発表されればさらにプラス</span>になります。
</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

今後の業績から目を離せない！

※STOCKPOINTでも近々、この銘柄を
組み込んだ新しいポイント運用商品を
リリース予定です。乞うご期待ください！

</pre>
	<hr class="down">
</div><!--point-->


<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre style="display: inline-block;text-align: center; padding-left: 2em;">銘柄投資の醍醐味
VS
ラクラク投信</pre>
	</div><!--next-->
</div><!--nextWrp-->


<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #288924;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #288924;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
.red{
	color: #EF5C5C;
	font-weight: 600;
}
span.uline{
	background: linear-gradient(transparent 60%, #FDAA85 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #E9F5E9;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
	>hr{
		position: absolute;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #1A1A1A;
		margin: 0;
		&.up{
			top: 5px;
		}
		&.down{
			bottom: 5px;
		}
	}
	>img{
		height: 30px;
		position: absolute;
		top: -15px;
		left: -2px;
	}
	>pre{
		font-weight: 600;
		>p{
			font-weight: inherit;
			width: 240px;
			margin-left: 70px;
			text-align: left;
			.l{
				margin-left: -20px;
			}
		}
	}
}

.grey{
	background-color: #F4F4F4;
	margin: 20px 0;
	padding: 20px 0;
}
.yellow{
	background-color: #FFF3C3;
	border-radius: 5px;
	padding: 20px 0;
	width: 335px;
	margin: 20px auto;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}


.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 9,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
