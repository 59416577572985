<template>
<div class="wrapper1">

	<div class="main">
		<p class="nType">{{l.n_type}}</p>
		<pre class="contents" v-html="l.contents"></pre>
	</div><!--main-->

	<div class="favs">
		<p class="dt">{{dtFmt()}}</p>
		<Favorite :favs="favs" :myMark="myMark" @fav="fav" />
		<img class="replyBtn" :src="$cdn + '/img/main/btn_reply.png'" @click.stop="togglePostComment">
	</div><!--favs-->

	<ReplyPostComment :l="l" placeHolder="返信はこちら（最大500字）" @post="replyDone" ref="replyPostComment" />

	<div class="replies">
		<ul>
			<li v-for="(d, i) in replyList" v-show="replyDisp || i < 2">
				<Reply :d="d" @del="replyDelCb" @fav="favCb" />
			</li>
		</ul>
	</div><!--replies-->

	<a class="moreReply" @click="replyDisp = true" v-show="replyList && replyList.length > 2 && ! replyDisp">返信をさらに表示<img :src="$cdn + '/img/main/arrow_down_green.svg'"></a>

</div>
</template>

<style scoped>
.wrapper1{
	width: calc(100% - 20px);
	text-align: center;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	margin: 20px auto;
	padding: 0 0 10px 0;
}
.main{
	overflow: hidden;
	padding-bottom: 10px;
}
.nType{
	display: block;
	white-space: nowrap;
	text-align: left;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: white;
	background-color: var(--col-main);
	padding: 5px;
}
.dt{
	float: left;
	font-size: 10px;
	line-height: 20px;
	opacity: 0.6;
	margin-left: 10px;
}
pre.contents{
	margin: 10px 10px 0 10px;
	clear: both;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	white-space: pre-wrap;
}
pre.contents>>>a{
	display: inline;
	color: blue;
	background-color: transparent;
	text-decoration: underline;
	margin: 0;
	padding: 0;
	border: none;
	font-weight: normal;
	height: auto;
	line-height: 20px;
}

.replies li{
	width: auto;
	padding: 20px 0;
	margin-left: 10px;
}

.postCommentBtn{
	width: 120px;
	border-radius: 24px;
}
.moreReply{
	background-color: transparent;
	color: var(--col-main);
	border: none;
	padding: 0;
	margin: 0 auto;
	height: auto;
	line-height: 30px;
	font-size: 14px;
	>img{
		margin-left: 5px;
		height: 6px;
	}
}

.favs{
	position: relative;
	.replyBtn{
		position: absolute;
		height: 22px;
		right: 100px;
		top: 5px;
		cursor: pointer;
	}
}
</style>

<script>
import Favorite from '../Favorite3.vue'
import Reply from './Reply.vue'
import ReplyPostComment from '../PostComment3.vue'

export default {
	props: {
		l: { type: Object, default: () => { return {} } },
		replies: { type: Object, default: () => { return {} } },
	},

	data: function(){
		return {
			replyDisp: false,
			favs: 0,
			myMark: -1,
			replyList: [],
		}
	},

	mounted: async function(){
		this.$refs.replyPostComment.isDone = true
		this.favs = this.l.favs
		this.myMark = this.l.my_mark
		setTimeout( () => {
			this.replyList = this.replies[this.l.id]
		}, 100)
	},

	methods: {
		dtFmt: function(){
			let dt = new Date(this.l.created_at * 1000)
			return dt.getFullYear() + '/' + (dt.getMonth() + 1) + '/' + dt.getDate()
		},

		replyDone: async function(params){
			let d = {
				id:       this.l.id,
				nickname: params.nickname,
				comment:  params.comment,
				c_theme:  params.c_theme,
			}
			const res = await this.$post('/webapi/client/reply_company_news/create', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			//クライアント側に追加
			if(this.replyList == null) this.replyList = []
			this.replyList.unshift(res.reply)
			//終了処理
			this.$refs.replyPostComment.close()
		},

		replyDelCb: function(params){
			for(let i=0, l=this.replyList.length; i<l; i++){
				if(this.replyList[i].reply_news_id == params.reply_news_id){
					this.replyList.splice(i, 1)
					break
				}
			}
		},

		fav: async function(){
			let d = {
				id: this.l.id,
				mark: 0,
			}
			const res = await this.$post('/webapi/client/company_news/fav', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.favCb()
		},

		favCb: function(){
			this.favs ++
			this.myMark = 0
		},

		togglePostComment: function(){
			this.$refs.replyPostComment.isDone = !  this.$refs.replyPostComment.isDone
		},

	},

	components: {
		Favorite,
		Reply,
		ReplyPostComment,
	},
}
</script>
