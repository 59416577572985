let data = [
        [157529,"u2378","red","サウナが気持ちいい","毎日泳ぎに行ってます。サウナが気持ちいいです。","ねるねるねるね","ルネサンス"],
        [156827,"u2590","orange","カイロ代わりに","新聞配達でお世話になってましたよ。カイロ代わりにコーヒー使ってました。","明後日、いつものとこで、","ダイドーグループ"],
        [157047,"u2590","blue","ポイントカード今も使えるのかな？","昔ダイドーの自販機でポイントカードみたいなの使ってたなー。年末の大掃除で学生時代の引き出しにしまってあるの見つけたけど今も使えるのかな？","さかな","ダイドーグループ"],
        [157750,"u2593","red","ほっと…する瞬間","寒い季節に、温かいラテ！ほっと…する瞬間です。なかなか、置いてあるお店が多くないので、もっと増やしたいですね。期待してます。","たら","伊藤園"],
        [157444,"u2651","orange","何度食べても飽きない","ローソン行ったら必ずスウィーツ買います。ロールケーキ、カヌレ、チーズケーキなどなど、どれも美味しいし何度食べても飽きないです。","コネコ","ローソン"],
        [157032,"u2503","blue","なんだか効いてきた","「おいしい免疫ケア」今日も頂きました。プラズマ乳酸菌なんだか効いて見たいです！","ハッピードラゴン","キリンホールディングス"],
        [156998,"u2805","red","お料理の味方","濃いシチュー好きです😊マジックソルトにも助けられてますお料理の味方ありがとうございます。","ぼん","ヱスビー食品"],
        [157028,"u4977","orange","知らず知らずに","知らず知らずに摂取しているのですね。これからも体に良いものをお願い致します。","テル","新田ゼラチン"],
        [157039,"u1928","blue","信頼ある会社","積水ハウスの5本の樹計画いいですね。生物多様性への取り組みも地道に続けていて、信頼おける会社だなと思います","BONSAI","積水ハウス"],
        [157694,"u5332","red","だから応援しちゃう","TOTO工場内の設備されてるトイレ。耐久性の調査とかあるかもだけれどすごい機種とか置いてある。社内で地道な努力を見てないところでこなしている優良企業。だから応援しちゃうんですよ。","キートン","ＴＯＴＯ"],
        [157024,"u7751","orange","10年ぶりに買い替え","年賀状作成時にプリンター買い替えました。約10ぶりの買い換えで性能差に驚き。綺麗に印刷できました。","くあま","キヤノン"],
        [157512,"u4478","blue","確定申告準備が簡単に","ユーザーですが、確定申告準備が簡単に終わり、とても感謝しています！使っててよかった！","トースト","Freee"],
        [157651,"u7974","red","子供と手軽に遊べる","子供と一緒に太鼓の達人を楽しませて貰ってます。手軽に遊べて有難いです。","Shin","任天堂"],
        [157558,"u7974","orange","久しぶりにゲームのわくわくを","年齢を重ね体力や集中力が無くなっていましたが、Switchを買い、凄く久しぶりにゲームのわくわくを感じる事が出来ました。1人でも、誰かとでも、年齢や不得手を問わず沢山の人が楽しめるというのは、本当に凄い事だと思います。感謝と共にこれからも応援しています。","アイス","任天堂"],
        [157543,"u4755","blue","ポイントで寄付できる","ポイントで寄付できるなんて、毎日ログインしてなかったから気付きませんでした。昨日、気付き次第寄付して来ました。ポイントで寄付できる様にしてくれてありがとうございます。","彼方","楽天グループ"],
        [157232,"u7564","red","高嶺の花だった","10年前くらいには高嶺の花だったアウトドア用品がお手頃価格で揃えられてありがたいです。応援してます。","13oz","ワークマン"],
        [157644,"u7309","orange","釣りも自転車も好き","釣りも自転車も好きで、色々なところだお世話になっています。これからもよろしくおねがいします。","ブラックバス","シマノ"],
        [157002,"u5108","blue","タイヤはブリヂストン","タイヤはブリヂストンを使ってます。次もブリヂストンの予定です。頑張って下さい。","芳輝煌","ブリヂストン"],
        [157484,"u5019","red","好んでピットイン","いつも愛車のお腹を満たして頂き、好んでピットインしています。これからも頼って応援していきます。","jahn.jahn","出光興産"],
        [157099,"u9020","orange","E259系が好きです","成田エクスプレス（E259系）がとてもかっこよくて好きです","そうま","ＪＲ東日本"],
        [156918,"u9201","blue","新社長おめでとうございます","新社長、CA出身の鳥取専務が昇格、おめでとうございます。","ＱＮＸ","ＪＡＬ"],
        [157445,"u9202","red","伸びてほしい！","是非インバウンド需要を取り込んで伸びてほしい！","jeke","ＡＮＡＨＤ"],
        [157504,"u7630","orange","もっと店舗を増やして","カレーももちろん好きですが、あんかけパスタのパスタでココも好きなので、もっと店舗を増やして欲しいです。","ルパン","壱番屋"],
        [157201,"u7630","blue","お酒を飲んだ後に、シメのカレー","地元のカレー専門店が、全国に広まった。お酒を飲んだ後に、シメのカレー。当然辛さは、１０辛。翌日は、あまり酔いは残りません。一度試してみてください。最高ですよ。","向日葵","壱番屋"],
        [157325,"u2705","red","迷います","どの定食も美味しくていつも迷います。","のと","大戸屋ホールディングス"],
        [157100,"u4665","orange","優待が使える","ミスドで優待が使えるのが嬉しいです😆","シラタキ","ダスキン"],
        [157401,"u9873","blue","家では再現不可能","家ではまず再現不可能で、周期的に行きたくなります。応援しています。","NKJM2000","日本ＫＦＣホールディングス"],
        [156949,"u2702","red","サムライマックは欠かさず","いつも子供と一緒に週末はマックに行くのが楽しみです！個人的にはサムライマックは欠かさず食べています！ゴジラバーガーもなかなかの美味さでしたね！値上げしてもまだまだ通いますのでこれからのラインナップの発展に期待してます！","なり","日本マクドナルドホールディングス"],
        [157369,"u9602","orange","60年以上ゴジラファン","60年以上前からのゴジラファンです。この歳になっても毎回ワクワクしながら映画館に通っています。マイナスワンの次回作を期待しています。","はんちゃん","東宝"],
        [157049,"u9602","blue","映画鑑賞を楽しんでいます","地元からすぐのところにあるので助かっています。ゆったりした椅子で落ち着いて映画鑑賞を楽しんでいます。","グゥパァーオリャース","東宝"],
        ]
        export const list = data.map( (d) => {
                return {
                        id:       d[0],
                        c_code:   d[1],
                        color:    d[2],
                        title:    d[3],
                        comment:  d[4],
                        nickname: d[5],
                        c_name:   d[6],
                        marks:    [],
                        favs:     0,
                        my_mark:  -1,
                }
        })