import { render, staticRenderFns } from "./app.vue?vue&type=template&id=5e772d34"
import script from "./app.vue?vue&type=script&lang=js"
export * from "./app.vue?vue&type=script&lang=js"
import style0 from "../style_zen_maru_gothic.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../style.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports