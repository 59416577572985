import axios from 'axios'
import { get_products } from '../../../lib/utils'
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import CustomSlider from '../../components/CustomSlider.vue'
import CompanyNewsItem from './CompanyNewsItem.vue'

import SampleMsg from './SampleMsg.vue'
import Rensai from '../../components/Rensai.vue'
import Banner from './Banner.vue'

export default {
	data: function(){
		return {
			isReady: false,
			products: {}, //銘柄マスタ
			companyNews: [], //注目の最新ニュース
			sampleMsgs: [], //メッセージ
			sampleActive: 0,
			rensaiList: [],
			rensaiMore: false,
			intval: -1,
			adsense: false,
		}
	},

	mounted: async function(){
		clearInterval(this.intval)
		//銘柄マスタ（sessionStorageに入れる）
		const prdObj = await get_products(false)
		//ハッシュ側を登録する
		this.products = prdObj.obj

		//メッセージ取得
		this.getSampleMsgs()

		//連載取得
		this.getRensaiList()

		await this.getCompanyNews()

		//CustomSlider起動
    if (this.companyNews.length > 0) {
      this.$refs.sliderCompanyNews.startSlider()
    }

		//adsense
		if(location.hostname == 'aisarekigyo.stockpoint.jp') this.adsense = true

		this.isReady = true
	},

	destroyed: function() {
		clearInterval(this.intval)
  },

	methods: {
		linkTo: function(name){
			this.$router.push({name: name})
		},

		getCompanyNews: async function(){
			//注目の最新ニュース
			const res = await this.$post('/webapi/client/company_news/list', {}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			for(let i=0, l=res.list.length; i<l; i++){
				const c = res.list[i]
				c.product = this.products[c.c_code]
				if(c.product == null) c.product = {c_code: c.c_code, name: '', img_url: this.$cdn + '/img/main/noimage.svg', market: ''}
				this.companyNews.push(c)
			}
		},

		getSampleMsgs: async function(){
			const res = await this.$post('/webapi/client/top_comment/list', {}, true)
			if(res.code != 0){
				return false
			}
			this.sampleMsgs = res.list
		},

		startAnim: function(params){
			if(params.i == 0){
				setTimeout( () => {
					this.$refs.sampleMsg[0].animStart()
				}, 0)

				this.intval = setInterval( this.sampleStart, 5000 )
			}
		},

		sampleStart: function(){
			this.sampleActive ++
			if(this.sampleMsgs.length <= this.sampleActive) this.sampleActive = 0
			this.$refs.sampleMsg[this.sampleActive].animStart()
		},


		getRensaiList: async function(){
			axios({
				url: '/static/index/rensai.json',
				responseType: 'json',
			}).then( (res) => {
				let list = []
				if(location.hostname == 'aisarekigyo.stockpoint.jp'){
					const now = new Date().getTime()
					list = res.data.filter( (r) => {
						const dtf = new Date(r.dt).getTime()
						const dte = new Date(r.dtEnd).getTime()
						return (dtf <= now && now < dte)
					})
				}else{
					list = res.data
				}
				//10個に限定
				list = list.splice(0, 10)
				this.rensaiList = list
			}).catch( (e) => {
				console.error(e)
			})
		},


		nextNews: function(){
			this.$refs.sliderCompanyNews.dif = -100
			this.$refs.sliderCompanyNews.tend()
		},

		prevNews: function(){
			this.$refs.sliderCompanyNews.dif = 100
			this.$refs.sliderCompanyNews.tend()
		},

	},

	components: {
		Header,
		Footer,
		CustomSlider,
		CompanyNewsItem,
		SampleMsg,
		Rensai,
		Banner,
	},

}
