import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			// 指定した時間にリリース用
			targetDate: new Date('2024-03-15T10:00:00'),
			isTargetDateReached: false, // 時間になるとtrueになる
			timer: null,
		}
	},

	created() {
		// 指定した時間にリリース用
		this.checkTargetDate();
	},

	mounted: async function(){
		this.isReady = true
	},

	methods: {
		// 指定した時間にリリース用
		checkTargetDate() {
			const now = new Date();
	  
			if (now >= this.targetDate) {
			  // ターゲット日付がすでに過ぎている場合
			  this.isTargetDateReached = true;
			} else {
			  // ターゲット日付がまだ到来していない場合、指定された日付になるまで待機
			  const timeToWait = this.targetDate - now;
			  this.timer = setTimeout(() => {
				this.isTargetDateReached = true;
			  }, timeToWait);
			}
		  },
	},

	beforeDestroy() {
		  // コンポーネントが破棄されるときにタイマーをクリア
		  if (this.timer) {
			clearTimeout(this.timer);
		  }
	},

	components: {
		Header,
		TopCap,
	},

}
