<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #05
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg5/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg5/MV.png'">

		<h1>「E」は現実を見据えつつも、<br>投資判断には重要な要素である</h1>
		<p class="dt">2023/12/6</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">


<pre>米国を中心にＥＳＧ（環境・社会・企業統治）投資へ激しい逆風が吹きつつあることは、当コラムですでに書いた。<span class="uline">特にＥについては</span>ロシアのウクライナ侵攻をきっかけに、アンチのうねりが特に強い。これまで威勢良く脱炭素の看板をかかげてきた企業も、<span class="uline">現実を見すえてゆっくりと軌道修正しているようだ。</span>
それを痛感したのは今年９月。休暇と取材をかねてロンドンに滞在した時のことだ。地下鉄の駅構内でこんな意見広告を見た。石油大手ＢＰ（旧ブリティッシュ・ペトロリアム）のものである。

＝＝＝＝
<span style="font-weight: bold;">And, not or.

Increasing investment in the transition to lower carbon energy and keeping oil and gas flowing where it’s needed.

「どちらも」であって、「どちらか」ではない。</span>

低炭素エネルギーへの移行に向けた投資を増やす一方、必要な場所には石油とガスを供給する。（拙訳）
＝＝＝＝

温暖化ガスの排出を実質的になくすネットゼロ社会の実現に向けた内容だ。かつてはＢＰの社名にビヨンド・ペトロリアム（Beyond Petroleum 石油を超えて）の意が込められていた。しかし、ＢＰは足元のエネルギー価格高騰の現実に直面し、２０２２年通期決算で石油生産の削減計画の縮小を表明し、環境問題を重視する運動家や投資家の失望を誘った。

<span class="uline">And, not or のメッセージには性急な二者択一を排し、石油・ガスを使い続けながら脱炭素の道を探る</span>というＢＰの現実路線が色濃くにじんでいた。

英国は第２６回国連気候変動枠組み条約締約国会議（COP26）の議長国を務めたということもあり、世界の脱炭素のフロントランナー、リーダーと自他共に認める存在だった。しかし、その自負も９月２０日のスナク首相による脱ガソリン車の期限延期表明で、ひっくりかえってしまった。
首相の演説のなかで筆者が注目したのは、次の簡潔な一節だ。

＝＝＝＝
<span style="font-weight: bold;">Pragmatism, not ideology</span>

<span style="font-weight: bold; font-style: italic;">現実主義であって、政治思想ではない。</span>
＝＝＝＝


現実主義に徹すれば高すぎる脱炭素の目標は国民を疲弊させるだけという、政治嗅覚にもとづく発言といえる。ＢＰの「『どちらも』であって、『どちらか』ではない」に相通ずる抜け目のなさではないか。

<span class="uline">Ｅを巡る風向きの変化は、投資の世界にも広がっている。</span>ロシアによるウクライナへの侵攻後、株価が良好に推移した石油・ガス企業からダイベストメント（投資撤退）する機運は急速にしぼんだ。かわって見直されているのが、温暖化ガスを多く排出する企業をクリーン化するトランジション（移行）や、それを後押しするエンゲージメント（働きかけ）だ。

もともとダイベストメントは指摘されるほど広範に実施されているわけではなかった。環境意識の低い企業の株式をＥＳＧ投資家が売却したところで、その買い手が環境問題に関心を示さなければ、市場を通じた監視機能はむしろ下がってしまうからだ。筆者に言わせれば、ダイベストメントはよく言えば投資家と企業との交渉材料、悪く言えば、こけおどしだった。

<span class="uline">プロの機関投資家の変わり身も早い。</span>

７月には、脱炭素の旗振り役だった米ブラックロックが石油会社サウジアラムコの経営者を取締役に迎え、市場を驚かせた。この人事のメッセージの背景にある考え方もＡＮＤ、すなわち「クリーンエネも石油も」ということだと思う。

日本生命保険は８月、「ESG投融資の高度化」を発表し、施策のひとつとして「エンゲージメントの強化」を掲げた。国内外の投資家の動きからして、「クリーンエネも石油も」のＡＮＤの時代にあっても、<span class="uline">企業に脱炭素を促す市場の力が弱まることはなさそうだ。むしろ、企業の取り組みの進捗に関する投資家のチェックは強まることが予想される。</span>最終的には株主総会での取締役選任の議決に影響してくるかもしれない。

台風や竜巻、洪水などの自然の猛威は企業活動にも影響を及ぼす。２０２３年の異常気象はそのことを改めて私たちに痛感させた。そうなると、株主が企業の環境対策に目を光らせるのは当然のリスク管理。そこからさらに踏み込み、脱炭素への移行を働きかける投資家も増えることだろう。

<span class="uline">ＥＳＧという言葉が残るかどうかは別にして、環境問題は投資判断の大きな要素だ。</span></pre>



	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<img :src="cdn + '/img/rensai/esg/esg5/next.png'">
		<p class="title">「ESG投資」は批判に耳を傾け、<br>粘り強く分析し続ける意義がある</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg5/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	min-height: 60px;
	background-color: #D7E9EB;
	margin: 20px auto;
}
.next img{
	position: absolute;
	width: 46px;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}
.next .title{
	margin-left: 70px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 600;
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 10,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
