import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index/Index.vue'
import Ranking from '../views/Ranking/Ranking.vue'
import SearchIndex from '../views/SearchIndex/SearchIndex.vue'
import SearchIndexSdgs from '../views/SearchIndexSdgs/SearchIndexSdgs.vue'
import SearchIndexComment from '../views/SearchIndexComment/SearchIndexComment.vue'
import SearchResult from '../views/SearchResult/SearchResult.vue'
import SearchList from '../views/SearchList/SearchList.vue'
import SearchSdgsList from '../views/SearchSdgsList/SearchSdgsList.vue'
import SearchDetail from '../views/SearchDetail/SearchDetail.vue'
import SearchComment from '../views/SearchComment/SearchComment.vue'
import MyPage from '../views/MyPage/MyPage.vue'
import OtherPage from '../views/OtherPage/OtherPage.vue'
import Event from '../views/Event/Event.vue'
import Information from '../views/Information/Information.vue'
import Terms from '../views/Terms/Terms.vue'
import AppSearchDetail from '../views/AppSearchDetail/AppSearchDetail.vue'
import AppSearchComment from '../views/AppSearchComment/AppSearchComment.vue'
import Interview from '../views/Interview/Interview.vue'
import InterviewList from '../views/InterviewList/InterviewList.vue'
import ColumnList from '../views/ColumnList/ColumnList.vue'
import Rensai from '../views/Rensai/Rensai.vue'
import RensaiNobuToYasu from '../views/RensaiNobuToYasu/RensaiNobuToYasu.vue'
import RensaiEsg from '../views/RensaiEsg/RensaiEsg.vue'
import RensaiDiary from '../views/RensaiDiary/RensaiDiary.vue'
import RensaiToribia from '../views/RensaiToribia/RensaiToribia.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
    name: 'Index',
    component: Index,
		meta: { gtm: 'Index' },
	},
	{
		path: '/index',
    name: 'Index2',
    component: Index,
		meta: { gtm: 'Index2' },
	},
	{
		path: '/ranking',
    name: 'Ranking',
    component: Ranking,
		meta: { gtm: 'Ranking', title: 'ランキング', desc: '愛され企業さーちにおける各種ランキングをご紹介します。' },
	},
	{
		path: '/search/index',
    name: 'SearchIndex',
    component: SearchIndex,
		meta: { gtm: 'SearchIndex', title: '企業を探す' },
	},
	{
		path: '/search/index/sdgs',
    name: 'SearchIndexSdgs',
    component: SearchIndexSdgs,
		meta: { gtm: 'SearchIndexSdgs', title: '企業を探す' },
	},
	{
		path: '/search/index/comment',
    name: 'SearchIndexComment',
    component: SearchIndexComment,
		meta: { gtm: 'SearchIndexComment', title: '企業を探す' },
	},
	{
		path: '/search/result',
    name: 'SearchResult',
    component: SearchResult,
		meta: { gtm: 'SearchResult', title: '企業を探す' },
	},
	{
		path: '/search/list/:theme',
    name: 'SearchList',
    component: SearchList,
		meta: { gtm: 'SearchList' },
	},
	{
		path: '/search/sdgslist/:id',
    name: 'SearchSdgsList',
    component: SearchSdgsList,
		meta: { gtm: 'SearchSdgsList' },
	},
	{
		path: '/search/detail/:c_code',
    name: 'SearchDetail',
    component: SearchDetail,
		meta: { gtm: 'SearchDetail' },
	},
	{
		path: '/search/comment/:c_code',
    name: 'SearchComment',
    component: SearchComment,
		meta: { gtm: 'SearchComment' },
	},
	{
		path: '/mypage',
    name: 'MyPage',
    component: MyPage,
		meta: { gtm: 'MyPage', title: 'マイページ' },
	},
	{
		path: '/other/:enc_aisare_id',
    name: 'OtherPage',
    component: OtherPage,
		meta: { gtm: 'OtherPage', title: 'マイページ' },
	},
	{
		path: '/event/:event_id',
		name: 'Event',
		component: Event,
		meta: { gtm: 'Event', title: 'イベント', desc: 'STOCKPOINTの各種サービスで実施中のキャンペーンをご紹介します。' },
	},
	{
		path: '/information',
    name: 'Information',
    component: Information,
		meta: { gtm: 'Information', title: '運営からのお知らせ' },
	},
	{
		path: '/terms',
    name: 'Terms',
    component: Terms,
		meta: { gtm: 'Terms', title: '利用規約' },
	},
	{
		path: '/app/detail/:c_code',
    name: 'AppSearchDetail',
    component: AppSearchDetail,
		meta: { gtm: 'AppSearchDetail' },
	},
	{
		path: '/app/comment/:c_code',
    name: 'AppSearchComment',
    component: AppSearchComment,
		meta: { gtm: 'AppSearchComment' },
	},
	{
		path: '/interview_list',
    name: 'InterviewList',
    component: InterviewList,
		meta: { gtm: 'InterviewList', title: 'SDGsインタビュー', desc: '企業様の素晴らしいSDGsの取組みをご紹介するSDGsインタビュー' },
	},
	{
		path: '/interview/:id/:page',
    name: 'Interview',
    component: Interview,
		meta: { gtm: 'Interview', title: 'SDGsインタビュー', desc: '企業様の素晴らしいSDGsの取組みをご紹介するSDGsインタビュー' },
	},
	{
		path: '/column_list',
    name: 'ColumnList',
    component: ColumnList,
		meta: { gtm: 'ColumnList', title: 'コラム記事一覧' },
	},
	{
		path: '/rensai',
    name: 'Rensai',
    component: Rensai,
		meta: { gtm: 'Rensai', title: '連載' },
	},
	{
		path: '/rensai/nobu_to_yasu',
    name: 'RensaiNobuToYasu',
    component: RensaiNobuToYasu,
		meta: { gtm: 'RensaiNobuToYasu', title: 'ノブとヤス' },
	},
	{
		path: '/rensai/nobu_to_yasu/:type/:id',
    name: 'RensaiNobuToYasuItem',
    component: RensaiNobuToYasu,
		meta: { gtm: 'RensaiNobuToYasuItem', title: 'ノブとヤス' },
	},
	{
		path: '/rensai/esg',
    name: 'RensaiEsg',
    component: RensaiEsg,
		meta: { gtm: 'RensaiEsg', title: 'ESG' },
	},
	{
		path: '/rensai/esg/:id',
    name: 'RensaiEsgItem',
    component: RensaiEsg,
		meta: { gtm: 'RensaiEsgItem', title: 'ESG' },
	},
	{
		path: '/rensai/diary',
    name: 'RensaiDiary',
    component: RensaiDiary,
		meta: { gtm: 'RensaiDiary', title: 'Diary' },
	},
	{
		path: '/rensai/diary/:id',
    name: 'RensaiDiaryItem',
    component: RensaiDiary,
		meta: { gtm: 'RensaiDiaryItem', title: 'Diary' },
	},
	{
		path: '/rensai/toribia',
    name: 'RensaiToribia',
    component: RensaiToribia,
		meta: { gtm: 'RensaiToribia', title: 'トリビア' },
	},
	{
		path: '/rensai/toribia/:id',
    name: 'RensaiToribiaItem',
    component: RensaiToribia,
		meta: { gtm: 'RensaiToribiaItem', title: 'トリビア' },
	},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
	scrollBehavior () {
		return {x: 0, y: 0}
	},
})

export default router

