import { comma } from '../../../lib/utils'
import axios from 'axios'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import Item from './Item.vue'


export default {
	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			list: [],
		}
	},

	mounted: async function(){
		this.getList()
		this.isReady = true
	},

	methods: {
		getList: async function(){
			axios({
				url: '/static/interview_list/list.json',
				responseType: 'json',
			}).then( (res) => {
				const now = new Date().getTime()
				const hn = location.hostname
				res.data.list.forEach( (d) => {
					const dt = new Date(d.dt)
					if(hn === 'aisarekigyo.stockpoint.jp' && dt.getTime() > now) return
					this.list.push(d)
				})
			}).catch( (e) => {
				console.error(e)
			})
		},

	},

	components: {
		Header,
		TopCap,
		Item,
	},

}
