import { comma, get_products } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import PostComment from '../../components/PostComment3.vue'
import Comment from '../../components/Comment/Comment.vue'
import SortFilter from '../../components/SortFilter.vue'

export default {
	data: function(){
		return {
			isReady: false,
			commentList:[], //コメントリスト
			product: {
				name: '',
			},
			page: 0,
			isMore: true,
			replies: {},
		}
	},

	mounted: async function(){
		//銘柄データ取得
		let prdObj = await get_products(false)
		this.product = prdObj.obj[this.$route.params.c_code]

		this.isReady = true

		//comments
		await this.getComments()
	},

	methods: {
		backToDetail: function(){
			this.$router.push({name: 'AppSearchDetail', params:{c_code: this.$route.params.c_code}})
		},

		delCb: function(id){
			//クライアントデータを削除
			for(let i=0, l=this.commentList.length; i<l; i++){
				let m = this.commentList[i]
				if(m.id == id){
					this.commentList.splice(i, 1)
					break
				}
			}
		},

		postDone: async function(params){
			let d = {
				c_code:   this.product.c_code,
				nickname: params.nickname,
				comment:  params.comment,
				c_theme:  params.c_theme,
			}
			const res = await this.$post('/webapi/client/comment/create', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()

			//投稿コメントをリストに追加
			this.commentList.unshift(res.comment)
		},

		comma: function(num){
			return comma(num)
		},

		getComments: async function(){
			let sf = this.$refs.sortFilter
			let d = {
				c_code: this.$route.params.c_code,
				sort:   sf.sort,
				page:   this.page,
				all:    true, //全件取得
			}
			if(sf.cTheme != -1) d.c_theme = sf.cTheme
			let res = await this.$post('/webapi/client/comment/search_detail', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}

			//10件未満になったらもっとみるを削除
			this.isMore = (res.list.length >= 10)

			//最初のページの場合はクリアする
			if(this.page == 0) this.commentList = []

			for(let i=0, l=res.list.length; i<l; i++){
				this.commentList.push(res.list[i])
			}

			//返信コメント
			const ids = this.commentList.map( (c) => c.id )
			res = await this.$post('/webapi/client/reply/list', {comments_id: ids}, true)
			if(res.code != 0){
				return false
			}
			this.replies = res.replies
		},

		changeSort: async function(){
			this.page = 0
			await this.getComments()
		},

		moreComments: async function(){
			this.page++
			await this.getComments()
		},

	},

	components: {
		Header,
		PostComment,
		Comment,
		SortFilter,
	},

}
