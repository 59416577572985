let data = [
[140491,"u9104","orange","こんなに君を。。。","安くなったら買おうとおもってるんだけど、全然安くならないね。こんなに君を愛してるのに。","FJ#","商船三井"],
[140954,"u9104","blue","嬉しい限り","期待してなかった値上がりもあり、嬉しい限り","たら","商船三井"],
[139131,"3313188","red","次の世代は宇宙へ","生きてる間に宇宙へ行けるかわからないけど、次の世代で行先の選択肢が増えますように。","赤石太郎","ｅＭＡＸＩＳ Ｎｅｏ 宇宙開発"],
[139988,"3313188","orange","生きてる間いつかは！","生きてる間に宇宙ヘ行きたいね","マージン","ｅＭＡＸＩＳ Ｎｅｏ 宇宙開発"],
[140413,"3313188","blue","どうなる？","宇宙が開発されるとどうなるんだろう?","あおー","ｅＭＡＸＩＳ Ｎｅｏ 宇宙開発"],
[139232,"SPCE","red","…間に合わせて！","宇宙旅行、今世で絶対行きたい！今41だから間に合うはず…間に合わせて！","こぢゃ","ヴァージン・ギャラクティック・ホールディングス"],
[139166,"COST","orange","ついつい新商品に手が出る","いつも同じものを買いに行くのですが、ついつい新商品に手を出してしまいます。これは希望なのですが、プラの容器がもう少し処分し易くなったらいいなと思います。頑張れー","ポンコツ","コストコ"],
[140163,"COST","blue","購入意欲を掻き立ててくれる","肉の塊を筆頭に男の購入意欲を掻き立ててくれる商品がいっぱいで嬉しいです。大人買いができてるいつも感謝してます。","らっきー","コストコ"],
[137858,"GOOGL","red","散々お世話に","インターネット黎明期より散々お世話になっております。スマホもpixelを使っております。CMが琴線に触れすぎて涙したこともあります。これからも利用させていただきます","はいめん","Alphabet A"],
[139930,"NFLX","orange","ワンピースの実写版が！","ワンピースの実写版予告編観ました。なかなか面白そうで期待しています。役者の選定も苦労されたでしょうね。出演俳優はいずれUSJで登場したりして・・・","ハッピードラゴン","ネットフリックス"],
[140658,"NFLX","blue","過去作も最近増えてて嬉しい","はじめの一歩の配信に心から感謝最新も良いけど過去作も最近増えてて嬉しいです","オガワ","ネットフリックス"],
[137036,"NVDA","red","AIで儲かるのはNVIDIAなのかも","ゴールドラッシュで儲かったのは金を掘る道具屋だったと聞く。AIで儲かるのは道具を作ってるNVIDIAなのかもね。","禿親父","NVIDIA"],
[139344,"NVDA","orange","購入したい！！","凄い！今から購入しても遅くないのかなぁ～💦","ぺこ","NVIDIA"],
[140078,"NVDA","blue","これからも期待","自作PCでお世話になってます。これからも期待しています。","もろへいや","NVIDIA"],
[140515,"t9984p3","red","スポーツを応援してほしい","ワールドカップのバスケット支援していただき感謝します。いろんなスポーツを応援してほしいです","おつきみ","【推しカブ】ソフトバンクグループ"],
[140671,"u6612","orange","いつかは欲しい","いつかトースターほしい🥹","ちくぺん","バルミューダ"],
[10873,"u6952","blue","肉体労働向きのGショック","Gショックを長年愛用してます。頑丈なので肉体労働向きだと思います。時刻合わせの操作性はちょっと複雑ですね。","ジー","カシオ計算機"],
[139623,"u7270","red","どれに乗ろう","良いぞ。どれに乗ろう悩むこと","こう","ＳＵＢＡＲＵ"],
[140018,"u7270","orange","うちの家族はスバリスト","うちの家族はずーっとSUBARUが大好きで、スバリストと言っているぐらいです(笑)これからも安心安全な車作りをよろしくお願いいたします！","とみこ","ＳＵＢＡＲＵ"],
[137510,"u7733","blue","カメラの技術が人命を救う","カメラと言えばオリンパス、だったけど・・・いまや医療機器・内視鏡と言えばになって、少し遠い存在になってしまった。カメラの技術が人命を救う手立てになってくれれば、そう願わずにはいられない。","さといも","オリンパス"],
[140419,"u7733","red","より良い製品の開発に期待","体内の視覚化はとても重要ですよね。より良い製品の開発に期待します。","あっくお","オリンパス"],
[140370,"u9020","orange","心強いモバイルSuica","モバイルSuica便利ですね。家の鍵とスマホがあれば電車でのお出かけも心配無用。心強いです。","ソダチ","ＪＲ東日本"],
[140398,"u9020","blue","デジタル人材が増える","社員約5000人にDX教育を実施するんですね。これからは無人駅も増えていくでしょうから、デジタル人材を増やしてよりよい乗り継ぎ等のアドバイスが出来るようになって、欲しいです。また防災対策にも生きてくるでしょう。","ハッピードラゴン","ＪＲ東日本"],
[140420,"u9020","red","R東日本youtu部にハマっている","鉄道が大好きです。JR東日本youtu部見てください。とても興味深くてハマりました。","miomio","ＪＲ東日本"],
[140966,"u9020","orange","新幹線が福井へ！","北陸新幹線、来年3月には福井(敦賀)まで行ける…楽しみです☺️","ハタボン","ＪＲ東日本"]
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

