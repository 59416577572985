<template>
<div class="wrp">
	<img class="mark" width="17" height="18" alt="リアクション" v-show="favs > 0" :src="$cdn + '/img/main/icon_fav_0.svg'">
	<p class="favs">{{dispFavs()}}</p>
  <button class="fav" @click.stop="fav" v-show="isMark">
    <img width="32" height="32" alt="リアクション" :src="$cdn + '/img/main/icon_fav_' + (this.myMark == -1 ? 'off' : 'on') + '.svg'">
  </button>

</div><!--wrp-->
</template>

<style scoped>
.wrp{
	width: 100%;
	height: 32px;
	text-align: right;
	overflow: visible;
}
.mark{
	display: inline-block;
	height: 18px;
}
.favs{
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}
.fav{
	width: 32px;
	height: 32px;
  cursor: pointer;
	margin-right: 10px;
}
</style>

<script>
//表示に特化する
import {comma} from '../../lib/utils'

export default {
	props: {
		favs:    {type: Number, default: 0},
		myMark:  {type: Number, default: -1},
		isMark:  {type: Boolean, default: true},
	},

	data: function(){
		return {
		}
	},

	methods: {
		dispFavs: function(){
			return comma(this.favs)
		},

		fav: async function(){
			if(! this.isMark) return false
			if(this.myMark != -1) return false
			this.$emit('fav')
		},

	},

}
</script>
