let data = [
[147154,"u7867","orange","大大大ファンです","孫がトミカの大大大ファンです。誕生日のたびに増車してます。","カンクロウ","タカラトミー"],
[147317,"u7201","blue","初めて覚えた車","車好きの兄につられて、次男が初めて覚えた車のはにっしゃんっじーちーあーる(日産R32GT-R)です。だからか、日産の車を好んでいるようです。先進技術にも、ワクワクするようなデザインにも期待しています！","りじゅ","日産自動車"],
[148368,"u7201","red","障害者に優しい車両","福祉車両を見ていて良いなぁと思っています。障害者に優しい車両をよろしくお願いします。頑張って下さい。","芳輝煌","日産自動車"],
[148851,"u7201","orange","オーラ最高","オーラ最高なかっこ良いですね～","みゃう","日産自動車"],
[145649,"u7201","blue","自動運転での進化は凄い！","固体燃料やEV、自動運転での進化は凄い！さすが技術の日産！この調子でがんばって！","Macca","日産自動車"],
[147701,"t7203p3","red","ジャパンモビリティーショー","ジャパンモビリティーショー楽しかったです！","7000","【推しカブ】トヨタ自動車"],
[145687,"t7203p3","orange","面白い展示でした。","ジャパンモビリティショー、面白い展示でした。モビリティの今後にとてもワクワクしています。たくさんの選択肢を提供してくれることに期待しています。頑張ってください！！","kigngn","【推しカブ】トヨタ自動車"],
[145461,"t7203p3","blue","インタビューカッコ良い！","章男会長JMSのインタビューカッコ良い！これからも活躍して下さい。","ぬこ","【推しカブ】トヨタ自動車"],
[148459,"t7203p3","red","世界で頑張るトヨタを応援","クラウンやランクルかっこいい！世界で頑張るトヨタを応援しています！","たくたく","【推しカブ】トヨタ自動車"],
[148212,"t7203p3","orange","クラウンかっこいい！","クラウンかっこいい！とりあえず試乗しにいく！","みーこ","【推しカブ】トヨタ自動車"],
[147183,"t7203p3","blue","「86」が…","「86」が5ナンバーサイズだったら...","ひ〜さん","【推しカブ】トヨタ自動車"],
[147600,"u7203","red","累計3億台突破おめでとう","世界生産台数の累計3億台突破おめでとう🎉さらなる発展を期待します😊✨","ティンコ","トヨタ自動車"],
[146783,"u7203","orange","素敵な未来が見えました","業績絶好調ですね！工場トラブルで一時的に停まってましたが関係なし？クラウンセダンもカッコいいです。モーターショーでも素敵な未来が見えました。これからも頑張ってください！応援してます。","いちご屋さん","トヨタ自動車"],
[147967,"u7203","blue","高いけど、良い車","レクサスは高いけど、良い車。","おしゃかしゃま～。","トヨタ自動車"],
[146158,"u7203","red","唯一嫉妬した日本車","ベンツが唯一嫉妬した日本車がレクサス！！流石　トヨタ！！","ハッピードラゴン","トヨタ自動車"],
[148314,"u7203","orange","登場に期待","ランドクルーザーFJ(仮)の登場に期待です♪","ポンタ3号","トヨタ自動車"],
[147814,"u7203","blue","もっと世の中に知ってもらうべき","1600ccで300hp超えのカローラは、もっと世の中に知ってもらうべきです。小排気量化が進みます。","うっち","トヨタ自動車"],
[148125,"u7203","red","最高の車や","トヨタに乗り続けてはや10年、最高の車や","ポロ","トヨタ自動車"],
[146673,"u7203","orange","今週末に納車","初売りで注文したNOAHが今週末に納車となります。年内で乗ることができて良かったです😁大事に乗りながら、応援していきます👏頑張れトヨタ👍","かずー","トヨタ自動車"],
[146989,"u7203","blue","どうしたら、住めますか？","大阪万博開催の前に、未来都市の、ウーヴンシティに、暮らしたいです！どうしたら、住めますか？一般公募で、募集して頂けると、嬉しいです！","たくさん","トヨタ自動車"],
[147622,"u6902","red","世界一だと思う","自動車関連企業では、どこよりもスゴイ。世界一だと思う。これからも実はスゴイ裏方さん頑張ってほしい！","SA","デンソー"],
[146371,"u6981","orange","日本を支える企業","何かと職場でよく目にした村田製作所。最近はテレビＣＭでもよく耳にします。日本を支える企業としてこれからも頑張ってもらいたいです！","うっしー","村田製作所"],
[146645,"u7267","blue","大盛況みたいですね！","モビリティショウも大盛況みたいですね！プレリュードみたいなぁ日中は夏日になるのでアフター4がオススメですよ","さすけねぇ","ホンダ"],
[146671,"u7267","red","素晴らしい","SONYとの車素晴らしいです🤗","decade","ホンダ"],
[146679,"u7267","orange","考え方が大好き","本田宗一郎さんの考え方が大好きです。今ステップワゴン乗っています。","かるは","ホンダ"],
[148391,"u7267","blue","新型STEPWGN乗ってます。","新型STEPWGN乗ってます。楽しい社会の実現に期待しています♪","haru2301","ホンダ"],
[148738,"u7267","red","30年以上ずっとホンダ車","車の免許を取得して30年以上ずっとホンダ車。エンジン性能は抜群だと思う。乗り心地も車が変わっても乗りやすい。一生ホンダ車応援します","フクムスメ","ホンダ"],
[148407,"u7267","orange","ずっと愛用","スーパーカブをずっと愛用してます。燃費が良く丈夫です。","まさ","ホンダ"],
[147748,"u7269","blue","５ドアに期待！","シエラに乗っています。５ドアに期待❗","とも","スズキ"],
[147756,"u7270","red","エンジンが好き","スバルのエンジンが好きだ。アイサイトはよくできてるよ","ゆたかっち","ＳＵＢＡＲＵ"],
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

