export default {
	props: {
		height: { type: Number, default: 442 },
		backTouchClose: { type: Boolean, default: true },
	},

	data: function(){
		return {
			isOpen: false,
			isClosing: false,
		}
	},

	methods: {
		open: function(){
			this.isOpen = true
		},

		close: function(){
			this.isClosing = true
			setTimeout(this.closed, 350)
		},

		closed: function(){
			this.isClosing = false
			this.isOpen = false
		},

		backClose: function(){
			if(this.backTouchClose){
				this.close()
			}
		},

		dmy: function(){
		},

	},

}

