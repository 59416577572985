import {dtfmt, get_news} from '../../../lib/utils'
import Header from '../../components/Header.vue'

export default {
	data: function(){
		return {
			isReady: false,
			list: [],
		}
	},

	mounted: async function(){
		let news = await get_news(true)
		this.list = news
		if(news.length > 0){
			localStorage.setItem('newsNotifTime', news[0].date)
			this.$refs.header.isNotif = false
		}
		this.isReady = true
	},

	methods: {
		dtfmt: function(dt){
			return dtfmt(dt)
		},
	},

	components: {
		Header,
	},

}
