import {comma} from '../../../lib/utils'

export default {
	props: {
		l: {type: Object, default: {}},
		index: {type: Number, default: 0},
		activeRanking: {type: Number, default: 1},
	},

	data: function(){
		return {
		}
	},

	mounted: function(){
	},

	methods: {
		detail: function(c_code){
			this.$router.push({name: 'SearchDetail', params:{c_code: c_code}})
		},

		comma: function(num){
			return comma(num)
		},
	},

}

