<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	長期投資コラム
</div><!--head-->

<h1>第4回<br>個別銘柄投資は、<br>ハイリスクなのか？<br>銘柄投資の醍醐味<br>VS<br>おまかせ運用のラクラク投信</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<img class="kaisetsu chara_icon" style="width: 86px;" :src="cdn + '/img/rensai/nobu_to_yasu/kaisetsu_yasu.png'">

<pre>私たちの資産運用において、<span class="red">「個別銘柄に投資をする」方法</span>と、<span class="red">「投資信託を活用する」方法</span>と大きく2種類があります。

一般的に、個別銘柄投資はリスクがあって危険だから、素人は投資信託を活用するのが良い、という話を聞くことが多いですが、本当にそうでしょうか？

個別銘柄投資でも、リスクを抑えて大きなリターンを狙うことができる！
そのための基本鉄則1・2・3をご紹介します。

</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">個別銘柄投資でもリスクを
抑えて大きなリターンを狙う鉄則</p>
<p class="l">① じっくり腰を落ち着けて構えよ
② 焦らず大きなリターンを狙うべし
③ 必勝法は意外とシンプル</p>
</pre>
	<hr class="down">
</div><!--point-->

<pre>


</pre>

<h2>【1】個別銘柄投資の鉄則<span class="green">その１「じっくり腰を落ち着けて構えよ」</span></h2>

<pre>個別銘柄投資において、短期で売買して資金を回転させて殖やしていく手法もあります。
但し、これには情報収集などの労力がとてもたくさん必要なので、皆さんに普通にお勧めするのは難しい、と考えています。

一方で、<span class="red_bar">最低1年以上の長期でどっしり構えて投資をするスタンス</span>であれば、日々お仕事など忙しい皆さんでも、リスクを抑えてリターンを狙う確率を高めることができます。

</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">個別銘柄投資鉄則 その1
<span class="strong">じっくり腰を落ち着けて構えよ</span></p>
<p class="l">最低でも1年、1年以上の長期で
大きなリターンを狙える個別銘柄に
標的を合わせること
<span class="small">※銘柄選びは鉄則その3へ</span></p>
</pre>
	<hr class="down">
</div><!--point-->

<pre>


</pre>

<h2>【2】個別銘柄投資の鉄則その2<span class="green">「焦らず大きなリターンを狙うべし」</span></h2>

<pre>
個別銘柄投資の醍醐味は、大きなリターンを狙える可能性がある事です。

ノブとヤス第3回「寿スピリッツ」は、<span class="red">10年間で株価が約28倍</span>になりました。第2回「ファーストリテイリング」は、<span class="red">	10年間で株価が約4倍</span>になりました。

直近の1年間の株価で見ると、
<span class="red">　　寿スピリッツは、約50%上昇
　　ファーストリテイリングは約30%上昇</span>
参考までに、TOPIXは約20%上昇でした。
</pre>
<div class="grey">
<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu4/chart1.png'">
</div>
<pre>
「10年間もじっと待てないよ」
という方でも、最低1年はじっくりと腰を落ち着けて銘柄投資をしてみましょう。</pre>
<p class="red_bar">業績好調な企業の株価は上がる</p>
<pre>という当たり前を信じて、ご自身で「これは！」と思える銘柄を見つけてみてはいかがでしょうか？

10年間で数十倍、を狙うのでなくても、
<p class="red_center">
1年間で1.3倍になる銘柄
＝年間リターン30%
を狙うのは難しくない！
</p>
</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">個別銘柄投資鉄則 その2
<span class="strong">焦らず大きなリターンを狙うべし</span></p>
<p class="l center"><span class="red">1年で30％アップすれば、
<span class="red bar">3年で約<span class="red bar big">2倍</span>になります</span>

3年毎に2倍にできれば、
<span class="red bar">10年で<span class="red bar big">10倍</span>になります！</span></span></p>
</pre>
	<hr class="down">
</div><!--point-->

<pre>


</pre>

<h2>【3】個別銘柄投資の鉄則その3<span class="green">「必勝法は意外とシンプル」</span></h2>

<pre>
業績が良い会社、そして、今後の業績を伸ばすことができるだろうと思う企業を選べば、その会社の業績に応じて株価は上がります。

この単純な銘柄選びであれば、日々忙しい私たちも、株価に毎日張り付いている必要はありません。

銘柄選びのポイント
<span class="red">・今までの業績の確認</span>
確実に業績を伸ばしている企業
※Webで検索すればどなたでも容易く入手できる情報
<span class="red">・これからの業績がどうなるか</span>
業績が良い、その背景、理由を理解して今後の業績を予想する

</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">個別銘柄投資鉄則 その3
<span class="strong">必勝法は意外とシンプル</span></p>
<p class="l">・今までの業績が安定して確実な会社
<span class="red">・これからの業績も引き続き良い、
　と考えられる「理由」がある会社</span>

<span class="small">※STOCKPOINTでは、「この企業の強さの理由」
　を分析して、今後いろいろと紹介していきます。
　乞うご期待を。</span></p>
</pre>
	<hr class="down">
</div><!--point-->

<pre>
ここまでは、個別銘柄投資の話です。
一方で、投資信託で運用する方も多いことでしょう。

</pre>

<img class="kaisetsu" style="width: 335px;" :src="cdn + '/img/rensai/nobu_to_yasu/kaisetsu_vs.png'">

<img class="kaisetsu chara_icon" style="width: 86px;" :src="cdn + '/img/rensai/nobu_to_yasu/kaisetsu_hide.png'">

<div class="point hide">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">投資信託運用のメリット</p>
<p class="l">① インデックス型で安定した運用
② いろいろなものに投資できる
③ リターンが低いわけでもない</p>
</pre>
	<hr class="down">
</div><!--point-->

<h2 class="hide">【1】投資信託運用のメリットその1<span class="blue">「インデックス型で安定した運用」</span></h2>

<pre>
投資信託のメリットのひとつは、<span class="red">市場全体に投資をする</span>、ということが出来る事。
たとえば、TOPIXや日経平均に連動する投資信託であれば、日本の市場へ、ダウやS&amp;Pに連動する投資信託であればアメリカの市場へ、ということです。

市場全体を相手にするので、<span class="red">パフォーマンスは比較的安定</span>しますが、個別銘柄投資のような大きなリターンは狙い難いところがあります。

</pre>

<div class="point hide">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">投資信託投資のメリット その1
<span class="strong">インデックス型で安定した運用</span></p>
<p class="l">プロに任せて市場全体の成長に期待
するのは、安定・安全な投資手法の
ひとつ</p>
</pre>
	<hr class="down">
</div><!--point-->
<div class="grey">
<h2 class="hide">【2】投資信託運用のメリット その2<span class="blue">「いろいろなものに投資できる」</span></h2>

<pre>
もうひとつの魅力は、<span class="red">いろいろな種類がある</span>、ということ。
たとえば、日本やアメリカ以外の国にも投資ができたり、不動産に投資したり、資源エネルギーに投資したり、<span class="red">個人では直接難しい投資先に投資することができます。</span>

たとえば今では、成長著しい「インド」に投資をしてみたり、世界全体に投資をするということもできます。

</pre>

<div class="point hide">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<p class="center">投資信託投資のメリット その2
<span class="strong">いろいろなものに投資できる</span></p>
<p class="l">自分の考えや好みにあったテーマや
地域、産業などに対応する投資信託
を選ぶことができます。</p>
</pre>
	<hr class="down">
</div><!--point-->
</div>
<h2 class="hide">【3】投資信託運用のメリット その3<span class="blue">「リターンが低いわけでもない」</span></h2>

<pre>
投資信託の中でも、例えば、ロシア、東欧、中南米、中国、インド、東南アジアなどの国や新興企業の債券、つまり、リスクは少し大きいが、同時に大きなリターンを狙うこともできるかもしれない、<span class="red">「エマージング債」あるいは「ハイ・イールド債」に投資する投資信託</span>もあります。
これらは、<span class="red">年率10%以上になることもあります。</span>

ここは伸びるだろう！という理由を自分の中で納得できれば、こういう投資信託で運用することも良いでしょう。
</pre>

<div class="grey" style="margin-bottom: 0;">
<h2 class="hide">【4】最後に…</h2>

<pre>
繰り返しになりますが、どちらが正しい、ということではありません。
<span class="red">皆さん、それぞれにフィットした方を選ぶのが正しい選択です。</span>

そんな中で、STOCKPOINTでは、特に、<span class="red">個別銘柄投資も楽しいし、難しくないということを、今後はもっと積極的にご紹介していこうと思います。</span>

企業の魅力に気付いて、そこに投資をしてみよう、その企業を応援しよう、という仲間を増やすために……。
</pre>
</div>
<div class="nextWrp" style="margin-top: 0;">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>THE NORTH FACEだけ
じゃないゾ。「ゴールドウィン」</pre>
	</div><!--next-->
</div><!--nextWrp-->


<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
	>span.green{
		color: #288924;
		font-size: 24px;
		font-weight: bold;
	}
}
h2.hide{
	>span.blue{
		color: #195BA8;
		font-size: 24px;
		font-weight: bold;
	}
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #288924;
}
h2.hide:after{
	border-top: 4px solid #195BA8;
}
img.kaisetsu{
	display: block;
	margin: 1em auto;
}
img.chara_icon{
	transform: translateX(-10px);
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
p.red_center{
	line-height: 24px;
	margin: 0 auto;
	text-align: center;
	color: #EF5C5C;
	font-weight: 600;
}
p.red_center_bar{
	line-height: 24px;
	margin: 0 auto;
	text-align: center;
	color: #EF5C5C;
	font-weight: 600;
	text-decoration: underline;
}

.red{
	color: #EF5C5C;
	font-weight: 600;
}
.red_bar{
	color: #EF5C5C;
	font-weight: 600;
	text-decoration: underline;
	text-align: center;
}
span.uline{
	background: linear-gradient(transparent 60%, #FDAA85 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #E9F5E9;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
	>hr{
		position: absolute;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #1A1A1A;
		margin: 0;
		&.up{
			top: 5px;
		}
		&.down{
			bottom: 5px;
		}
	}
	>img{
		height: 30px;
		position: absolute;
		top: -15px;
		left: -2px;
	}
	>pre{
		font-weight: 600;
		text-align: center;
		>.l{
			display: inline-block;
			font-weight: inherit;
			margin: 0 auto;
			text-align: left;
		}
		>.center{
			font-size: 18px;
			font-weight: inherit;
			text-align: center;
		}
		.small{
			display: inline-block;
			font-size: 12px;
			font-weight: inherit;
			line-height: 1.5em;
			text-align:left;
		}
		.big{
			font-size: 20px;
		}
		.strong{
			font-size: 20px;
			font-weight: inherit;
		}
		.bar{
			text-decoration: underline;
		}
	}
}
.point.hide{
	background-color: #E9EFF5;
}
.grey{
	background-color: #F4F4F4;
	margin: 20px 0;
	padding: 20px 0;
}
.yellow{
	background-color: #FFF3C3;
	border-radius: 5px;
	padding: 20px 0;
	width: 335px;
	margin: 20px auto;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}


.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 13,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
