<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #04
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg4/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg4/MV.png'">

		<h1>「人権問題」の意識が低い、<br>日本の現状</h1>
		<p class="dt">2023/11/20</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">

	<pre>　ＥＳＧ投資が人々のココロに刺さる理由のひとつは、<span class="uline">身近な話題と関連づけられることが多いから</span>だろう。
それを実感したできごとが最近あった。ジャニーズ事務所元社長、故ジャニー喜多川氏の性加害問題だ。
　筆者はよく
「ＥＳＧを重視する投資家はジャニーズタレントを起用する上場企業の株式をダイベストメントしないのか」
と聞かれるようになった。<span class="uline">ダイベストメントとは「投資撤退」を意味する投資用語で、脱炭素に熱心に取り組まない企業や人権侵害をくり返す企業の株式を投資家が抗議の意味も込めて売却すること</span>だ。
　一般的に性加害問題は未成年者への犯罪と人権侵害であり、ダイベストメント（投資撤退）を考慮する投資家が増えている。ＥＳＧ（環境・社会・企業統治）の観点からも、性加害は「社会」に関する要素として大きな問題なのだ。

　日本の大企業もジャニーズタレントの広告起用を見送る動きが出ている。
イメージの悪化と、ダイベストメントへの警戒によるものだろう。国際的にも、人権問題は企業の社会的責任に関する重要なテーマとされており、投資家は問題企業に対してエンゲージメント（積極的な対話）も強化している。

　次の株主総会に向けて目立った動きが出る可能性がある。
性の問題は企業の評判や経営に直接的な影響を与えるからだ。
<span class="uline">企業社会におけるハラスメント排除の動きは加速</span>しており、国際サステナビリティ基準審議会（ISSB）は環境に関する情報開示基準を設けた後、<span class="uline">次のテーマとして「人権」</span>が検討している。　特に欧州の投資家は、「人的資本」と「人権」を関連づける。投資家との連携を深める英市民組織シェアアクションは機関投資家との連携し、ISSBに対して「人的資本と人権に関する開示基準を同時につくるべきだ」とする要望書を送った。

　<span class="uline">日本ではこの点についてはまだ発展途上</span>だ。日本の企業は「人」に関する問題を女性役員比率やリスキリングなどの狭い観点から考える傾向がある。しかし、<span class="uline">ことの本質的には労働者の人権尊重</span>にほかならない。

　<span class="uline">国連「ビジネスと人権」作業部会は今年８月４日に日本の人権状況に関する調査報告書</span>をまとめ、日本で記者会見を開いた。ジャニーズの性加害問題も取り上げられ、時節柄、国内メディアの報道はそこに集中した。しかし、作業部会報告の対象は外国人労働者や技能実習生、先住民族、部落など多岐に渡った。そのなかで最後に取り上げられたのが「メディアとエンターテインメント業界」だ。報告書を改めて読むと、<span class="uline">日本企業に対して幅広く「人権」に関する意識を高めるよう求める内容</span>だ。

　人権問題が企業の評判や市場取引に重大な影響を与える事例は過去にいくつもある。
１９９０年代にはナイキが低賃金労働による人権侵害で批判を受け、ブランド力と業績に悪影響をもたらした。さらに時代が下ると、アカデミー賞の常連だった映画プロデューサー、ハーベイ・ワインスタイン氏の悪質なセクハラがあった。２０１７年１０月に発覚し、直後にはツイッター（当時）で「#MeToo」と書き添えてセクハラ被害を告発する運動が世界的に広がった。同氏が共同経営していたワインスタイン・カンパニーの経営は破綻したほか、取引があった企業の評判や経営も大きく揺れた。
　ジャニーズ問題をきっかけに#MeToo　運動が、日本発で再び勢いを増す可能性だってある。
ＥＳＧ投資家の動きも注目される。</pre>

	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<img :src="cdn + '/img/rensai/esg/esg4/next.png'">
		<p class="title">「E」は現実を見据えつつも、<br>投資判断には重要な要素である<br>日本の現状</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg4/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	min-height: 60px;
	background-color: #D7E9EB;
	margin: 20px auto;
}
.next img{
	position: absolute;
	width: 46px;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}
.next .title{
	margin-left: 70px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 600;
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 8,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
