import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import List from './components/List.vue'
import Diary1 from './components/Diary1.vue'
import Diary2 from './components/Diary2.vue'
import Diary3 from './components/Diary3.vue'
import Diary4 from './components/Diary4.vue'
import Diary5 from './components/Diary5.vue'
import Diary6 from './components/Diary6.vue'
import Diary7 from './components/Diary7.vue'
import Diary8 from './components/Diary8.vue'
import Diary9 from './components/Diary9.vue'
import Diary10 from './components/Diary10.vue'
import Diary11 from './components/Diary11.vue'
import Diary12 from './components/Diary12.vue'
import Diary13 from './components/Diary13.vue'
import Diary14 from './components/Diary14.vue'
import Diary15 from './components/Diary15.vue'
import Diary16 from './components/Diary16.vue'

export default {
	components: {
		Header,
		List,
		Diary1,
		Diary2,
		Diary3,
		Diary4,
		Diary5,
		Diary6,
		Diary7,
		Diary8,
		Diary9,
		Diary10,
		Diary11,
		Diary12,
		Diary13,
		Diary14,	
		Diary15,
		Diary16,
	},

	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			comp: 'List',
		}
	},

	mounted: async function(){
		const id = this.$route.params.id
		if(id != null) this.comp = 'Diary' + id
		this.isReady = true

		setTimeout( () => {
			//初期化
			this.$refs.comp.init()
		}, 0)
	},

	methods: {
	},

}
