<template>
<div>
<TopCap page="event" />

<h3>STOCKPOINTのイベント</h3>
<p class="notes">STOCKPOINTの各種サービスで実施中のキャンペーンをご紹介します。</p>

<div class="bnr small" @click="linkTo('https://service.stockpoint.co.jp/comentsking/')">
	<img :src="$cdn + '/campaign/events/page1/bnr_small_comment_king2.jpg'">
  <p class="title">コメントキング</p>
  <p class="comp"></p>
</div>

<div class="bnr small" @click="moveTo(1)">
	<img :src="$cdn + '/campaign/events/page1/bnr_small_ranking.png'">
  <p class="title">現在の愛されコメント<br>TOP10</p>
  <p class="comp"></p>
</div>

<div class="bnr small" @click="moveTo(2)">
	<img :src="$cdn + '/campaign/events/page1/bnr_small_aisare_comment.jpg'">
  <p class="title">最新の「ステキなコメント投稿」会員様<br>TOP10</p>
  <p class="comp"></p>
</div>

<Footer />

</div>
</template>

<style scoped>
.notes{
	margin: 10px;
	font-size: 14px;
	line-height: 20px;
}
.bnr{
  overflow: hidden;
  background-color: white;
  height: auto;
  border: none;
  max-width: 355px;
  margin: 20px auto;
	background-color: #F8F8F8;
	cursor: pointer;
}
.bnr .title{
  display: block;
  font-size: 14px;
	font-weight: 600;
  line-height: 20px;
  margin: 10px;
  text-align: left;
}
.bnr.new .title:before{
	content: 'NEW';
	position: absolute;
	top: -10px;
	right: -26px;
	background-color: #F8397D;
	color: white;
	text-align: center;
	width: 32px;
	height: 18px;
	border-radius: 4px;
	line-height: 18px;
	font-size: 10px;
}

.bnr .comp{
  display: block;
  font-size: 10px;
  line-height: 14px;
  margin: 10px;
  text-align: left;
  color: #333333;
  opacity: 0.6;
}
.bnr.small img{
  display: block;
  float: left;
  width: 100px;
  height: 100px;
}
.bnr.small .title{
  width: 215px;
  float: left;
  margin: 10px;
}
.bnr.small .comp{
  width: 215px;
  float: left;
  margin: 0 10px;
}
</style>

<script>
import TopCap from '../../../components/TopCap.vue'
import Footer from '../../../components/Footer.vue'
import { getServerDateString } from '../../../lib/dateModules'

export default {
	props: {
		moveTo: {type: Function, default: (num)=>{}},
	},

	data: function(){
		return {
		}
	},

	mounted: async function(){
	},

	methods: {
		exLink: function(url){
			window.open(url, '_blank')
		},

    isReleaseDate: function(releaseDate){
      const serverDate = getServerDateString(NOW_DATE)

      return !releaseDate || releaseDate <= serverDate
    },

    isNotCloseDate: function(closeDate){
      const serverDate = getServerDateString(NOW_DATE)

      return !closeDate || serverDate < closeDate
    },

		dtFmt: function(num){
			const dt = new Date(num * 1000)
			return (dt.getMonth() + 1) + '月' + dt.getDate() + '日'
		},

		linkTo: function(url){
			location.href = url
		},

	},

	components:{
		TopCap,
		Footer,
	},
}
</script>
