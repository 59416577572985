<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	短期投資コラム
</div><!--head-->

<h1>第2回<br>
超短期投資手法その１<br>
「3日以内で勝負を決める」<br>
超短期モーメンタム投資とは</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<h2>群衆心理の先端で売り抜けるべし</h2>

<pre>短期投資で結果を出すのは、個人投資家には
難しい、という話が前回でした。

ただ、超短期投資でも一定の高いパフォーマンスを出している個人投資家の方は確実に存在しています。

そこで今回紹介するのが以下の手法。</pre>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>超短期モーメンタム投資

3日以内で確実に売り抜ける！
</pre>

	<hr class="down">
</div><!--point-->


<div class="grey">

<h2>群衆が集まりそうなイベントを事前にチェック</h2>

<pre>まずは準備。
市場の注目を集め、多くの投資家が集まって来る「個別銘柄の上昇局面」を事前に察知して、そこに<span class="blue">真っ先に参入する</span>。そして、上昇の勢いが途切れる前に<span class="blue">売り抜ける</span>ことで利益を確定する手法です。

この投資手法のリターンの源泉は、<span class="blue">群衆心理と群衆の波</span>です。

「これは儲かりそうだ！」という材料があると、多くの投資家が殺到します（＝群衆の形成）。しかし、その群衆の中では、情報の伝達速度や反応速度に違いがあるので、<span class="blue">先頭から最後尾までにタイムラグ</span>が発生。このタイムラグが1～2日になると、そこに<span class="blue">リターンを生み出す時間</span>が生まれるというわけ。</pre>

</div><!--grey-->

<h2>具体的な例で見てみましょう</h2>

<pre>例えば、オンワードホールディングス（8016）の例です。日本で有名なアパレルメーカーですね。

この会社、7月6日の引け後に、第１四半期（1Q）の決算を発表しました。（ちなみに、この日に発表があることは事前に公開されている情報です）</pre>


<pre style="text-align:center;">

<span class="blue">この決算が、予想を上回る好決算！</span>
<span class="blue">同時に通期の業績見込みを情報修正</span>

</pre>

<pre>これによって、翌日には多くの投資家が殺到することが予想されました。（で、実際にそうなりました。）</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/nobu2/chart1.png'">

<pre>この時に、

<span class="blue">決算発表の翌日（7/7）にすぐに購入。</span>
2日目、3日目（7/11）と、群衆として参加してくる他の投資家に株価を持ち上げてもらい、
<span class="blue">3日目の引けまでに保有株をすべて売却する</span>と、この3日間で、</pre>

<div class="yellow" style="text-align: center;">
	<span class="blue">約10〜13%のリターン</span>
</div>


<pre>が得られたことになります。
ちなみに、この局面で最も出来高（取引）が多かったのは、2日目でしたので、1日目に参入することで十分に群衆の先頭だったことが分かります。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>大切なことは2つ

・フットワーク軽く
・いつでもパパっと動ける準備
</pre>

	<hr class="down">
</div><!--point-->


<div class="grey">
<h2>短期的な上昇局面を事前に察知するには、どうすればいい？</h2>

<pre>ひとことで言うと、日々、上場企業の様々なニュースを入手し、チャンスになりそうな動きを察知できるようにしておくこと、です。

当たり前のことなのですが、こういう準備が「普通」の個人投資家、しかも日常株だけ見ているわけにはいかない「普通の人」が、時間をかけて取り組めるか、という点で、個人投資家にとっては難しい短期投資と言う所以なのです。

ちなみに、プロと言われる投資家だと、オンワードのケースでは、　

事前の業績予想は1Qの当期利益15億で、通期では40億
<p style="text-align: center;">↓</p>
それが、決算発表で1Qでいきなり当期利益が33.5億に大幅増
<p style="text-align: center;">↓</p>
となると、その利益をベースに株価を予想してみると、株価は550円〜570円になってもおかしくない
<p style="text-align: center;">↓</p>
と予想できて、実際に、決算発表後にその通りになった、というわけ。</pre>

<pre>さて、皆さんもこれを見てご自身でも試してみようと思いましたか？いかがでしょう？
</pre>
<br>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>超短期モーメンタムの兵法

・事前の準備を怠らずに
・上昇局面でリターン出せるかを
冷静に見極めて
・逃げ遅れずにさっさと手仕舞う
</pre>

	<hr class="down">
</div><!--point-->

</div><!--grey-->

<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>超短期投資の手法紹介 その2
「変則型逆張り投資」</pre>
	</div><!--next-->

</div><!--nextWrp-->

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #EF5C5C;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #EF5C5C;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
span.red{
	color: #EF5C5C;
}
span.blue{
	color: #2A78C6;
	font-weight: 600;
}
span.uline{
	background: linear-gradient(transparent 60%, #91C8FF 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #FCF2F2;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
}
.point hr{
	position: absolute;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #1A1A1A;
	margin: 0;
}
.point hr.up{
	top: 5px;
}
.point hr.down{
	bottom: 5px;
}
.point>img{
	height: 30px;
	position: absolute;
	top: -15px;
	left: -2px;
}
.point pre{
	font-weight: 600;
}

.grey{
	background-color: #FAFAFA;
	margin: 20px 0;
	padding: 20px 0;
}
.yellow{
	background-color: #FFF3C3;
	border-radius: 5px;
	padding: 20px 0;
	width: 335px;
	margin: 20px auto;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #F2CBCB;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #F2CBCB;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 7,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d, true)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
