<template>
<div class="wrp">
	<div class="main">
		<span class="btn" :class="{active: sort == 'new'}" @click="srt('new')">新着順</span>
		<span class="sp">|</span>
		<span class="btn" :class="{active: sort == 'favs'}" @click="srt('favs')">いいね順</span>
		<span class="sp">|</span>
		<span class="btn" :class="{active: sort == 'king'}" @click="srt('king')">コメントキング</span>
		<a class="filterBtn" @click="isFilter = true">さらに絞り込む</a>
	</div><!--main-->

	<div class="filter" v-show="isFilter">
		<div class="filterWrp">
			<a class="cancel" @click="isFilter = false"><img :src="$cdn + '/img/main/btn_close_green.svg'"></a>
			<p class="title">投稿テーマで絞り込む</p>
			<ul>
				<li @click="cTheme = -1" :class="{active: cTheme == -1}">
					<p style="margin-top: 36px;">全て</p>
				</li>
				<li @click="cTheme = 0":class="{active: cTheme == 0}">
					<img :src="$cdn + '/img/main/icon_c_theme0.png'">
					<p>{{ $apc.cTheme[0] }}</p>
				</li>
				<li @click="cTheme = 1" :class="{active: cTheme == 1}">
					<img :src="$cdn + '/img/main/icon_c_theme1.png'">
					<p>{{ $apc.cTheme[1] }}</p>
				</li>
				<li @click="cTheme = 2" :class="{active: cTheme == 2}">
					<img :src="$cdn + '/img/main/icon_c_theme2.png'">
					<p>{{ $apc.cTheme[2] }}</p>
				</li>
				<li @click="cTheme = 3" :class="{active: cTheme == 3}">
					<img :src="$cdn + '/img/main/icon_c_theme3.png'">
					<p>{{ $apc.cTheme[3] }}</p>
				</li>
				<li @click="cTheme = 5" :class="{active: cTheme == 5}">
					<img :src="$cdn + '/img/main/icon_c_theme5.png'">
					<p>{{ $apc.cTheme[5] }}</p>
				</li>
			</ul>

			<a @click="changeFilter">絞り込む</a>
			
		</div><!--filterWrp-->
	</div><!--filter-->

</div>
</template>

<style scoped>
.wrp{
	margin: 10px;
}
.main{
	height: 32px;
	line-height: 32px;
}
.main .btn{
	font-size: 12px;
	cursor: pointer;
}
.main .btn.active{
	color: var(--col-main);
}
.main .sp{
	font-size: 12px;
	margin: 0 5px;
}
.main .filterBtn{
	width: 120px;
	height: 32px;
	border: 1px solid var(--col-main);
	background-color: white;
	color: var(--col-main);
	font-size: 14px;
	font-weight: 400;
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	line-height: 32px;
}

.filter{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 60;
}
.filterWrp{
	background-color: #EDF7F6;
	padding-bottom: 20px;
}
.cancel{
	position: absolute;
	top: 0;
	right: 0;
	width: 48px;
	height: 48px;
	margin: 0;
	padding: 0;
	border: none;
}
.title{
	margin-top: 48px;
	text-align: center;
	color: var(--col-main);
	font-size: 16px;
	font-weight: 600;
}
ul{
	text-align: center;
}
li{
	display: inline-block;
	width: 100px;
	height: 100px;
	background-color: white;
	border-radius: 4px;
	margin: 8px;
	border: 2px solid white;
}
li img{
	margin-top: 20px;
}
li p{
	font-size: 14px;
}
li.active{
	border: 2px solid var(--col-main);
}
li.active p{
	color: var(--col-main);
}
</style>

<script>
export default {
	props: {
		//変更があった場合に呼び出すcallback関数
		cb: {type: Function, default: ()=>{}},
	},

	data: function(){
		return {
			sort: 'new', //new | favs | king
			cTheme: -1,  //-1はall 0,1,2,3,4
			isFilter: false,
		}
	},

	methods: {
		srt: function(sort){
			//変更ない場合はスキップ
			if(this.sort == sort) return false
			this.sort = sort
			this.cb()
		},

		changeFilter: function(){
			this.isFilter = false
			this.cb()
		},

	},
}
</script>
