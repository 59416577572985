<template>
<div class="topCap">
	<p class="t">{{t}}</p>
	<p class="s">{{s}}</p>
	<img :src="$cdn + '/img/main/cap_' + page + '.svg'">
</div><!--topCap-->
</template>

<style scoped>
.topCap{
	background-color: var(--col-main);
	width: 100%;
	height: 98px;
}
.topCap .t{
	font-size: 24px;
	font-weight: 600;
	color: var(--col-white);
	margin-top: 24px;
	margin-left: 20px;
	line-height: 33px;
}
.topCap .s{
	font-size: 14px;
	font-weight: 300;
	color: var(--col-white);
	margin-left: 20px;
	line-height: 14px;
}
.topCap img{
	position: absolute;
	top: 10px;
	right: 30px;
	height: 76px;
}
</style>

<script>
export default {
	props: {
		page: { type: String, default: "ranking" },
	},

	data: function(){
		return {
			t: 'Ranking',
			s: 'ランキング',
		}
	},

	mounted: function(){
		switch(this.page){
			case 'ranking':
				this.t = 'Ranking'
				this.s = 'ランキング'
				break
			case 'search':
				this.t = 'Search'
				this.s = '企業を探す'
				break
			case 'mypage':
				this.t = 'MyPage'
				this.s = 'マイページ'
				break
			case 'campaign':
				this.t = 'Campaign'
				this.s = 'キャンペーン'
				break
			case 'guideline':
				this.t = 'Guideline'
				this.s = 'ご利用にあたって'
				break
			case 'interview':
				this.t = 'Interview'
				this.s = 'インタビュー'
				break
			case 'event':
				this.t = 'Event'
				this.s = 'イベント'
				break
		}
	},

}
</script>
