<template>
<div class="main">

<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	連載小話　ノブとヤス
</div><!--head-->

<h1>第2話　選べない</h1>

<div class="yellow">

	<img class="talk1" :src="cdn + '/img/rensai/nobu_to_yasu/talk2/talk.png'">
	<img class="omake" :src="cdn + '/img/rensai/nobu_to_yasu/talk2/omake.png'">
	<img class="capContinue" :src="cdn + '/img/rensai/nobu_to_yasu/talk2/cap_continue.png'">

</div><!--yellow-->


<div class="column">
	<img class="capCheck" :src="cdn + '/img/rensai/nobu_to_yasu/cap_check.png'">
	<Column ref="column" :latestOnly="true" />
</div><!--column-->


<div class="green">

	<div class="yogoshu">
		<h4>よくわかるノブとヤス用語集</h4>

		<pre>・戦国ッター…人気SNS。最近名称が変わったとか。
・戦国プランナー…プランナーの上位職。生き抜くのが厳しい。試験がある。
・下黒上コーヒー…人気コーヒー。見た目が黒すぎるがうまい。

</pre>
		<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_tanu.png'">
	</div><!--yogoshu-->

</div><!--green-->


<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


</div><!--main-->
</template>

<style scoped>
.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
h1{
	text-align: center;
	padding: 20px 0;
}
.yellow{
	padding: 20px 0 40px 0;
	background-color: #FCFAEF;
}
.talk1{
	display: block;
	width: 335px;
	margin: 20px auto;
}
.omake{
	display: block;
	width: 335px;
	margin: 40px auto 20px auto;
}
.capContinue{
	display: block;
	width: 130px;
	margin: 20px auto;
}

.column{
	position: relative;
	overflow: visible;
	padding-top: 40px;
}
.capCheck{
	position: absolute;
	top: -35px;
	left: 0;
	right: 0;
	height: 84px;
	margin: auto;
}

.green{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.yogoshu{
	position: relative;
	overflow: visible;
	max-width: 335px;
	background-color: white;
	border: 1.5px solid #1A1A1A;
	padding: 20px;
	margin: 20px auto 40px auto;
}
.yogoshu h4{
	margin: 10px 0;
	color: #288924;
	font-size: 14px;
}
.yogoshu pre{
	font-size: 12px;
	line-height: 20px;
}
.yogoshu img{
	position: absolute;
	bottom: -22px;
	right: -40px;
	height: 104px;
}


.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}
</style>


<script>
import Column from './Column.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
		}
	},


	methods: {
		//初期化
		init: function(){
			this.$refs.column.getFavs()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

	},

	components: {
		Column,
	},

}
</script>
