<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	長期投資コラム
</div><!--head-->

<h1>第5回<br>めちゃくちゃ高い利益率を<br>誇る会社<br>「ゴールドウィン（8111）」</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<pre>「THE NORTH FACE」というアウトドア・ウエアのブランドをご存じでしょうか？

もともとは、登山用のジャンパーなどで有名なブランドですが、今ではすっかりタウンユースも定着しています。
ダウンジャケットが6〜7万円するブランドなので、決して安くはないですが、愛用する人も多い人気ブランドです。
ゴールドウィンは、「THE NORTH FACE」を日本で開発・生産・販売している会社です。

今回はこの会社、<span class="red">今後の成長が期待できそうな「スゴイ会社」</span>というところを解説していきましょう。

</pre>

<h2>1. ゴールドウィンってどんな会社？</h2>

<pre>ゴールドウィンは、アウトドア・ウエア／スポーツ・ウエアのメーカーです。

自社ブランドの「ゴールドウィン」に加え、エレッセ、ダンスキン、ヘリーハンセン、speedoなどの、多くの海外ブランドと日本における独占販売契約を結んでいて、それらの商品の日本における販売を行っています。

その中でも、「THE NORTH FACE」は、同社の売上の60〜70%を締める大黒柱になっていることが、決算発表会資料等から推測できます。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/img1.png'">

<pre>


</pre>
<h2>2. 今までの姿を見てみよう</h2>

<pre>まずは、長期投資の基本のキ！のデータ、
会社の業績を見てみましょう。

過去20年間の売上と当期利益の推移です。</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/chart1.png'">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/chart2.png'">
</div><!--grey-->

<pre>これに伴い、ゴールドウィンの株価の推移は下図の通り。</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/chart3.png'">
</div><!--grey-->

<pre>実は、2015年9月から日々の取引高が一気に増えていて、それに合わせるように株価も上昇トレンドに入っています。

これは、

　業績が改善してきたことが周知された
　　　　　　　　　　↓
　多くの投資家が入ってきた
　　　　　　　　　　↓
　業績の改善に合わせて株価も上昇

となっていたようです。

その結果、<span class="red">株価は</span></pre>

<pre class="yellow">過去10年間で<span class="big">14</span>倍</pre>

<pre>になっています。

2002年11月には最安値の75円だった同社が、<span class="red">2024年にはその100倍以上になった、</span>
というわけです。

その理由は？どこに転換点があったのか？

次に解説していきましょう。


</pre>

<h2>3. ゴールドウィンが「強い！」所以は</h2>

<pre>ゴールドウィンは、過去20年間において、不振だった事業を抜本改革し、成長する事業へと仕立て直しました。

その抜本改革とは、
</pre>

<pre class="yellow center">圧倒的に高い<span class="big">「</span><span class="bar big">利益率</span><span class="big">」</span>
非常に高い<span class="big">「</span><span class="bar big">資本効率</span><span class="big">」</span></pre>

<pre>です。

前回の寿スピリッツの回でも紹介しましたが、ゴールドウィンの場合も同様で、圧倒的に高い利益率が、同社の競争優位性を高め、株価の押し上げに貢献しています。

下記チャートは、2022年度の営業利益率の比較です。
<span class="small">※参考までに、ファーストリテイリングも加えています。</span>
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/chart4.png'">
</div><!--grey-->

<pre>ゴールドウィンの圧倒的に高い利益率が、ここから読み取れますよね。なんと、あの、ファーストリテイリングより高い！は驚きです！

もうひとつ。社員一人当たりの営業利益率が下記のチャートですが、これも驚きの高さ！
</pre>

<div class="grey">
	<img class="img chart" :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/chart5.png'">
</div><!--grey-->

<pre>ゴールドウィンの強さは、
<span class="red">　　圧倒的に高い「<span class="red bar">利益率</span>」
　　非常に高い「<span class="red bar">資本効率</span>」</span>
で、同じ金額を販売しても、他社より利益が圧倒的に高い。

その結果として、

　会社に多くのキャッシュが入って来る
　　　　　　　　　　↓
　そのキャッシュを次なる投資へ
　　　　　　　　　　↓
　その結果ライバルとの差が広がる

という好循環を生み出しているわけです。


</pre>

<h2>4. ゴールドウィンが主戦場にしている「アウトドア・スポーツアパレル」市場ってこれから期待できる市場なの？</h2>

<pre>まずは、<span class="red">日本のアパレル市場全体</span>の規模</pre>

<pre class="yellow">約8～9兆円の市場</pre>

<pre>この中で、ゴールドウィンの売上が約1,150億円なので、市場規模としてはまだまだ成長余地はあります。
但し、市場全体規模はここ数年でほぼ横ばいなので、この<span class="red">約8兆円の市場の中でどれだけシェアを伸ばせるか</span>、がゴールドウィンにとっての成長の分かれ目になります。

ちなみに、何度も出していますが、ファーストリテイリングの国内売り上げが約9,000億円なので、ファストリは市場全体の10%のシェアだ、ということになります。

もう少し絞り込んで、<span class="red">日本のスポーツウエア市場</span>は？というと、
</pre>

<pre class="yellow">約1兆7,000億円の市場</pre>

<pre>ゴールドウィンのTHE NORTH FACEの売上が、約700〜800億円程度ではないか、と計算できるので、<span class="red">ゴールドウィンのスポーツウエア市場全体のシェアは約7%</span>で、そこそこ大きなシェアを既に持っている、ことが分かります。

そのように考えると、今後のゴールドウィンの成長は、既存のアウトドア／スポーツウエア市場だけでなく、タウンウエアなどの日常服としての市場に、どれだけ浸透させことができるか、が大きな鍵になってきそうです。


</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<span class="big">ゴールドウィンの成長の鍵は…</span>

アウトドアスポーツウエアだけでなく、
日常服としてのブランド浸透が大切

</pre>
	<hr class="down">
</div><!--point-->

<pre>

</pre>
<div class="grey">
<pre>

</pre>

<h2>5. ここが肝！です。<br>
ゴールドウィンの強さを支えるポイント</h2>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<span class="big">ゴールドウィンの強さのポイント</span>

<p><span class="l li">①</span><span class="li">多くの消費者を引き付ける、独特の「世界観」</span>
<span class="l li">②</span><span class="li">「世界観」を伝えるビジネスモデルの構築</span>
<span class="l li">③</span><span class="li">消費者から選ばれる仕組みを保有</span></p>
</pre>
	<hr class="down">
</div><!--point-->

<pre><span class="bold">【理由1】
多くの消費者を引き付ける「世界観」</span>

「世界観」とはなにか、と言うと、<span class="red">お客様の心を掴んだり、共感を生み出したりする核になるもの</span>、です。
たとえば、ディズニーランドやアップル、エルメス、ポルシェなど、「強い引力」を持つブランドは必ず独特な「世界観」を保有しています。そして、この世界観が魅力的であればあるほど、そのまま競争優位性になっていきます。

<span class="red">ゴールドウィンのTHE NORTH FACEは、この世界観がコアエンジンになって、多くのお客様を引き付けています。</span>

<span class="bold">【理由2】
「世界観」を伝えるビジネスモデル</span>

ゴールドウィンは、THE NORTH FACE商品を、その企画・開発から生産、販売まで一気通貫で行っています。
この一気通貫のビジネスモデルで、<span class="red">お客様に対して薄まることなく確実に、独自の世界観を伝えることが出来ている</span>のです。

たとえば、「どのような商品を開発するのか」においても、<span class="red">直営店（全国約158店舗）からのフィードバック</span>を取り入れて商品の企画や開発を行っています。
このように、「お客様と直接接点を持っている」ことが大きなアドバンテージにもなっているのです。

<span class="bold">【理由3】
消費者から選ばれる仕組みを保有</span>

ゴールドウィンは、原宿やGINZA SIX、東京ミッドタウンなどの<span class="red">集客力のある場所に直営店を構えています</span>。そして、年間を通し多くのイベントや広告宣伝活動を行っています。

これらの直営店は、お客様との重要な接点となっており、<span class="red">価値ある場所にある直営店</span>であるからこそ、お客様にとっては魅力的なコミュニケーションとなり、お客様の心を動かして、お客様から選んでいただけるようになります。

</pre>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">
<pre>

<span class="big">ゴールドウィンの強さの
ポイントをまとめると</span>

<p class="center">「アメリカ発の世界観」
    <img style="display: block;margin:-24px auto;"  :src="cdn + '/img/rensai/nobu_to_yasu/yasu5/parts1.png'">
    「日本の現場のオペレーション」
</p>
<p class="small">東京ディズニーランドや、日本マクドナルドなどで成功
している、このふたつの融合が、ゴールドウィンの戦い
方でもフィットしていると考えられます。</p>
</pre>
	<hr class="down">
</div><!--point-->
<pre>

</pre>
</div>
<pre>
</pre>
<h2>6. これからの成長について<br>
期待を込めて考察してみましょう</h2>

<pre>そうなると、株価は……

2023年8月に起こったように、攻めの経営を行った結果、マーケティング費用が大きくなり、営業利益率が縮小して、市場のコンセンサスを下回ったために株価が下落するということは今後も起こるかもしれません。

ただし、同社の「お客様に選ばれる仕組み」は盤石である限りは、成長は期待できるのでは、と思います。


</pre>

<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre style="display: inline-block;text-align: center; padding-left: 1em;">「霞ヶ関キャピタル」って
なにをやっている会社？？</pre>
	</div><!--next-->
</div><!--nextWrp-->


<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #288924;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #288924;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
    >.center {
        text-align: center;
    }
}
.red{
	color: #EF5C5C;
	font-weight: 600;
}
.bold{
    font-weight: bold;
}
.yellow{
    background-color: #FFF3C3;
    margin: 1em auto;
    color: #EF5C5C;
    font-size: 18px;
    text-align: center;
    padding: 1em;
    font-weight: bold;
    >.big{
        font-size: 24px;
        font-weight: bold;
        color: #EF5C5C;
    }
}
.small {
    font-size: 12px;
}
.bar{
        text-decoration: underline;
    }
span.uline{
	background: linear-gradient(transparent 60%, #FDAA85 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #E9F5E9;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
	>hr{
		position: absolute;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #1A1A1A;
		margin: 0;
		&.up{
			top: 5px;
		}
		&.down{
			bottom: 5px;
		}
	}
	>img{
		height: 30px;
		position: absolute;
		top: -15px;
		left: -2px;
	}
	>pre{
		font-weight: 600;
		>p{
			font-weight: inherit;
			width: 320px;
			text-align: left;
			.l{
				margin-left: 12px;
                font-weight: bold;
			}
            .li {
                font-size: 14px;
                font-weight: bold;
            }
		}
        >.big {
            font-weight: bold;
        }
        >.center {
            text-align: center;
        }
        >.small {
            text-align:left;
            font-size: 12px;
            margin-left: 16px;
            line-height: 1.5em;
        }
	}
}

.grey{
	background-color: #F4F4F4;
	margin: 20px 0;
	padding: 20px 0;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}


.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 14,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
