<template>
<div class="item" :class="l.color">
	<img :src="cdn + imgUrl" v-show="isImg">
	<p class="title">{{l.title}}</p>
	<pre>{{l.comment}}</pre>
	<p class="nickname">{{l.nickname}}</p>
	<p class="msg"><span>{{l.c_name}}</span>へのメッセージ</p>
	<Favorite :favs="l.favs" :myMark="l.my_mark" @fav="mark"/>
</div><!--item-->
</template>

<style scoped>
*{
	font-family: 'ZenMaruGothic', sans-serif;
	font-size: 16px;
	font-weight: 300;
}
.item{
	position: relative;
	width: 335px;
	min-height: 280px;
	background-color: white;
	border-radius: 20px;
	padding: 20px 30px;
	margin: 20px auto;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.item img{
	display: block;
	width: 270px;
	margin: 10px auto;
}
.item .title{
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
	margin: 10px auto;
}
.item pre{
	font-size: 16px;
	line-height: 28px;
	margin: 10px auto;
}
.item .nickname{
	font-size: 14px;
	font-weight: bold;
	text-align: right;
	margin: 10px auto;
}
.item .msg{
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}
.item .msg span{
	color: #28CFB5;
}

.item.orange .title{
	color: #FCA90B;
}
.item.blue .title{
	color: #0B8DFC;
}
.item.red .title{
	color: #FF9898;
}
</style>

<script>
import Favorite from '../../../components/Favorite3.vue'

export default {
	props: {
		l: {type: Object},
		isImg: {type: Boolean, default: false},
		imgUrl: {type: String, default: ''},
	},

	data: function(){
		return {
			cdn: CDN,
		}
	},

	methods: {
		//初期化
		init: function(){
		},

		mark: function(m){
			this.$emit('fav', {id: this.l.id, mark: 0})
		},
	},

	components: {
		Favorite,
	},
}
</script>
