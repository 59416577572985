let data = [
    [161629,"u1570","blue","歴史的な一日！","日経平均新高値更新おめでとう🎉歴史的な一日に立ち会えたことに感謝㊗️","さわぽん","日本株レバレッジ上場投信"],
    [161493,"u8035","red","倍になりびっくり","１株しかもってませんが、倍になりびっくりしてます。","ありす","東京エレクトロン"],
    [161665,"bitcoin","orange","使っていないポイント","使ってないポイントはビットコインに！","荒井くま","bitcoin"],
    [161898,"bitcoin","blue","私はBTCのおかげで早期退職済み","永久不滅ポイントから換えたBTCももう少しでテンバガーです。値動きは激しいけど、すぐに使う当てのない資産はBTCにしておけば老後の心配はありません。私はBTCのおかげで早期退職済みです。","FIRE済み","bitcoin"],
    [161155,"FB","red","３倍ぐらい増えた","昨年の今頃から投資してますが３倍ぐらい増えました。この勢いで来年もまた３倍ぐらい増えてくださいm(__)m","ゴリちゃん","Meta（Facebook）"],
    [161823,"NVDA","orange","プラスが2881に!!半導体のこれからに期待","Stock point for MUFG でコツコツポイント投資もしているけど、NVIDIAを4616ポイント投資してたら、プラスが2881に!!半導体のこれからに期待«٩(*´ ꒳ `*)۶»ﾜｸﾜｸﾜｸﾜｸ","Lucas","NVIDIA"],
    [161722,"u9104","blue","優秀な航海士を","海外での人材育成にも取り組んでいるのですね。優秀な航海士を育てて安全な輸送に取り組んでほしいです","green","商船三井"],
    [162019,"u4478","red","確定申告に助かります！","青色申告しないとならないのですが、レシートまとめるの大変なのに、すごく助かります！","CourseK","Freee"],
    [161851,"u9984","orange","株価続伸は嬉しい限り","半導体株の恩恵で上がってるのかな？株価続伸は嬉しい限り、頑張れソフトバンク。","ミートくん","ソフトバンクグループ"],
    [161248,"u9432","blue","アイオン構想に期待","Innovative Optical and Wireless Network（IOWN:アイオン）構想に期待が膨らみます。","rexs","日本電信電話"],
    [161176,"u9433","red","20年以上愛用！資本提携に期待","20年以上au利用してます。ポン太もかわいくて大好きです。三菱商事、ローソンとの資本提携期待してます!","アクアマリン","KDDI"],
    [162002,"u8306","orange","出資は大いに期待","ウェルスナビ出資は大いに期待","かあるん","三菱UFJ"],
    [161941,"u7201","blue","子供が日産の車大好き","セレナに乗ってます。子供が日産の車大好きです。応援してます📣","りく","日産自動車"],
    [161850,"u7203","red","ノアを新車で購入","トヨタのノアを新車で購入。がんばれトヨタ❗世界のトヨタの好調がつづくといいなあ。","ジーザス","トヨタ自動車"],
    [161370,"u7270","orange","念願のレヴォーグが納車","先日、念願のレヴォーグが納車されました！","すず","ＳＵＢＡＲＵ"],
    [161448,"u9020","blue","いよいよ...！","北陸新幹線、いよいよ３月福井延伸…楽しみ方が増えそうですね!","ハタボン","ＪＲ東日本"],
    [161841,"u2702","red","株主優待で格段に美味しく","株主優待を使って、サムライマックにトマト3枚追加(優待で無料で追加できます！ソース増も可)で頼むのにハマってます！トマトが入るとちょっと食べにくくはなるけど、格段に美味しくなる(*´～｀*)ŧ‹ŧ‹ŧ‹","Lucas","日本マクドナルドホールディングス"],
    [161205,"u3197","orange","大好き！","子供がガスト好き！","くろねこ","すかいらーくホールディングス"],
    [161183,"u3382","blue","満たしてくれる","金のシリーズははずれもなくいつもお世話になっております❗自分の満たしてくれ感謝してます。これからもよろしくお願いです","K2o","セブン＆アイ・ホールディングス"],
    [161212,"u8194","red","コスパが良い","地域密着でアプリもあるし全体的にコスパが良いですよね！特に魚類は新鮮なので活用してます♪店内で作るパン部門『小麦の郷』もとっても美味しい😋","トミー","ライフコーポ"],
    [161847,"u8194","orange","応援したいスーパー","有機野菜のコーナーがあったり、食の安全にも力を入れている印象があります。応援したいスーパーです。","１年は組のドラ","ライフコーポ"],
    [162167,"u7974","blue","新しいの出ると聞いて","ニンテンドースイッチ……実は買ってないけど、そろそろ新しいの出ると聞いて、もう少し辛抱しようと耐えてます(笑)久しぶりに、DSやWiiで遊んでみたけど、やっぱり任天堂のゲームは面白い!!","Lucas","任天堂"],
    [161267,"u7751","red","旅行先を決める参考に","毎週､世界遺産を視聴しています。今後の旅行先を決めるのに参考にしています。","Ichipy","キヤノン"],
    [162078,"u1333","orange","筋トレ専用缶詰、作ってほしい","缶詰で手軽にタンパク質補給してます。朝昼晩食べられる筋トレ専用缶詰なんか作って欲しいナ!","muzu2","マルハニチロ"],
    [162035,"u2801","blue","日本の心","しょうゆ、日本の心","らーぷ","キッコーマン"],
    [161335,"u2801","red","アメリカでよく見かけた","アメリカに赴任していた時にどのスーパーでも見かけ、お世話になりました。海外売上高比率が高いのも魅力の一つだと思います。今後も頑張って欲しいです。","バゲット","キッコーマン"],
    [162045,"u2267","orange","世界中で愛される","子供の頃から親しんだ飲料ですが今や世界中で愛される飲料となりましたね。今後もどんどん素晴らしい製品を生み出して欲しいです。","マッキー2023","ヤクルト本社"],
    [161263,"u2587","blue","優しい味","子どもが小さい頃からDAKARAをきらしたことがありません。優しい味で私も大好きです。","ニコ","サントリー食品インターナショナル"],
    [162001,"u2229","red","我が家は常備","年間配当あげるなんてすごーい我が家にはいつもうす塩、コンソメ､しあわせバターのいずれかがあります","ナンバガ","カルビー"],
    [161203,"u2206","orange","ポッキーたくさん","ポッキーおいしいので、たくさん食べてます","そうま","江崎グリコ"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })