<template>
<div>
	<div class="cap1 yasu">
		<p class="sub">ゆっくり待とう家康派</p>
		<p class="m">長期投資コラム</p>
		<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_yasu1.png'">
	</div><!--cap1-->

	<ul class="list yasu">
		<li v-for="(l, i) in list.filter((rec) => rec.tp == 'yasu')" @click="linkTo('yasu', l.num)" v-show="i == 0 || ! latestOnly" >
			<p class="number"><span>第{{l.num}}回</span></p>
			<p class="name" v-html="l.name"></p>
			<p class="dt">{{dtFmt(l.dt)}}更新</p>
			<img class="arrow" :src="cdn + '/img/rensai/nobu_to_yasu/arrow_right_black.png'">
			<div class="fav">
				<Favorite :favs="l.favs" :myMark="l.my_mark" :isMark="false" />
			</div>
		</li>

	</ul>



	<div class="cap1 nobu">
		<p class="sub">バリバリ稼ぐぜ信長派</p>
		<p class="m">短期投資コラム</p>
		<img :src="cdn + '/img/rensai/nobu_to_yasu/chara_nobu1.png'">
	</div><!--cap1-->


	<ul class="list nobu">
		<li v-for="(l, i) in list.filter((rec) => rec.tp == 'nobu')" @click="linkTo('nobu', l.num)" v-show="i == 0 || ! latestOnly" >
			<p class="number"><span>第{{l.num}}回</span></p>
			<p class="name" v-html="l.name"></p>
			<p class="dt">{{dtFmt(l.dt)}}更新</p>
			<img class="arrow" :src="cdn + '/img/rensai/nobu_to_yasu/arrow_right_black.png'">
			<div class="fav">
				<Favorite :favs="l.favs" :myMark="l.my_mark" :isMark="false" />
			</div>
		</li>

	</ul>
</div>
</template>

<style scoped>
.cap1{
	position: relative;
	max-width: 335px;
	height: 130px;
	margin: 20px auto 0 auto;
}
.cap1 .sub{
	margin-top: 50px;
	font-size: 14px;
	line-height: 20px;
	color: #288924;
}
.cap1 .m{
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	color: #288924;
}
.cap1 .m:after{
	content: '　';
	border-top: 4px solid #288924;
	display: block;
	width: 30px;
}
.cap1 img{
	position: absolute;
	top: 0;
	right: 0;
	width: 130px;
	height: 150px;
	object-fit: cover;
}
.cap1.nobu .sub,
.cap1.nobu .m{
	color: #EF5C5C;
}
.cap1.nobu .m:after{
	border-top: 4px solid #EF5C5C;
}

.list li{
	position: relative;
	border-bottom: 1px solid #C1C1C1;
	max-width: 335px;
	margin: 0 auto 20px auto;
	cursor: pointer;
}
.list li .number{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	height: 70px;
	width: 72px;
	background-color: #ACD9AA;
	border: 1.5px solid #1A1A1A;
	margin: auto;
}
.list li .number span{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 20px;
	line-height: 20px;
	width: 100%;
	text-align: center;
}
.list li .name{
	margin-top: 10px;
	margin-left: 80px;
	margin-right: 40px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 600;
}
.list li .dt{
	font-size: 12px;
	margin-left: 80px;
}
.list li .arrow{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	height: 11px;
	margin: auto;
}
.list li .fav{
	margin-top: -18px;
	margin-right: 20px;
}

.list li:first-child{
	border: 3px solid #1A1A1A;
}
.list li:first-child .number{
	position: relative;
	border: none;
	border-bottom: 3px solid #1A1A1A;
	width: 100%;
	height: 48px;
	background-color: #ACD9AA;
	text-align: left;
}
.list li:first-child .number span{
	position: relative;
	margin-left: 20px;
	line-height: 48px;
}
.list li:first-child .name{
	margin-top: 20px;
	margin-left: 20px;
	color: #288924;
}
.list li:first-child .dt{
	position: absolute;
	top: 12px;
	right: 10px;
	background-color: #1A1A1A;
	color: white;
	width: 70px;
	height: 20px;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
}
.list li:first-child .arrow{
	margin-bottom: 40px;
}

.list.nobu li .number{
	background-color: #F48E8E;
}

.list.nobu li:first-child .name{
	color: #EF5C5C;
}
</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import {list} from './list.js'

export default {
	props: {
		latestOnly: {type: Boolean, default: false},
	},

	data: function(){
		return {
			cdn: CDN,
			list: list,
		}
	},

	methods: {
		getFavs: async function(){
			const rensai_ids = this.list.map( (rec) => rec.rensai_id )
			let d = {
				rensai_ids: rensai_ids,
			}
			const res = await post('/webapi/client/rensai/favs', d)
			const favs = res.code != 0 ? [] : res.favs

			for(let i=0, l=favs.length; i<l; i++){
				for(let j=0, jl=this.list.length; j<jl; j++){
					if(this.list[j].rensai_id == favs[i].rensai_id){
						this.list[j].marks   = favs[i].marks
						this.list[j].favs    = favs[i].favs
						this.list[j].my_mark = favs[i].my_mark
						break
					}
				}
			}

		},

		linkTo: function(tp, num){
			location.href = '/rensai/nobu_to_yasu/' + tp + '/' + num
		},

		dtFmt: function(num){
			const dt = new Date(num)
			return [(dt.getMonth() + 1), dt.getDate()].join('/')
		},
	},

	components: {
		Favorite,
	},
}
</script>
