<template>
<div class="searchBox">
	<input id="searchText" type="text" placeholder="企業名・キーワードで検索" v-model="searchText" @keydown.enter="search">
	<button id="searchButton" @click="clickSearch(searchText)">
		<img :src="$cdn + '/img/main/icon-Search-green.svg'">
	</button>
</div><!--searchBox-->
</template>

<style scoped>
.searchBox{
	max-width: 356px;
	margin: 0 auto;
}
.searchBox input[type="text"]{
	border: none;
	background-color: white;
	width: 100%;
	max-width: 356px;
	height: 52px;
	font-size: 16px;
	font-weight: 300;
	color: #333;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 60px;
	border: 1px solid #E5E5E5;
	border-radius: 8px;
}
.searchBox input[type="text"]::placeholder{
	opacity: 1;
	color: var(--col-main);
}
.searchBox button{
	position: absolute;
	height: 24px;
	top: 16px;
	right: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>

<script>
export default {
	props: {
		clickSearch: { type: Function, default: (text)=>{} },
		defaultText: { type: String, default: '' },
	},

	data: function(){
		return {
			searchText: this.defaultText,
		}
	},

	methods: {
		search: function(){
			if (event.keyCode !== 13) return
			document.getElementById('searchButton').click()
		},

	},

}
</script>
