<template>
<div class="wrapper">

<!--todo-->
<div class="topics" v-if="! isDone && topics && topics.length > 0">
	<p class="h">この企業のキーワード</p>
	<div class="topic" v-html="getTopics()"></div>
</div><!--topics-->

<div class="postComment" v-show="! isDone" :class="{err: errorFlg}">

	<p class="cThemeTitle">テーマを選択</p>
	<div class="cThemeList">
		<div class="cTheme" :class="{active: cTheme == 0}" @click="cTheme = 0">
			<img class="poly1" v-show="cTheme == 0" :src="$cdn + '/img/main/poly1.svg'">
			<div class="dmy"><!--poly1を後ろに隠すため-->
				<img :src="$cdn + '/img/main/icon_c_theme0.png'">
				<p>{{ $apc.cTheme[0] }}</p>
			</div><!--dmy-->
		</div>

		<div class="cTheme" :class="{active: cTheme == 1}" @click="cTheme = 1">
			<img class="poly1" v-show="cTheme == 1" :src="$cdn + '/img/main/poly1.svg'">
			<div class="dmy"><!--poly1を後ろに隠すため-->
				<img :src="$cdn + '/img/main/icon_c_theme1.png'">
				<p>{{ $apc.cTheme[1] }}</p>
			</div><!--dmy-->
		</div>

		<div class="cTheme" :class="{active: cTheme == 2}" @click="cTheme = 2">
			<img class="poly1" v-show="cTheme == 2" :src="$cdn + '/img/main/poly1.svg'">
			<div class="dmy"><!--poly1を後ろに隠すため-->
				<img :src="$cdn + '/img/main/icon_c_theme2.png'">
				<p>{{ $apc.cTheme[2] }}</p>
			</div><!--dmy-->
		</div>

		<div class="cTheme" :class="{active: cTheme == 3}" @click="cTheme = 3">
			<img class="poly1" v-show="cTheme == 3" :src="$cdn + '/img/main/poly1.svg'">
			<div class="dmy"><!--poly1を後ろに隠すため-->
				<img :src="$cdn + '/img/main/icon_c_theme3.png'">
				<p>{{ $apc.cTheme[3] }}</p>
			</div><!--dmy-->
		</div>

		<div class="cTheme" :class="{active: cTheme == 5}" @click="cTheme = 5">
			<img class="poly1" v-show="cTheme == 5" :src="$cdn + '/img/main/poly1.svg'">
			<div class="dmy" style="background-color: #FFFF99;"><!--poly1を後ろに隠すため-->
				<img :src="$cdn + '/img/main/icon_c_theme5.png'">
				<p>{{ $apc.cTheme[5] }}</p>
			</div><!--dmy-->
		</div>

	</div><!--cThemeList-->

	<textarea :placeholder="placeHolder" v-model="comment" @input="countup" maxlength="500"></textarea>
	<p class="errors err1"><span>!</span>コメントが入力されていません</p>
	<div>
		<p class="agreement" @click="linkTo('Terms')">利用規約を遵守の上、投稿ください。</p>
		<p class="count"><span>{{count}}</span>/500字</p>
	</div>
	<a class="post" @click="openModal">送信する</a>
</div><!--postComment-->

<Modal ref="modal" class="modal">
	<div class="header">
		<img :src="$cdn + '/img/main/icon_pen.svg'">
		<span>入力してください</span>
	</div>
	<div class="modalMain">
		<p class="notes">メッセージの投稿にはニックネームの入力が必要です。</p>
		<p class="s">ニックネーム</p>
		<input type="text" v-model="nickname" placeholder="ニックネーム">
		<p class="aste">※本名フルネームではなく、ニックネームのご記入をオススメいたします。</p>
		<a :class="{disabled: isNicknameEmpty()}" @click="post">投稿する</a>
	</div>
</Modal>

<div class="toast" :class="{showToast: isToast}">
	投稿ありがとうございました
</div>

</div><!--wrapper-->
</template>

<style scoped>
.topics{
	margin: 0 10px 20px 10px;
	border: 1px solid var(--col-main);
	border-radius: 10px;
}
.topics .h{
	display: block;
	height: 36px;
	line-height: 36px;
	text-align: center;
	color: var(--col-main);
	background-color: #F8F8F8;
}
.topics .topic{
	padding: 20px;
}
.topics .topic>>>span{
	margin-right: 10px;
}
.topics .topic>>>.k1{
	font-size: 18px;
}
.topics .topic>>>.k2{
	font-size: 14px;
}
.topics .topic>>>.k3{
	font-size: 10px;
}

.cThemeTitle{
	color: var(--col-main);
}
.cThemeList{
	height: 70px;
}
.cTheme{
	width: 60px;
	height: 60px;
	border: 2px solid white;
	border-radius: 4px;
	overflow: visible;
	float: left;
	margin-right: 8px;
	cursor: pointer;
}
.cTheme .poly1{
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
	margin: auto;
}
.cTheme .dmy{
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 4px;
	text-align: center;
}
.cTheme .dmy img{
	margin-top: 5px;
}
.cTheme .dmy p{
	font-size: 10px;
}
.cTheme.active{
	border: 2px solid var(--col-main);
}
.cTheme.active .dmy{
	background-color: white !important;
}
.cTheme.active .dmy p{
	color: var(--col-main);
}

.postComment{
	width: 100%;
	background-color: #EDF7F6;
	padding: 16px 10px;
}
textarea{
	resize: none;
	max-width: none;
	width: 100%;
	height: 90px;
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
}
.agreement{
	font-size: 12px;
	font-weight: 300;
	color: var(--col-main);
	text-decoration: underline;
	cursor: pointer;
}

.count{
	position: absolute;
	top: 0;
	right: 0;
	font-size: 12px;
	font-weight: 300;
}
.count span{
	font-size: 12px;
	font-weight: 300;
}

.post{
	width: 96px;
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	font-weight: 400;
	margin-right: 0;
	background-color: #26AECF;
	border: none;
}

.errors{
	display: none;
	font-size: 14px;
	font-weight: 300;
	color: #E60E16;
}
.errors span{
	display: inline-block;
	width: 14px;
	height: 14px;
	background-color: #E60E16;
	color: white;
	font-size: 14px;
	line-height: 14px;
	border-radius: 7px;
	text-align: center;
	margin-right: 5px;
}
.err .errors{
	display: block;
}
.err textarea{
	background-color: #FFE8E8;
	border: 1px solid #E60E16;
}


.modal .header{
	height: 60px;
	background-color: #EDF7F6;
	text-align: center;
	line-height: 60px;
}
.modal .header img{
	height: 22px;
}
.modal .header span{
	font-size: 16px;
	font-weight: 600;
}
.modal .modalMain{
	padding: 20px;
}
.modal .notes{
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 20px;
}
.modal .s{
	font-size: 12px;
	font-weight: 600;
	color: var(--col-main);
	line-height: 14px;
	margin-bottom: 10px;
}
.modal .s::after{
	content: "*";
	position: relative;
	color: red;
	top: 0;
	left: 0;
}
.modal .aste{
	font-size: 12px;
	font-weight: 300;
	line-height: 18px;
	color: #666666;
}
.modal a{
	margin-top: 30px;
	width: 128px;
	height: 48px;
	background-color: #FF6060;
	border: none;
}
.modal a.disabled{
	background-color: black;
	opacity: 0.1;
	pointer-events: none;
}

/*toast*/
.toast{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	line-height: 80px;
	text-align: center;
	color: var(--col-white);
	background-color: #F8397D;
	z-index: 60;
}
.showToast{
	display: block;
	animation: openClose 4s;
}

@keyframes openClose{
	0%   { top: -80px; }
	20%  { top: 0;}
	80%  { top: 0;}
	100% { top: -80px; }
}
</style>

<script>
import {get_user} from '../../lib/utils'
import Modal from './Modal/Modal.vue'

export default {
	props: {
		placeHolder: {type: String, default: "コメントはこちら（最大500字）"},
		topics: {type: Array, default: () => { return [] }},
	},

	data: function(){
		return {
			cTheme: 0,
			comment: '',
			count: 0,
			nickname: '',
			isDone: false,
			isToast: false,
			isProcessing: false,
			errorFlg: false,
		}
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name})
		},

		getTopics: function(){
			let html = ''
			let k = this.topics
			if(k == null) return ''
			for(let i=0, l=k.length; i<l; i++){
				html += '<span class="' + k[i].cls + '">' + k[i].topic + '</span>'
			}
			return html
		},

		openModal: async function(){
			if(this.count == 0){
				this.errorFlg = true
				return false
			}
			const user = await get_user()
			if(user == null){
				alert('アプリにログインされていないため、投稿できません。各Stock Pointアプリから「愛され企業さーち」を開いて、投稿してください。')
				return false
			}
			this.nickname = user.nickname
			
			this.$refs.modal.open()
		},

		countup: function(){
			this.errorFlg = false
			this.count = this.comment.length
			if(this.count > 500){
				this.comment = this.comment.substring(0,500)
				this.count = this.comment.length
			}
		},

		post: async function(){
			if(this.isDone || this.isProcessing || this.isNicknameEmpty()) return false
			this.isProcessing = true
			this.$emit('post', {nickname: this.nickname, comment: this.comment, c_theme: this.cTheme})
			this.$refs.modal.close()
		},

		close: function(){
			this.isDone = true
			this.isToast = true
			this.isProcessing = false
			setTimeout(() => {
				this.isToast = false
			}, 3800)
		},

		isNicknameEmpty: function(){
			return this.nickname == '' || this.nickname == null ? true : false
		},

	},

	components: {
		Modal,
	},
}
</script>
