import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'

export default {
	data: function(){
		return {
			isReady: false,
			pages: [],
			page: 0,
			name: '',
			s_code: '',
		}
	},

	mounted: async function(){
		this.page = parseInt(this.$route.params.page) || 0
		let d = {id: this.$route.params.id}
		let res = await this.$post('/webapi/client/interview/detail', d)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.pages = res.pages
		this.name = res.name
		this.s_code = res.s_code
		this.isReady = true
	},

	methods: {
		backTo: function(){
			let name = this.$route.params.from || 'InterviewList'
			let c_code = this.$route.params.c_code || ''
			this.$router.push({name: name, params:{c_code: c_code}})
		},

		prev: function(){
			if(this.page <= 0) return false
			this.page -= 1
		},

		next: function(){
			if(this.page >= this.pages.length -1) return false
			this.page += 1
			window.scrollTo({top: 0})
		},
	},

	components: {
		Header,
	},

}
