import { get_products, get_sdgs_categories, updateMetaHead } from '../../../lib/utils'
import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import Sdgs from '../../components/Sdgs.vue'


export default {
	data: function(){
		return {
			list: [],
			sdgsCategories: {},
			category: {},
			products: {},
		}
	},

	mounted: async function(){
		//sdgsCategories
		let cats = await get_sdgs_categories()
		for(let i=0, l=cats.length; i<l; i++) this.sdgsCategories[cats[i].id] = cats[i]
		//選択されたカテゴリ
		this.category = this.sdgsCategories[this.$route.params.id]

		//銘柄データ取得
		this.products = await get_products(false)

		//SDGsデータ取得
		let res = await this.$post('/webapi/client/sdgs/list', {id: this.category.id}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.list = res.list

		updateMetaHead(this.category.name)
	},

	methods: {
	},

	components: {
		Header,
		TopCap,
		Sdgs,
	},

}
