import { updateMetaHead } from '../../../lib/utils'
import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import ProductItem from '../../components/ProductItem/ProductItem'


export default {
	data: function(){
		return {
			list: [],
			themList: [],
			theme: {},
			products: [],
		}
	},

	mounted: async function(){
		//theme
		if(sessionStorage.getItem('themeList') == null){
			let res = await this.$post('/webapi/client/theme', {}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			sessionStorage.setItem('themeList', JSON.stringify(res.list))
		}
		this.themeList = JSON.parse(sessionStorage.getItem('themeList'))

		for(let i=0, l=this.themeList.length; i<l; i++){
			if(this.$route.params.theme == this.themeList[i].id){
				this.theme = this.themeList[i]
				break
			}
		}
		updateMetaHead(this.theme.name)

		//銘柄マスタ
		if(sessionStorage.getItem('products') == null){
			let res = await this.$post('/webapi/client/products', {}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			sessionStorage.setItem('products', JSON.stringify(res.list))
		}
		this.products = JSON.parse(sessionStorage.getItem('products'))
		this.search(this.$route.params.theme)
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name})
		},

		search: function(theme){
			this.list = []
			for(let i=0, l=this.products.length; i<l; i++){
				let p = this.products[i]
				if(p.theme.indexOf(parseInt(theme)) >= 0) this.list.push(p)
			}
		},

	},

	components: {
		Header,
		TopCap,
		ProductItem,
	},

}
