<template>
<div class="wrapper">
	<p class="title">{{l.title}}</p>
	<p class="name" v-show="isName" @click="$router.push({name: 'SearchDetail', params: {c_code: l.c_code}})">{{product == null ? '' : product.name}}</p>
	<div class="categories">
		<p v-for="(cat, i) in catList" :key="i" :class="cat.group">{{cat.name}}</p>
	</div><!--categories-->
	<p class="cap">{{l.cap}}</p>
	<a class="link" :href="l.link" target="_blank"><img :src="$cdn + '/img/main/icon_popup_green.svg'">詳しくはこちら</a>
	<p class="favs">{{favs}}</p>
	<img class="fav" :src="favSrc()" @click="changeFav(l.id)">
	<img class="sdgsImage" :style="{display: isImage && sdgsImage ? 'block' : 'none'}" :src="$cdn + '/img/sdgs/' + l.id + '.png'" @load="sdgsImage = true">
</div>
</template>

<style scoped>
.title{
	color: var(--col-main);
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}
.name{
	color: var(--col-main);
	font-size: 12px;
	line-height: 18px;
	cursor: pointer;
	margin: 5px 0;
}
.categories p{
	display: inline-block;
	margin: 0 2px;
	padding: 0 10px;
	font-size: 10px;
	line-height: 20px;
	background-color: #50BDC7;
	color: white;
	border-radius: 10px;
}
.categories p.group2{
	background-color: #FFF2CC;
	color: #666;
}
.categories p.group3{
	background-color: #F9CB9C;
	color: #666;
}

.categories p:first-child{
	margin-left: 0;
}

.cap{
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.link{
	border: none;
	margin: none;
	padding: none;
	line-height: auto;
	height: auto;
	color: var(--col-main);
	background-color: transparent;
	font-size: 12px;
	font-weight: 300;
	text-decoration: underline;
	line-height: 20px;
	width: 150px;
	float: left;
}
.link img{
	height: 14px;
	width: 14px;
	margin-right: 5px;
}
.favs{
	width: calc(100% - 150px - 32px - 5px);
	float: left;
	text-align: right;
	line-height: 32px;
	margin-right: 5px;
}
img.fav{
	width: 32px;
	height: 32px;
	cursor: pointer;
}

img.sdgsImage{
	clear: both;
	margin: auto;
	display: none;
}
</style>

<script>
import { get_sdgs_categories, get_products } from '../../lib/utils'

export default {
props: {
	l: {type: Object, default: () => { return {} }},
	isName: {type: Boolean, default: false},
	isImage: {type: Boolean, default: true},
},

data: function(){
	return {
		sdgsImage: false,
		catList: [],
		product: null,
		favs: 0,
		fav_flg: false,
	}
},

mounted: async function(){
	this.favs = this.l.favs
	this.fav_flg = this.l.fav_flg

	if(this.isName){
		let prod = await get_products(false)
		this.product = prod.obj[this.l.c_code]
	}

	let catsObj = {}
	let cats = await get_sdgs_categories()
	for(let i=0, l=cats.length; i<l; i++) catsObj[cats[i].id] = cats[i]
	for(let i=0, l=this.l.categories.length; i<l; i++){
		this.catList.push(catsObj[this.l.categories[i]])
	}
},

methods: {
	favSrc: function(){
		if(this.fav_flg){
			return this.$cdn + '/img/main/icon_fav_on2.svg'
		}else{
			return this.$cdn + '/img/main/icon_fav_off.svg'
		}
	},

	changeFav: async function(id){
		if(this.fav_flg) return false
		let res = await this.$post('/webapi/client/sdgs/fav', {id: id}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.fav_flg = true
		this.favs += 1
	},

	dispSdgsImage: function(){
		this.sdgsImage = true
	},

},
}
</script>
