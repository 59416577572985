export const list = [
	{
		id: 131831,
		c_code: 't4661p3',
		color: "orange",
		title: '４歳の孫からのお誘い',
		comment: "4歳の孫から今度連れて行ってあげると誘われました。何年振りだろう？すごく楽しみです。",
		nickname: 'トッシー',
		c_name: '【推しカブ】オリエンタルランド',
		marks: [],
		favs: 0,
		my_mark: -1,
	},
	{
		id: 132269,
		c_code: 't4661p3',
		color: "blue",
		title: '我が子を連れて同じ経験を',
		comment: "ディズニーランド40周年おめでとうございます！これからも古い設備をメンテナンス、維持し続けながら、事故を起こさない、安全なアトラクションを管理し続けてください！子供の頃にディズニーランドの楽しさを味わい、大人になっても自分も思い出に浸りながら、我が子を連れて自分が子供の頃と同じ経験をしてもらう！変わらぬ楽しさが夢の国、ディズニーランドにあります👍",
		nickname: 'Peace',
		c_name: '【推しカブ】オリエンタルランド',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131968,
		color: "red",
		title: '年齢関係なくゲストに寄りそう施設',
		comment: "足が悪くて移動は大変でしたが、私でも楽しめるアトラクションもありました。対象年齢が存在しても年齢に関係なく楽しめるアトラクションも存在するんじゃないかと思いました。例えば。イッツ　スモールワールドとか。色々な配慮がされていて、ゲストに寄り添ってくれる施設だと思いました。全て制覇したワケではありませんが、ディズニーランドを訪れた時の感想です。",
		nickname: '彼方',
		c_name: '【推しカブ】オリエンタルランド ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 128052,
		color: "orange",
		title: '値上がりしても夢の国へ',
		comment: "値上げがあっても夢の国にはお金は払いますよ！頑張って下さい。応援します。",
		nickname: 'アルパカ',
		c_name: 'オリエンタルランド',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 10306,
		color: "blue",
		title: '高校サッカーに出資するいい取り組み',
		comment: "この間、テレビで青森山田高校のサッカー部が出ていて、そこでJALがスポンサーになっていて、遠征費は無料だって紹介していた。高校に出資するってすごいことだけど、すごくいい取り組みだとも思った。",
		nickname: 'しのら',
		c_name: 'ＪＡＬ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130917,
		color: "red",
		title: 'JALラウンジカレー最高！',
		comment: "日本航空さんはSDGsに取り組んでおられます。食べ残し防止や食品残渣の飼料化により、食品廃棄を減らしてCO2を削減私も微力ながら協力しております・ウェルカムドリンクの廃棄を減らすべく、残量が出ぬようシャンパンのお代わりを頂く(機上で飲み過ぎると悪酔いしますが)・ラウンジカレーでは、肉ばかり取りません！皆さんと分け合いますJALさんの取り組みに賛同し、引き続き協力して参りたい。私と致しましてはそのように考えているのであります👍️",
		nickname: 'ペロポネソスで会いましょう',
		c_name: 'ＪＡＬ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130868,
		color: "orange",
		title: 'JALってすごい！',
		comment: "あんなにも大きな飛行機でCO2排出実質0を目指すなんて、JALすごいなと思いました。是非実現していただきたいです。",
		nickname: 'れれれ',
		c_name: 'ＪＡＬ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129010,
		color: "blue",
		title: '買い替えました',
		comment: "最近家の2台を買い換えました　機能がグッとアップしていて、お値段はそんなにアップしてなくて助かりました！",
		nickname: 'じぃじぃ3',
		c_name: 'ＴＯＴＯ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130285,
		color: "red",
		title: 'バスルームをリフォーム',
		comment: "バスルームをリフォームしました。ホッカラリ床最高ですね！除菌水の床清掃も気に入ってます！これからも良い製品作り頑張ってください！",
		nickname: 'ホッカラリ',
		c_name: 'ＴＯＴＯ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 132102,
		color: "orange",
		title: '時給換算で300円！',
		comment: "時給換算で300円ほどで働いてもらってます。部屋の角をもっと頑張ってくれるとなお良いです。",
		nickname: 'ぶたしん',
		c_name: 'アイロボット',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129419,
		color: "blue",
		title: 'ドラえもん開発に期待',
		comment: "実用的なロボット開発というのが素晴らしいと思います✨✨ドラえもん開発に期待(っ ॑꒳ ॑c)ﾜｸﾜｸ",
		nickname: '一期一会',
		c_name: 'アイロボット',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130258,
		color: "red",
		title: 'スーパーで買い物といえば',
		comment: "イオンでの買い出しをきっかけに夫がスーパーで買い物できるようになりました、棚間隔の広さ、案内表示がわかりやすい、セルフレジの袋掛けが大きくて使いやすいそうです。今では袋詰めまでやってくれます",
		nickname: '満ちる',
		c_name: 'イオン',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 128919,
		color: "orange",
		title: '“袋を開けたら 止まらない” ',
		comment: "“袋を開けたら 止まらない” のだけど敢えて封ができると嬉しいポテチ(#^.^#)",
		nickname: 'ドリーチェ',
		c_name: 'カルビー',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131281,
		color: "blue",
		title: '歯応えがたまりません',
		comment: "堅あげポテトのファンです。あのガリガリした歯応えがたまりません。末長く販売してほしい！",
		nickname: 'にやわー',
		c_name: 'カルビー',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 10119,
		color: "red",
		title: '一袋をペロッと食べれてしまう',
		comment: "ポテチ、量は少ないし価格も上がる一方だけど、美味しいので買ってしまいます。最近は一袋をペロッと食べれてしまう量。やばいですw",
		nickname: '甘夏',
		c_name: 'カルビー',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130582,
		color: "orange",
		title: '毎日玉子焼きの上に',
		comment: "毎日玉子焼きの上にキューピーかけていただいています❗️ダブルたまご。私の身体はタマゴで出来ています😃",
		nickname: 'ジンシ',
		c_name: 'キユーピー',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129702,
		color: "blue",
		title: 'マヨネーズが大好き',
		comment: "マヨネーズが大好きなのは、キューピーさんの・・・…　お・か・げ　です✨　ありがとうございます😊",
		nickname: 'よしお',
		c_name: 'キユーピー',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131173,
		color: "red",
		title: '粉チーズ有料化',
		comment: "サイゼリヤの粉チーズ、今月12日から税込み100円に有料化です！これは粉チーズ提供の持続可能性(サステナビリティ)に配慮したものです🥰サイゼリヤさんはパスタ大盛りを廃止するなど、食料廃棄削減に取り組んでこられました。サイゼリヤのSDGs活動を応援します👍️",
		nickname: 'ペロポネソスで会いましょう',
		c_name: 'サイゼリヤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131826,
		color: "orange",
		title: '近くにあると嬉しいお店',
		comment: "子供が3人いるので、サイゼリヤのお値段は本当に助かります。近くにあると嬉しいお店の1つです！これからも応援しています。",
		nickname: 'ぺんぎん',
		c_name: 'サイゼリヤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129166,
		color: "blue",
		title: '美味しくて安全な食事',
		comment: "いつも美味しくて安全な食事を提供してくれてありがとうございます。給料は上がらないけど、助かっています！",
		nickname: 'ミドリムシ',
		c_name: 'サイゼリヤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131372,
		color: "red",
		title: 'イカ墨スパゲティの美味しさを知った',
		comment: "イカ墨のスパゲティの美味しさを知ったのは、サイゼリヤでした。ありがとう!　(⁠人⁠*⁠´⁠∀⁠｀⁠)⁠｡⁠*ﾟ⁠+",
		nickname: '令',
		c_name: 'サイゼリヤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 128828,
		color: "orange",
		title: 'カプチーノ再販希望',
		comment: "カプチーノ　再販希望この間　ブルーにオールペンされたカプチーノに出会いました❤️やっぱりカッコ可愛いですね❤️",
		nickname: 'midori',
		c_name: 'スズキ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131234,
		color: "blue",
		title: 'カッコよくてカワイイ',
		comment: "ハスラー、X-BEE、ジムニーみんなカッコよくてカワイイ益々人気になると思ってます！",
		nickname: 'SA',
		c_name: 'スズキ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 128217,
		color: "red",
		title: 'この度やっとスズキを購入',
		comment: "次乗る軽自動車はスズキにする❗️と思ってましたが、この度やっと購入する事が出来ました(^^)運転してみて乗りやすいのも良かったけれど、お店の接客対応もすごく良いので大満足です。これからお世話になります(^^)",
		nickname: 'あっちっち',
		c_name: 'スズキ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130186,
		color: "orange",
		title: 'ビジョン実現応援しています！',
		comment: "すき家やなか卯が有名ですが、ココス、華屋与兵衛やジョリーパスタ、はま寿司等様々なブランドを展開されてますね。最近ではロッテリアが話題になりましたかね。世界から饑餓と貧困を無くすというビジョン実現の為頑張って下さい！",
		nickname: 'カワフロ',
		c_name: 'ゼンショーホールディングス',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 10183,
		color: "blue",
		title: '「ニンニクの芽牛丼」が毎年待ち遠しい',
		comment: "すき家の期間限定メニューが楽しみです。特に「ニンニクの芽牛丼」が毎年待ち遠しくて、今年も時期的にそろそろかな？と心待ちにしています。",
		nickname: 'ぽんみ',
		c_name: 'ゼンショーホールディングス',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131681,
		color: "red",
		title: 'EV生産台数一番多い',
		comment: "アメリカのとあるEV専業メーカーにもポイントペット(投資)してるけど、実質的にEV生産台数一番多いのはやはりトヨタだわね、こないだ普段使っているるトヨタのガソリン車(パッソ)に不都合合って部品交換してもらうためにディーラーの人に代車貸して貰ったんだけど、適当なのなかったんで、一週間ハイブリッド車を代車代わりに貸してもらってた。で少し乗って充電状態を附属ナビで見てたんだけど、ほんとよく出来てるね。充電して満充電になったらエンジン停めて、実質EV走行したらまた充電という感じ、かなり精密なシステムだと思いました。",
		nickname: 'はらっぱ',
		c_name: '【推しカブ】トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131521,
		color: "orange",
		title: '会長が素晴らしい',
		comment: "会長が素晴らしすぎる。他ではなかなかできない事をやって、それで結果を出しているのには本当に頭が下がります。以前インタビューで「わたしはうるさいくるまがすきです。」とテレビで言ってたのは凄いと思った。そんな私もうるさい車が好きです(*´ω`*)",
		nickname: 'うききーも',
		c_name: 'トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129555,
		color: "blue",
		title: 'やはり世界Ｎｏ．1の会社',
		comment: "リチューム電池で中国に遅れを取っていますが、固体電池の実用化で巻き返し、やはり世界Ｎｏ．1の会社です。EV化を進めていますが後進国はやはり従来の内燃機関エンジンを使った車が必要とされています。トヨタは両方を見ら見ながら戦略を立てています。流石世界のトヨタですね。今後のますますの業績アップに期待しています。",
		nickname: 'ギョロ',
		c_name: 'トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 132047,
		color: "red",
		title: 'がんばれトヨタ！！',
		comment: "ＥＶにも積極的に対応する方針を表明して、これからの怒涛の追い上げと「世界のトヨタ」の実力の見せ所。が、ん、ば、れ、ト、ヨ、タ！！！",
		nickname: 'こんこん',
		c_name: 'トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129355,
		color: "orange",
		title: 'テンバガー期待しています',
		comment: "本業の自動車はもちろん、ロボットやスマートシティなど幅広い分野で研究開発に取り組んでいます。ＥＶに乗り遅れているなどと言われていますが、決してそんなことはありません。企業価値を最大化できる最適なタイミングで圧倒的な新商品をＥＶ市場に投入することになるでしょう！株価爆上げ、ここからテンバガー期待しています。",
		nickname: 'チェリー',
		c_name: 'トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131932,
		color: "blue",
		title: 'さあ、熱いトヨタの開幕だ',
		comment: "【この夏、熱くなる会社は】トヨタは注目ですね。ＢＥＶが注目のなか、ハイブリッドなのか、ＥＶなのか、どちらにいくのだろう。半導体の供給もおちついてきたなか、新型車をどんどん発表している。遅れているＥＶ化、世界のトヨタがこのまま、負けていくとは到底思えない。さあ、熱いトヨタの開幕だ。",
		nickname: '向日葵',
		c_name: 'トヨタ自動車',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 128612,
		color: "red",
		title: '嫌な顔せず集荷してくれて感謝',
		comment: "いつもお世話になっています。小さなものでも嫌な顔せず集荷してくれて感謝しています。",
		nickname: 'kirari',
		c_name: 'ヤマトＨＤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 132243,
		color: "orange",
		title: 'ヤマトのドライバーさんは、皆んな良い人',
		comment: "ヤマトのドライバーさんは、皆んな良い人ですね。これからはホワイト物流の時代となる一方、通販、産直の需要は益々伸びることでしょう。農家さんや漁師さんも直接消費者と繋がって働き方改革も進むことが予想されますので、物流のニーズは上がる一方だと思います。世の中の期待を背負って頑張れ！ヤマト！",
		nickname: 'トニー',
		c_name: 'ヤマトＨＤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 10144,
		color: "blue",
		title: '他社よりアプリが使いやすい',
		comment: "日本郵政や佐川よりもアプリ使いやすくて再配達便利です。いつもありがとうございました。またよろしくお願いします。",
		nickname: 'ねこたま',
		c_name: 'ヤマトＨＤ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130625,
		color: "red",
		title: '快適に過ごせるように',
		comment: "職業柄、仕事中トイレに行けない方もおられます。例えばコロナ最悪期に、集中治療室ナースはオムツをつけて看護しておられたそうですかっこ良くて臭わない大人用紙オムツがあれば良いのに、と思いますトイレに行けないお子さんのお困りで、心を痛めている親御さんのコメントもお見受けしますギャザーのデザインひとつでイメージも変わると思いますどんな条件でこの世に生まれてきても、その人なりに快適に過ごせるよう。衛生商品トップメーカーだから出来る社会貢献もあるはず素敵な新商品を期待しています🥰",
		nickname: 'ペロポネソスで会いましょう',
		c_name: 'ユニ・チャーム',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 132254,
		color: "orange",
		title: 'Pontaポイントを、ポイント投資',
		comment: "毎日お世話になっています！惣菜、スイーツ、何でも美味しくて大好きです。Pontaポイントを集めているので、積極的に使っています。集めたポイントで、こうしてポイント投資。これからも、よろしくお願い致します！☺️",
		nickname: 'きゃめる・ごんざれす',
		c_name: 'ローソン',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129683,
		color: "blue",
		title: 'フードロス削減商品を良く購入',
		comment: "値下げシールの付いたおにぎりやウチカフェスイーツを良く購入します。フードロス削減かつお財布に優しいので、今後もどんどん利用していきたいと思います。",
		nickname: 'スイミー',
		c_name: 'ローソン',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131186,
		color: "red",
		title: '満足感あるバスチークリームのせ',
		comment: "感謝還元チャレンジ創業祭で、バスチークリームのせを買いました。普通のバスチーに比べると大きくてクリームたっぷりで満足感ありました。この美味しさとボリューム、おまけに50円引き、さすがローソンさんです！これからも応援してます♪",
		nickname: 'ぶろんちゃん',
		c_name: 'ローソン',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 131376,
		color: "orange",
		title: '子供と取り合いになるからあげクン',
		comment: "ローソンのからあげクン、子供とは取り合いになりながらも？大きさ的にもちょうどよくて食べやすくて美味しいので大好きです！",
		nickname: 'むー',
		c_name: 'ローソン',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129291,
		color: "blue",
		title: '負けないで頑張って',
		comment: "ホリエモンが楽天やばいとか騒いでるようですが、そんなのに負けないで頑張ってください。楽天グループのサービスいっぱい使って応援してます。",
		nickname: 'シナたん',
		c_name: '楽天グループ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 129437,
		color: "red",
		title: '大きな経済圏を対抗できてるのは楽天くらい',
		comment: "モバイルの不調とか海外の攻勢とかもあるけれど、日本の企業で大きな経済圏を対抗できてるのは、楽天くらいと思うと、応援したくなる。国内での収入だけでなく、海外の市場から安定した収益が出せる知見もありそうだから、存続と拡大に向けて応援したい！！！",
		nickname: 'そっしー',
		c_name: '楽天グループ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},

	{
		id: 130318,
		color: "orange",
		title: '素晴らしく努力家！',
		comment: "「楽天**」素晴らしく努力家❗大企業にしてサービス満点要素を兼ね備え、その上*喜怒哀楽*のツボを知り尽くして居られる😃💯「学び×100💮」ワタシは自分の小ささを繊細に感じました😢期待大です。応援致します。😃✌️",
		nickname: 'ミートくん',
		c_name: '楽天グループ',
		marks: [],
		favs: 0,
		my_mark: -1,
	},


]
