let data = [
[152381,"UBER","red","ウーバーお願い！","「ウーバーお願い」と言えば、こどもが物を取って来てくれます","さくらさん","ウーバー・テクノロジー"],
[152006,"DIS","orange","プリンセスが大好き！","娘がエルサやラプンツェル大好き、お弁当箱や水筒、通園バックもプリンセスたちにお世話になってます。ミッキーやミニーの服もお気に入り、いつも娘の通園を助けてくれてありがとう！","あるぱか","ウォルト・ディズニー"],
[150635,"u7564","blue","大学生も絶賛","大学生の息子がワークマンが大好きで、中学生の頃からTシャツや上着など買ってました。安いのに丈夫だし、着心地もよく夏は涼しく、冬は暖かくていいと絶賛してます。","しろくま","ワークマン"],
[150729,"u8022","red","プレスサーモ","冬になり、外出する際に、プレスサーモ製の衣料は、とても重宝しています。願わくば、プレスサーモや、ドライベクターを、使って、５２シリーズで、スーツを、作ってくれると、嬉しいです！","たくさん","ミズノ"],
[150962,"BRK-B","orange","敬意を表します","右腕のマンガーさんがお亡くなりになりました。運用業界で燦然と輝くトラックレコードをともに作られたことに敬意を表したいと思います。R.I.P.","ぼんちあげ","バークシャー・ハサウェイ"],
[151919,"BRK-B","blue","バークシャーは！","コカコーラは保有してませんが、バークシャーは保有してます！","あきおパパ","バークシャー・ハサウェイ"],
[149676,"KO","red","当たりました！","今さっきコークオンアプリでジョージアのペットボトル当たりました！いつもすてきなキャンペーンありがとう！元をたどればコカ・コーラ社さんのおかげです！これからも応援していきます！","サーティ","コカ・コーラ"],
[152224,"u8316","orange","素敵なサービスに期待","三井住友カード使ってます。お得なカードなのでこれからも使います！これからも素敵なサービスを期待してます。","KJ","三井住友フィナンシャルグループ"],
[151139,"u8410","blue","100株！","コツコツ、コツコツ…買い増し続け、100株❗️単元株達成‼️微々たるものではありますが、これも一重にSTOK POINTさんのお陰です😊感謝❣️今後もコツコツ、コツコツ…次は、200株‼️","ルナルナ","セブン銀行"],
[152279,"t4755p3","red","応援したくなる","難しいと言われていることに挑戦する姿は応援したくなります。","たろすけ","【推しカブ】楽天グループ"],
[149651,"NVDA","orange","ソダテがレベル30！","遂にソダテがレベル30になりポイント投入✨最近は機械学習やディープラーニングも実行しているので、高性能なPCを初めて買おうと思います！お世話になります|* •̀ω•́ )ﾉ|Ю ｶﾞﾁｬ","しゅば","NVIDIA"],
[150736,"u2503","blue","初の株式交換","初の株式交換させて頂きました！","とと丸","キリンホールディングス"],
[151679,"u5020","red","配当いただけた！","未満株でも配当いただけた！ありがとうございます！","梅","ＥＮＥＯＳホールディングス"],
[150004,"u9104","orange","封筒のデザインがおしゃれ","応援しています。優待券を使う機会がなかなか無いのですが、お知らせの封筒のデザインなどがおしゃれでとても好きです。株価上昇も期待しています。","ゆきこ","商船三井"],
[151082,"u7267","blue","目指せ100株","分割されたのか、100株目指すぞ。","百目鬼","ホンダ"],
[151130,"u9843","red","道民としては","道民としては、頑張って欲しいところですよね。すっかり株価上昇して手が出ませんが！","みつこぶらくだ","ニトリＨＤ"],
[150095,"u7453","orange","商品から企業へ","今までは無印良品の「商品」のファンでした。先日、個人投資家向けのIR動画を拝見して、良品計画という企業のファンになりました！経営理念に共感でき、第二創生を見守り応援し続けたいと心から思います。もちろんお買い物はシェアホルダーカードで^ ^","まめねこ","良品計画"],
[150980,"u9432","blue","一気に1300株！","株式が２５分割は衝撃がありました。分割前に単株で52株買ったら一気に1300株になりました。","ゼット","日本電信電話"],
[151912,"TSLA","red","かっこよすぎた...","Cybertruck…かっこよすぎた…Langley BC で見たけど…欲しい！！！","Sarah","テスラ"],
[150836,"u7270","orange","バケモノですね","R2に乗ってます4気筒スーパーチャージャー4輪独立サス軽のバゲモノですね","あ～る","ＳＵＢＡＲＵ"],
[151597,"u7201","blue","もうすぐ定年","父が日産を、もうすぐ定年です長らくお世話になりました","がき","日産自動車"],
[150634,"u6902","red","退職しても","退職してますが、株式だけは持っています…頑張れ！","アタルくん","デンソー"],
[151195,"IRBT","orange","阪神優勝","ジョーシンの阪神優勝セールで購入。85歳になる膝関節の悪い老母も掃除が楽になったと喜んでいます。家建てた20年前はルンバの存在知らなかったけど、バリアフリーにしておいてよかったとつくづく思います。","ルンルンルンバ","アイロボット"],
[150660,"u6645","blue","見守りを助けてもらってます","スマートウォッチやデバイスもとても優秀。離れて暮らす両親の見守りを助けてもらっています。これからもよろしくお願いします","はり","オムロン"],
[150941,"u4901","red","年賀状","年賀状の時期ですね。昔から年賀状のCM好きでした。","ラッキーママ","富士フイルムホールディングス"],
[150607,"u9201","orange","有効期限","JALマイルに有効期限が無くなったのが嬉しい☺️","おぎ","ＪＡＬ"],
[150626,"u1377","blue","オンラインショップ","サカタのタネオンラインショップいつも利用させてもらっています。","黒猫","サカタのタネ"],
[151935,"u2811","red","トマトの苗","カゴメさんからいただいた、トマトの苗が冬の今でも、トマトの実がたくさんなっている。市販のトマトの種や苗では、こんなにもたない。流石、カゴメさんだと感心しています。凄い。","向日葵","カゴメ"],
[150834,"u4452","orange","塩味の歯磨き粉","つぶ塩！隣に塩味の歯磨き粉もう一つあるけど、花王のつぶ塩はフッ素入り！つぶ塩使っている人いる ?もう甘いハミガキには戻れない","ぴーんっぽ","花王"],
[152344,"u4921","blue","20年以上","20歳の頃から20年以上洗顔はファンケルです。これからもお世話になります。","むう","ファンケル"],
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

