<template>
<div class="main">

	<div class="bgPink">

		<div class="vol">
			Vol.<span>5</span>
		</div>
		

		<ul>
			<li v-for="(l, i) in list" :class="l.color">
				<Item :l="l" :isImg="i == 0" @mark="markComment" :imgUrl="imgUrl" />
			</li>
		</ul>


		<img class="closeBtn" :src="cdn + '/img/rensai/diary/btn_close.png'" @click="back">



		<div class="MVWrp">
			<img class="back" :src="cdn + '/img/rensai/diary/MV_back2.png'">
			<img class="MV" :src="cdn + '/img/rensai/diary/MV.png'">
		</div><!--MVWrp-->

		<img class="bottom" :src="cdn + '/img/rensai/diary/bottom.png'">


	</div><!--bgPink-->





</div>
</template>

<style scoped>
*{
	font-family: 'ZenMaruGothic', sans-serif;
	font-size: 16px;
	font-weight: 300;
}
span{
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
}
.bgPink{
	background-color: #FDF8F9;
	min-height: 100vh;
	position: relative;
	margin-bottom: 100px;
}

.vol{
	width: 70px;
	height: 70px;
	border-radius: 35px;
	line-height: 70px;
	margin: 30px auto 0 auto;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	color: white;
	background-color: #7FBFF5;
}
.vol span{
	font-size: 20px;
}


.MVWrp{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 150px;
}
.MVWrp .back{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.MVWrp .MV{
	position: absolute;
	width: 80px;
	top: 20px;
	left: 20px;
}

.closeBtn{
	position: fixed;
	top: 20px;
	right: 20px;
	display: block;
	width: 48px;
	cursor: pointer;
}

.bottom{
	width: 100%;
	height: 150px;
	object-fit: cover;
	object-position: center top;
}
</style>


<script>
import {post} from '../../../../lib/utils'
import Item from './Item.vue'
import {list} from './diary5_list'

export default {
	data: function(){
		return {
			cdn: CDN,
			list: list,
			imgUrl: '/img/rensai/diary/vol5.png',
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		back: function(){
			location.href = '/rensai/diary'
		},

		getFavs: async function(){
			let d = {
				ids: this.list.map( (m) => m.id),
			}
			const res = await post('/webapi/client/comment/list_favs', d)
			if(res.code == 0){
				for(let i=0, l=this.list.length; i<l; i++){
					const id = this.list[i].id
					if(res.list[id]){
						this.list[i].marks = res.list[id].marks
						this.list[i].favs = res.list[id].favs
						this.list[i].my_mark = res.list[id].my_mark
					}
				}
			}
		},


		markComment: async function(params){
			let d = {
				id:   params.id,
				mark: params.mark,
			}
			const res = await post('/webapi/client/comment/fav', d)
			if(res.code != 0){
				console.error(res.message)
				return false
			}
			for(let i=0, l=this.list.length; i<l; i++){
				if(this.list[i].id == params.id){
					if(this.list[i].marks.length < 3) this.list[i].marks.push(params.mark) 
					this.list[i].favs ++
					this.list[i].my_mark = params.mark
					break
				}
			}
		},

	},

	components: {
		Item,
	},
}
</script>
