let data = [
    [165694,"u9020","orange","祝！北陸新幹線延伸!!","祝　北陸新幹線延伸。GW 行きたいけど、まだチケット取れないだろうなぁ。","M","ＪＲ東日本"],
    [166302,"u9202","blue","インバウンドの波","いよいよ羽田空港第2ターミナルからの海外線ですね。インバウンドの波に乗って大いに羽ばたいてください。","マスタード","ＡＮＡＨＤ"],
    [165243,"u9603","red","海外旅行が大好き","日本人は旅行が好き、それも海外旅行が大好きなので、期待しています。バブル景気の中で、外国で爆買いする日本人を楽しみにしてます。変なホテルは、コンシェルジュがＡＩだったらいいなと思いました。フロントは、人がした方がいいと思います。","白楽天","エイチ・アイ・エス"],
    [165830,"u6594","orange","ニデック京都タワー","「京都タワー」が、「ニデック京都タワー」になるなんて、かっこよすぎ！故郷京都のシンボルが、よりワクワクするシンボルとなるよう、期待しています!","ただっち","ニデック"],
    [165378,"u7731","blue","愛される名機","父の形見のF2で色んな所に撮影に行きました。寒冷地での撮影で他の人たちが使っている最新鋭の機種が軒並み動かないなか、機械式の強みかなんの問題もなく撮影出来ました。これからも長く愛される名機を作り続けてください。","たけし","ニコン"],
    [166667,"u7203","red","ハイブリッド","トヨタのEVに偏らずハイブリッド車を開発し続ける姿勢は、とても良いと思います。アメリカでは寒さに弱いEVに沈痛な叫びが聞かれるようですから。","ハッピードラゴン","トヨタ自動車"],
    [165583,"t2897p3","orange","忘れられない","なんと言っても手軽に美味しく食べられるのがすごい！子供の頃、寒いときに野球場で食べたカップラーメンは忘れられません！新しい味を期待してます！","black3","【推しカブ】日清食品"],
    [166145,"KO","blue","昔の味","昔飲んだメローイエローがまた飲みたいです。","ひろくん","コカ・コーラ"],
    [165554,"JNJ","red","重宝してます","キズパワーパッド、切り傷や擦り傷ができた時には重宝してます。子供が産まれてから、沢山こちらの製品にお世話になってます！","ぺんぎん","ジョンソン・エンド・ジョンソン"],
    [165293,"u9684","orange","信じてます！","ドラクエは堀井先生だけになっちゃって今後が不安だけど、12以降の正規シリーズをずっとリリースしてくれると信じてます！","ゆーく","スクエニＨＤ"],
    [165229,"u6460","blue","初任給で","セガのハード、今でも大好きです！初任給でマークIIIを買いました。もう一度ゲーム機を作りませんか！応援しています！","t-8100","セガサミーＨＤ"],
    [166156,"u2229","red","子供たちが嬉しそう","サッカー観戦に行くと、カルビースマイルタイムというイベントスポンサーをしてくれていて、当たった子供達が嬉しそうにはしゃいでいる姿を見せてくれるので、これからもカルビーさんを応援したいです♪","MINO-G","カルビー"],
    [165371,"u2229","orange","変わらないでいてください","広島から応援してます!価格は高くなってるけど味は変わらないでいてください。じゃがりこ信者","ぐめ","カルビー"],
    [165927,"u2270","blue","地元企業","小さな頃から、地元企業でもあり、牛乳やチーズは雪印さんでした。高校生の学校祭では、アイスクリームの仕入れなど大変お世話になりました！今でも感謝しております！","サブ","雪印メグミルク"],
    [166663,"u7532","red","お世話になってます！","ドンキの焼き芋とツナ缶にいつもお世話になっています！","にやわー","パン・パシフィック・インターナショナルホールディングス"],
    [165374,"u4902","orange","期待大！","プラネタリウム凄い！期待大です！","ガチョウ","コニカミノルタ"],
    [165246,"u9602","blue","これから","『ゴジラ−1.0』アカデミー賞視覚効果賞の受賞おめでとうございます。実のところ私は、まだ観ていません。これから観に行こうと思ってます。","ゴジラ","東宝"],
    [165600,"t8306p3","red","毎日","株投資に興味が出てきたので毎日見て勉強しています。","ふふより","【推しカブ】三菱UFJ"],
    [165866,"u8411","orange","のびしろ","ついに本業で稼ぐ時代に戻りましたね。のびしろしかな〜い！","みちん","みずほフィナンシャルグループ"],
    [165886,"u8750","blue","マイナス金利解除","マイナス金利解除、金融機関にとってプラスに働くといいですね。","ＱＮＸ","第一生命ＨＤ"],
    [166362,"u9005","red","次世代へ","結婚して田園都市線沿線に引っ越して約30年。厳しい時期もあったけど復活！今月は日本経済新聞で私の履歴書で歴史を改めて勉強し、ますます応援したくなりました。想いを次世代へ紡ぎ続けて欲しいです","トミー","東急"],
    [165358,"u2294","orange","国産牛","スーパーだと、海外の安い肉をよく見ますが、自分が小さい頃は、もっと国産牛がメインで並んでたイメージ。価格帯は変えなくてもいいと思うが、馴染みやすいイメージ展開をして、国産が賑わってほしい◎","ダイ","柿安本店"],
    [165888,"u2819","blue","といえば","焼肉といえばエバラっす！","レイ","エバラ食品工業"],
    [166497,"u9502","red","逆に","契約に関して電話したら、「30分後に対応できます」と言われ、逆に恐縮してしまった","マサ","中部電力"],
    [166093,"u6701","orange","懐かしのフレーズ","バザールでござーる分かる人はいますか。あのときのジャパンパワーを見せてくれ","どすこい","ＮＥＣ"],
    [166604,"u3660","blue","選べるサンプル","アットコスメのアプリはポイントも貯まりやすいし、ボーナスの時期には、30％ポイント還元をしていたりするので、百貨店ブランドの化粧品とかを貯めたポイントと、以前買った物の還元ポイント、そして株主優待の割引券を使って購入しています(*^^*)選べるサンプルセットもつけてくれたりするので、とても嬉しいです!!これからも応援しています！","Lucas","アイスタイル"],
    [165906,"u4927","red","欲しかった美容液","今回、3年以上保有したことにより、株主優待でいただけるポイントが増えました。そのおかげで、妻が欲しかった美容液を選ぶことができました。去年の春から夏にかけて株価が上がった際に売れば良かったと思っていましたが、妻の喜ぶ顔を見て、所有していてよかったと心から思いました。これからも保有しながら、応援します。","きんちょん","ポーラ・オルビス"],
    [165851,"u2201","orange","いい調子","お肌によいかなと思って飲みはじめた森コラのおかげか、加齢に伴う関節の不具合がいつの間にかいい調子になったのにはとても感謝してます！","うにごろー","森永製菓"],
    [165716,"u3092","blue","お揃い","先日、こちらで持っているカーディガンとお揃いのマフラーを見つけて購入しました。なかなかみつからないものなので感謝です。","らん","ＺＯＺＯ"],
    [166588,"u9843","red","メジャー","カーテンを新調しようとニトリに行ったらカーテンを測るメジャーがありました。家にあるメジャーだと測りにくかったので、レールにひっかけて測れるので便利でした！","ぶろんちゃん","ニトリホールディングス"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })