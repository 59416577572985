let data = [
[142706,"u2305","orange","孫と一緒に写真撮影","孫が出来たので一緒に写真を取りに行きます。","柊ボー","スタジオアリス"],
[144103,"u2305","blue","次は入学式","息子の七五三では、お世話になりました。次は入学式頃かなぁ","ぶんた","スタジオアリス"],
[143049,"u7867","red","プラレール卒業","うちの子どもはプラレール卒業しましたが、きっと次は孫に？いつの時代も子どもに夢与えるものだと思います。応援してます！","マッキー","タカラトミー"],
[143356,"u7867","orange","大人になっても大好き","トミカのお仕事シリーズは大人になっても大好きです。これからも頑張って下さい。","小夏","タカラトミー"],
[141504,"u9684","blue","ドラクエの虜","30年以上前、小学生の頃、ホントにファミコンやドラクエの虜でした。","Mas","スクエニＨＤ"],
[144317,"u9684","red","スクエニと過ごした学生時代","学生時代をスクエニと過ごしました。思い出のある作品ばかりです。応援してます！！！","こが","スクエニＨＤ"],
[144022,"u2809","orange","今もあだ名はQP！","マヨネーズはここ。小学生時代のあだ名はQP！50を過ぎても友人にはあだ名で呼ばれます（笑）","qp","キユーピー"],
[143970,"u2809","blue","炒め物にもマヨネーズ","私もマヨネーズと言えば､キューピーです。かける､あえるだけじゃなく､炒めるにもいいんですね♪  美味しい‼️これからも応援します。","くうちゃん","キユーピー"],
[144531,"u7581","red","結婚してください","その日の気分によってパスタやピザ、ドリアを選ぶわけですが、毎回テーブルに乗るもの、それは青豆の温サラダ。大好きです。結婚してください。","Laz","サイゼリヤ"],
[144612,"u7581","orange","ヒロロ店","弘前ヒロロ店、9月の開店から毎日老若男女で賑わっております！","ちぐら","サイゼリヤ"],
[143709,"u2831","blue","シーチキンマヨ","シーチキンにマヨネーズかけて食べると、ごはん何杯でもイケる","全力屋","はごろもフーズ"],
[144712,"u2804","red","チャーハンに中濃ソース","ソースも地域性がある様ですが家では昔からブルドックの中濃ソースでした(青森県在住)。チャーハンを味薄めで作り中濃ソースをかけて食べるのが好きです。","シュン","ブルドックソース"],
[144370,"u8194","orange","美味しいお寿司","他のスーバーに比べお魚が新鮮でお寿司が美味しいのでいつも利用させて頂いております。","だはん","ライフコーポ"],
[141559,"KO","blue","スカッとさわやか","コカ・コーラはいつも僕らと共にあるよね。楽しい時、悲しい時。スカッとさわやか。勇気をくれる。","ととし","コカ・コーラ"],
[143746,"u2579","red","買ってしまいます","ドクターペッパーが自販機にあると買ってしまいます。","ケイ","コカ・コーラボトラーズジャパン"],
[144609,"u2587","orange","CMが面白すぎる","ムトゥレモンのCM面白すぎて。見るたびに笑っちゃいます。インパクトあっていいです。応援してます。","よーさん","サントリー食品"],
[143403,"u2587","blue","相変わらず美味しい","CCレモン久しぶりに飲んだけど相変わらず美味しかった！","CGC","サントリー食品"],
[143777,"u6952","red","壊れないのが悩み","電卓も、電子辞書も、時計もカシオです。壊れないことが悩みです。","しおん","カシオ計算機"],
[143809,"u6952","orange","今も動いている目覚まし時計","30年くらい前だったと思いますが、小学生の時に景品で貰ったワールドクロック目覚まし時計がまだ動いています。選ぶ時、箱を開けた時にワクワクしたのを思い出します。これからもそんな商品を世に出していただきたいと願っています。","ポンタ","カシオ計算機"],
[143928,"u9201","blue","笑顔での接客","グローバルクラブ会員です。いつもチェックインカウンター、搭乗口、機内で明るく笑顔での接客ありがとうございます。出張が楽しくなります。","のりへー","ＪＡＬ"],
[143794,"u9020","red","神です","時刻表作っている人は神です。","妻用事","ＪＲ東日本"],
[142095,"u9433","orange","対応がよかったです","携帯が壊れたときの対応が良かったです。たしかサービスプランかなにかに入っていたおかげだと思うのですが、無料で同一機種に交換してくれるとは。いまはどこもそうなのかしら。これからは大切に扱います。","ぱよちん丸","KDDI"],
[142278,"u9433","blue","ずっとauユーザーです","auになる前から、auユーザーです(笑)これからも使い続けるので応援しています。","みこたん","KDDI"],
[143318,"u9433","red","auマネ活で値下げから投資へ","「auマネ活」、「値下げから投資へ」とのことで、今度の活躍に期待が大きいです。お財布にやさしいプランも増えると良いなって思います","ぴーなつ","KDDI"],
[144634,"u9433","orange","TORQUE愛用者","京セラのTORQUE愛用者なのでKDDI(AU)を応援しています！","さささん","KDDI"],
[143090,"u4385","blue","まとめ買い機能","まとめ買い機能を作ってもらって感謝です！！また便利な機能を期待しています(^^)","りんご","メルカリ"],
[144057,"u6752","red","ナショナル時代から","長年利用した貴社（当時はナショナル）オープン電子レンジにお別れをしました。トラブルもなく23年、共働きの我が家の大きな戦略でした。ありがとう。","きむ","パナソニックホールディングス"],
[141855,"u6301","orange","オリジナルミニチュア","株主優待制度でオリジナルミニチュアがいただけるそう…目標に少しずつ投資したい！","かるは","コマツ"],
[144120,"u7270","blue","フラットエンジン製造は世界で２社","世界に2社しか製造していない自動車用のフラットエンジンを製作するメーカー。3台ほど乗り継ぎました。個性のある自動車メーカーです。","うがっち","ＳＵＢＡＲＵ"],
[143069,"TSLA","red","ネットでポチッとテスラ購入","テスラを調べると、ネットでポチッと購入可のようで、時代を感じます。","だんだん","テスラ"]
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

