let data = [
    [152751,"AAPL","orange","自分で自分に","クリスマスプレゼントにはじめてのアップルウォッチもらいます！ほんとは自分で自分にあげるだけですが…","わーたろう","アップル"],
    [153403,"AMZN","blue","半値で買えた","ブラックフライデーで半値で買えたのでありがとう。","あははは","アマゾン ドットコム"],
    [152534,"IRBT","red","寝てる間に...","いつもルンバでお世話になってます子供が小さいとテーブルの下とかゴミが落ちたりしますが、寝てる間に綺麗にしてくれていて助かっていますありがとうございます","MJ13","アイロボット"],
    [152891,"IRBT","orange","3台編成","裕福な友人が大きな家を建てた。「掃除が大変そう」と言うと。「ルンバ３台あるの！」って。大活躍ですね。","石部金吉","アイロボット"],
    [152890,"NKE","blue","好きです！","かっこいいです…好きです！","HIROROCK","ナイキ"],
    [153188,"NKE","red","上がり続けて","エアマックス最高です。株価はこのまま上がり続けて下さい！","ちゃる","ナイキ"],
    [152923,"AXP","orange","ヘビーユーザーになります！","アメックスユーザーになりました。やはり海外での安心感、ポイントが魅力で、ヘビーユーザーになります！","akr","アメリカン・エキスプレス"],
    [152505,"u8253","blue","もっとアピール","永久不滅ポイント、名前が明快で良い！期限を気にしなくていいのはこのせちがらいご時世にありがたいし、もっとアピールしてもいいのにと思う。頑張ってください！","yumyum","クレディセゾン"],
    [152734,"u7453","red","神のカード","MUJIカードは神カードです。年会費無料なのに持ってるだけで毎年1000円相当、誕生月にMUJIでカード決済or提示すれば更に500円相当のポイントが頂けます。カード発行会社はクレディセゾンですが、こんなに手厚いポイントプログラムは他社にはありません。どの商品も品質が良くて、応援しないわけがありません！","マドうめたろう","良品計画"],
    [152590,"u8267","orange","有能ですね","株主優待カードが現金還元以外にもいろいろ割引きあるので有能ですね。","エビ","イオン"],
    [153219,"u8200","blue","野菜を好きに","野菜を好きになったきっかけ応援してます","ぺぺぺ","リンガーハット"],
    [153347,"SBUX","red","どれも美味しい！","新作がドンドン出て、どれも美味しいんですよねー豊かなひとときをありがとうございます","てぃーたいむ","スターバックス"],
    [152452,"u2590","orange","チャレンジ","子供がさつまいもミルクを探して飲んでます。チャレンジすると美味かったみたいで嬉しそうに飲んでます。また何かチャレンジするもの出るかな⁉️楽しみに待ってます。","芳輝煌","ダイドーグループ"],
    [153072,"DPZ","blue","月一","家族みんな大好きで月一頼んでます。","かず","ドミノ・ピザ"],
    [153430,"u2501","red","サクラビール","2024年1月に新発売の『サクラビール』が楽しみ！『黒ラベル』は昔ながらのテイストを残した定番であり続けている。この復刻も期待大です！","スミス","サッポロホールディングス"],
    [152489,"u7780","orange","超ドドド近眼","コンタクト無しで暮らせない超ドドド近眼です。コンタクトの種類もケア製品も色々できてきて助かっています。","hanana","メニコン"],
    [152759,"u3861","blue","次のポイント投資は...","三菱UFJが100株になったので、次のポイント投資は王子H Dを考えています。我が家はネピネピ好き❤️ですし、社会貢献もしっかり頑張っている会社だし、応援してます。","★Gon！","王子ＨＤ"],
    [153690,"u4502","red","助けられてます","武田のお薬に父が助けられています。感謝してます。どんどん新薬作って欲しいです。","はやな","武田薬品"],
    [152792,"u6752","orange","値引きが無いのも","最近冷蔵庫を買い替えました。値引きがないのもより良い商品開発のため。さらなる発展に期待しています☆","ひまお","パナソニックホールディングス"],
    [153340,"u6367","blue","ぴちょんくん","ぴちょんくんシリーズ好きですまたぴちょんくんカーと巡りあいたいな","ケロすけ","ダイキン工業"],
    [152782,"u8088","red","産業時代","卓上コンロでお世話になってます。これからは水素が活躍する産業の時代なのでこれからも宜しくお願いします！","よぴ","岩谷産業"],
    [153643,"u4825","orange","降り始め予報","雨の降り始めの時間の予想では、いつも便利に利用させていただいてます。","ノッキー","ウェザーニューズ"],
    [153610,"u9020","blue","全席指定化","年末年始の新幹線の全席指定席化、ずっと待ち望んでいました。過剰な混雑や混乱を避けるための英断に応援しています。","tkblueheaven","ＪＲ東日本"],
    [152705,"u9041","red","快速急行","神戸三宮駅から奈良駅への快速急行、とても便利ですありがとうございます","そうま","近鉄グループホールディングス"],
    [153669,"u9603","orange","希望します！","安い海外航空券の提供を希望します。","クエチョコ","エイチ・アイ・エス"],
    [152712,"u3099","blue","特別食堂","日本橋の特別食堂大好きです。応援しています。","NKJM2000","三越伊勢丹"],
    [153055,"t6861p3","red","セミナー出てます！","キーエンスのセミナー出てます！凄い企業です。応援してます。勢い取り戻して！","くろねこ","【推しカブ】キーエンス"],
    [152717,"u3436","orange","日本の宝","超高度な技術こそ日本の宝","masazou","ＳＵＭＣＯ"],
    [152974,"t8058p3","blue","比較して","他の大手商社と比較して、対応の良い営業マンが多い印象です。","まるめ","【推しカブ】三菱商事"],
    [152744,"t8058p3","red","3分割！","いよいよ3分割！手に届く価格になり、さらなる飛躍を期待してます😁","さとＣ","【推しカブ】三菱商事"],
    ]
    export const list = data.map( (d) => {
            return {
                    id:       d[0],
                    c_code:   d[1],
                    color:    d[2],
                    title:    d[3],
                    comment:  d[4],
                    nickname: d[5],
                    c_name:   d[6],
                    marks:    [],
                    favs:     0,
                    my_mark:  -1,
            }
    })