<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	短期投資コラム
</div><!--head-->

<h1>第3回<br>
	超短期投資手法その2<br>
	「市場の間違いを見抜く」<br>
	変則型逆張り投資とは</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<h2>市場の間違いや行き過ぎを<br>投資チャンスとして狙う</h2>

<pre>市場に参加している投資家は「完璧」な人ばかりではないので、株価は<span class="blue">必要以上に高値まで買い上がったり、必要以上に安値まで売り崩したり</span>することがあります。

そして、このような市場の間違いは、<span class="blue">多くの場合1〜3日ほどで修正されます。</span>
この修正されるまでの間にリターンを狙うというのが、この投資手法になります。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>リターンの源泉は
<p><span class="l uline">不完全な投資家が犯す間違いや</span>
<span class="l uline">行き過ぎな状態</span></p>
このリターンが再現する理由は
<p><span class="l uline">このような間違いは、数日の間に</span>
<span class="l uline">修正されるものであるから</span></p></pre>

	<hr class="down">
</div><!--point-->


<h2>具体的な例で見てみましょう</h2>

<pre>例えば、トヨタ自動車（7203）の最近のチャートです。
</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/nobu3/chart1.png'">

<pre>トヨタ自動車は、8月1日の引け後に第１四半期の決算が非常に好調と発表しました。
この好決算発表から、<span class="blue">トヨタの翌日の株価は急騰するだろう！</span>と多くの人が考えました。

しかし、実は確かに内容は好決算だったのですが、その中に、
　　中国での販売台数が減少
　　米国市場ではシェアを落としている
など、<span class="blue">やや今後に懸念される内容も含まれていました。</span>

ここまでを冷静に分析できれば、
　<span class="blue">・この決算の評価は覆る可能性が高い</span>
<span style="display: inline-block; width: 100%; text-align: center;">↓</span>
　<span class="blue">・そうなると、決算発表前の水準に株価はすぐに落ち着く可能性が高い</span>
ということが予想できるわけです。

まさにここが、市場の間違い／行き過ぎを探すという行為なのです。</pre>

<h2>短期においては、この手法は結構一般的</h2>
<pre>トヨタ自動車の例で紹介しましたが、日々発表される経済指標に対しても株式市場は様々な反応をします。その中には、<span class="blue">早とちり的な間違い</span>が結構見られます。

最近だと、

　消費者物価指数が予想より低かった
<span style="display: inline-block; width: 100%; text-align: center;">↓</span>
　金利敏感株が上昇する
<span style="display: inline-block; width: 100%; text-align: center;">↓</span>
　実際には消費者物価指数のコアコア指数（食料とエネルギーを除いた物価指数）は高止まりしていた
<span style="display: inline-block; width: 100%; text-align: center;">↓</span>
　金利敏感株が売られる

といったことです。
こういう状況を素早く見抜くことができれば、変則型逆張り投資でリターンを得ることができるのです。

どうでしょうか？

皆さんも、市場の間違いを見抜く力があれば、短期間にリターンを得ることができます。

ただし、この手法は、<span class="blue">普通の多くの個人投資家には難しいと思います。</span>
また、こういう見抜く力を持つ投資家が増えてくると、リターンも出しにくくなり、別の観点からの見抜く力が必要になる、ということもあります。

また、最近はシステム化された投資マネーが大勢を占めていますので、<span class="blue">システムのスピードには負けてしまう可能性も高くなります。</span>

つまり、こういう手法で儲けられる投資家は、様々な窮地を何度も乗り越えてきた猛者なのです。そして、スーパー個人投資家として君臨する世界で、そのサバイバルはかなり過酷なように思います。

短期投資の手法をご紹介して来ましたが、やはり、</pre>

<div class="yellow" style="text-align: center; padding: 20px;">
	<span class="blue">シンプルな法則で再現性が高く資本主義の真理に基づくルールで投資をする、というのが個人投資家には合っているのではないでしょうか。</span>
</div>

<pre>皆さんはどんな投資手法が自分に合っていると思いますか？

次回は、変則的逆張り投資のもうひとつの例を「楽天」の場合でご紹介します。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre><p><span class="l" style="font-weight: bold;">短期投資で個人投資家が</span>
<span class="l" style="font-weight: bold;">確実にリターンを得るには</span>
<span class="l uline">・かなりの情報量と才能</span>
<span class="l uline">・見抜いてすぐに行動できる胆力
が必要</span></p></pre>

	<hr class="down">
</div><!--point-->


<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>変則型逆張り投資
「楽天」</pre>
	</div><!--next-->

</div><!--nextWrp-->

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #EF5C5C;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #EF5C5C;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
span.red{
	color: #EF5C5C;
}
span.blue{
	color: #2A78C6;
	font-weight: 600;
}
span.uline{
	background: linear-gradient(transparent 60%, #91C8FF 30%);
	font-weight: 600;
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #FCF2F2;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
	>hr{
		position: absolute;
		left: 0;
		width: 100%;
		border-bottom: 1px solid #1A1A1A;
		margin: 0;
		&.up{
			top: 5px;
		}
		&.down{
			bottom: 5px;
		}
	}
	>img{
		height: 30px;
		position: absolute;
		top: -15px;
		left: -2px;
	}
	>pre{
		font-weight: 600;
		>p{
			font-weight: inherit;
			width: 240px;
			margin-left: 70px;
			text-align: left;
			.l{
				margin-left: -20px;
			}
		}
	}
}

.grey{
	background-color: #FAFAFA;
	margin: 20px 0;
	padding: 20px 0;
}
.yellow{
	background-color: #FFF3C3;
	border-radius: 5px;
	padding: 20px 0;
	width: 335px;
	margin: 20px auto;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #F2CBCB;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #F2CBCB;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 30px 0 30px 0;
	max-width: 335px;
	>img{
		position: absolute;
		top: 0;
		left: 0;
		height: 30px;
	}
	>pre{
		text-align: center;
	}
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 11,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d, true)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
