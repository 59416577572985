<template>
<div class="banner">
<a :href="d.link" target="_blank">
	<img :src="$cdn + d.img">
</a>
</div><!--banner-->
</template>

<style scoped>
.banner a{
	background-color: transparent;
	border: none;
	height: auto;
	max-width: none;
	>img{
		min-height: 40px;
	}
}
</style>

<script>
export default {
	props: {
		d: {type: Object, default: () => {return {} }},
	},
	data: function(){
		return {
		}
	},
}
</script>
