var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main"},[_c('h3',[_vm._v("【利用規約】はじめにお読みください。")]),_vm._v(" "),_c('div',{staticStyle:{"margin":"10px"}},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('p',[_vm._v("当社でも、定期的に機械的あるいは専門チームがコメントのパトロールを行っていますが、もし、明らかに不適切な禁止コメントを発見された場合には、投稿の右肩から「投稿を通報する」のボタンがありますので、通報をお願い致します。")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('p',[_vm._v("＝＝＝＝＝＝＝＝")]),_vm._v(" "),_c('p',[_vm._v("\n\t\t\t禁止しているコメント例\n\t\t")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('div',{staticClass:"fixed"},[_c('a',{on:{"click":_vm.back}},[_vm._v("戻る")])])]),_vm._v(" "),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("「愛され企業サーチ」は、私たちが普通の日常生活の中から、"),_c('br'),_vm._v("\n\t\t・この企業には、いつもお世話になっている。"),_c('br'),_vm._v("\n\t\t・この企業の商品は、素敵、好き！"),_c('br'),_vm._v("\n\t\t・この企業の取組には、共感できる、応援したい。"),_c('br'),_vm._v("\n\t\tといった、普通の生活者の視点から、応援したい企業に応援メッセージを送る、というサイトです。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("投稿にあたり、参加しているみなさんが、心地よくご利用いただくために以下の規約を設けます。"),_c('br'),_vm._v("\n\t\t■投稿ができるのはストックポイントサービスの会員限定になります。"),_c('br'),_vm._v("\n\t\t■ストックポイントサービスの会員でない方は、参照のみとなります。"),_c('br'),_vm._v("\n\t\t■当社が認定していない方、あるいは法人による宣伝や荒らし行為は禁止します。"),_c('br'),_vm._v("\n\t\t　見つけた場合には、投稿削除等の対応を強制的に実施させていただく場合がございます。"),_c('br'),_vm._v("\n\t\t　→　禁止しているコメント例"),_c('br'),_vm._v("\n\t\t■「企業への応援メッセージ」と著しくかけ離れた投稿については、事務局で掲載削除いたします。"),_c('br'),_vm._v("\n\t\t■参加しているみなさんに心地良く、あるいはためになる、またはホッと暖かい気持ちになるような、投稿をお願い致します。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("このサイトは、皆さんで作り育てていく「企業愛」を基本として作られるサイトです。"),_c('br'),_vm._v("\n\t\t発言しやすいように尊重し合って語りましょう。"),_c('br'),_vm._v("\n\t\tまた、掲示板のコメントはすべて投稿者の個人的な判断を表する内容ですので、参考にされる場合はご自身の判断で対応お願い致します。"),_c('br'),_vm._v("\n\t\tよろしくご協力の程お願い致します。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n\t\t\t①個人情報を含む投稿"),_c('br'),_vm._v("\n\t\t\t②犯罪につながる恐れのある投稿"),_c('br'),_vm._v("\n\t\t\t③わいせつや暴力的な投稿"),_c('br'),_vm._v("\n\t\t\t④過度な批判や誹謗中傷、個人に対する攻撃や嫌がらせの投稿"),_c('br'),_vm._v("\n\t\t\t⑤差別的発言・不謹慎・配慮にかける投稿"),_c('br'),_vm._v("\n\t\t\t⑥関連性がない、意味がわからない投稿"),_c('br'),_vm._v("\n\t\t\t⑦明らかな偽の情報・いたずら・スパム投稿"),_c('br'),_vm._v("\n\t\t\t⑧明らかに他の会員の投稿の一部あるいは全部のコピー投稿"),_c('br'),_vm._v("\n\t\t\t⑨商用や宣伝目的と思われる投稿"),_c('br'),_vm._v("\n\t\t\t⑩当サイトのコンセプトと照らし合わせて掲載が適当ではないと思われる投稿"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n\t\t\t上記に当てはまる投稿を見つけた場合には、削除させていただきます"),_c('br'),_vm._v("\n\t\t\tまた、違反を繰り返される会員様には、当社の判断で即時投稿停止などの措置を取らせて頂く場合もございます。\n\t\t")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"40px 10px"}},[_c('p',{staticStyle:{"font-size":"12px","font-weight":"600"}},[_vm._v("運営会社")]),_vm._v(" "),_c('p',{staticStyle:{"font-size":"12px","font-weight":"600","color":"var(--col-black)"}},[_vm._v("STOCK POINT株式会社")])])
}]

export { render, staticRenderFns }