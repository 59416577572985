import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import List from './components/List.vue'
import Esg1 from './components/Esg1.vue'
import Esg2 from './components/Esg2.vue'
import Esg3 from './components/Esg3.vue'
import Esg4 from './components/Esg4.vue'
import Esg5 from './components/Esg5.vue'
import Esg6 from './components/Esg6.vue'

export default {
	components: {
		Header,
		List,
		Esg1,
		Esg2,
		Esg3,
		Esg4,
		Esg5,
		Esg6,
	},

	data: function(){
		return {
			cdn: CDN,
			railsenv: RAILSENV,
			isReady: false,
			comp: '',
		}
	},

	mounted: async function(){
		const id = this.$route.params.id
		this.comp = id ? 'Esg' + id : 'List'
		this.isReady = true

		setTimeout( () => {
			//初期化
			this.$refs.comp.init()
		}, 0)
	},

	methods: {
	},

}
