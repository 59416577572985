import { comma, get_products, get_user } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import ProductItem from '../../components/ProductItem/ProductItem.vue'
import MyComment from './MyComment.vue'
import CustomSort from './CustomSort.vue'

export default {
	data: function(){
		return {
			isReady: false,
			isSelectIcon: false,
			closingSelectIcon: false,
			myIcon: 1,
			nickname: 'ゲスト',
			oenList: [],
			products: {},
			commentList: [], //コメントリスト
			commentCount: 0, //コメント数
			page: 0,
			isMore: true,
		}
	},

	mounted: async function(){
		//銘柄データ取得
		let prdObj = await get_products(false)
		this.products = prdObj.obj

		//コメント取得
		await this.getComments()

		//ランキング取得
		let res = await this.$post('/webapi/client/ranking', {rank_type: 5}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.oenList = res.list.splice(0, 3)

		this.isReady = true
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name})
		},

		getComments: async function(){
			let cSort = this.$refs.cSort
			let d = {
				enc_aisare_id: this.$route.params.enc_aisare_id,
				sort: cSort.sort == 1 ? 'favs' : 'new',
				page: this.page,
			}
			let res = await this.$post('/webapi/client/comment/otherpage', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//nickname、アイコンを表示する
			this.nickname = res.nickname
			this.myIcon = res.my_icon

			//コメント数
			this.commentCount = res.count

			//10件未満になったらもっとみるを削除
			this.isMore = (res.list.length >= 10)

			//最初のページの場合は新規
			if(this.page == 0) this.commentList = []

			for(let i=0, l=res.list.length; i<l; i++){
				let c = res.list[i]
				c.product = this.products[c.c_code]
				if(c.product == null) c.product = {name: ''}
				this.commentList.push(c)
			}
		},


		changeSort: async function(){
			this.page = 0
			await this.getComments()
		},

		moreComments: async function(){
			this.page++
			await this.getComments()
		},

		exLink: function(url){
			window.open(url, '_blank')
		},

	},

	components: {
		Header,
		TopCap,
		ProductItem,
		MyComment,
		CustomSort,
	},

}
