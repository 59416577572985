<template>
<div>
<TopCap page="event" />

<h3>STOCKPOINTのサービス</h3>
<p class="notes">ポイント運用で「愛され企業」を応援しよう</p>

<div class="bnr">
<img :src="$cdn + '/img/main/event_original.png'">
<div class="app">
	<img :src="$cdn + '/img/main/btn_appstore.svg'" @click="appstore('original')">
	<img :src="$cdn + '/img/main/btn_googleplay.svg'" @click="googleplay('original')">
</div><!--app-->
</div>

<div class="bnr">
<img :src="$cdn + '/img/main/event_spc.png'">
<div class="app">
	<img :src="$cdn + '/img/main/btn_appstore.svg'" @click="appstore('spc')">
	<img :src="$cdn + '/img/main/btn_googleplay.svg'" @click="googleplay('spc')">
</div><!--app-->
</div>

<div class="bnr">
<img :src="$cdn + '/img/main/event_spm.png'">
<div class="app">
	<img :src="$cdn + '/img/main/btn_appstore.svg'" @click="appstore('spm')">
	<img :src="$cdn + '/img/main/btn_googleplay.svg'" @click="googleplay('spm')">
</div><!--app-->
</div>


<Footer />

</div>
</template>

<style scoped>
.notes{
	margin: 10px;
	font-size: 14px;
	line-height: 20px;
}
.bnr{
	width: 355px;
	margin: 40px auto;
	border-bottom: 1px solid #E5E5E5;
}
.app{
	margin: 20px auto;
	text-align: center;
}
.app img{
	display: inline-block;
	margin: 0 10px;
	cursor: pointer;
}

</style>

<script>
import TopCap from '../../../components/TopCap.vue'
import Footer from '../../../components/Footer.vue'

export default {
	props: {
		moveTo: {type: Function, default: (num)=>{}},
	},

	methods: {
		appstore: function(app){
			if(app == 'original'){
				window.open('https://apps.apple.com/jp/app/ポイント運用アプリ-stockpoint/id1314873450', '_blank')
			}else if(app == 'spc'){
				window.open('https://apps.apple.com/jp/app/ポイント運用-stockpoint-for-connect/id1502530661', '_blank')
			}else if(app == 'spm'){
				window.open('https://apps.apple.com/jp/app/id1574893406', '_blank')
			}
		},

		googleplay: function(app){
			if(app == 'original'){
				window.open('https://play.google.com/store/apps/details?id=jp.co.unyo.android&hl=ja&gl=US', '_blank')
			}else if(app == 'spc'){
				window.open('https://play.google.com/store/apps/details?id=jp.co.stockpoint.connectsp&hl=ja&gl=US', '_blank')
			}else if(app == 'spm'){
				window.open('https://play.google.com/store/apps/details?id=jp.stockpoint.spm', '_blank')
			}
		},
	},

	components:{
		TopCap,
		Footer,
	},
}
</script>
