<template>
<div class="main">

	<div class="head">
	<img :src="cdn + '/img/rensai/esg/arrow_left_white.png'" @click="back">
	ESGの泉 #03
	</div><!--head-->

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/esg/esg3/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/esg/esg3/MV.png'">

		<h1>ＥＳＧは儲かるのか？<br>儲からないのか？</h1>
		<p class="dt">2023/10/25</p>

		<div class="fav" style="overflow: visible;">
			<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
		</div><!--fav-->

	</div><!--MVWrp-->

	<div class="border">

	<pre>　「…で、ESGってもうかるんですか？」
　個人投資家向けのセミナーや勉強会でＥＳＧ投資について話すと、必ず投げかけられる質問はこれだ。

　「投資に環境や社会などの視点を盛りこむ」などと言っても、それは慈善活動を推奨しているのか。
　<span class="uline">そんな企業が利益を上げ、株価は上がっているのだろうか。</span>
　素朴だが、本質的な内容だ。

　人によって「ＥＳＧ投資」について抱く内容は異なる。

　ある人は環境や社会に良いことをしている企業だから株式を買うことによって応援するのだと思い、またある人は環境・社会面で優れている企業は業績や株価も良好だから投資したほうが得だと考える。
　どちらが正しいのだろう。
　答えは「どちらも正しい」だ。
ＥＳＧ投資を考案した国連の人々の意図は明らかに前者、すなわち「<span class="uline">良いことをしている企業の応援</span>」だ。しかし、実際にＥＳＧ投資が広まったのは、<span class="uline">経済的な利益を得られそうなこと</span>にある。

<span class="uline">自分のお金の使い方は自分で決めれば良い。</span>
ＥＳＧ投資の定義にアタマを悩ます前に、
<span class="uline">まず自分がどんな投資をして何を成し遂げないのか、しっかり考えること</span>
が重要だ。

　ＥＳＧ投資の定義や有効性が問題になるのは、人から預かったお金を代理で運用している場合だ。年金運用などが典型例だ。もし、貪欲に利益を追求してほしいと考える年金運用の委託者が多かった場合、運用の受託者、すなわち基金が勝手に「環境や社会に良いことをしているから」という理由だけでＥＳＧ投資を進めることは許されない。そこは「ＥＳＧ投資はもうかる」という明確な証拠が必要とされる。

　今、米国ではアンチＥＳＧの嵐が吹き荒れている。共和党が強い州を中心に、ＥＳＧ投資をする資産運用会社には公的年金を運用させないという動きまである。なぜなら、ＥＳＧ運用が金銭的リターンを犠牲にして環境・社会問題の解決を目指していると考えているからだ。

　ＥＳＧ投資は金銭的リターンを向上させるのかどうか。明確なエビデンスは今のところなく、定説も存在しない。ＥＳＧ投資の歴史はたかだか２０年なので、有意な因果関係を証明するためのデータの蓄積がないからだ。
　資産運用に関わる人たちは、あの手この手でＥＳＧ投資の有効性を検証しようとしている。日本では旗振り役の年金積立金管理運用独立行政法人（ＧＰＩＦ）の「ＥＳＧ活動報告」がよく知られている。２０２２年度版を見ると、ＧＰＩＦが選定したＥＳＧ９指数のうち、運用開始日から２３年３月まで比較対象となる東証株価指数（ＴＯＰＩＸ）やＭＳＣＩオールカントリー指数を上回ったものは６本。２本は下回り、１本は最近の運用開始なのでデータなしだった。

　フィデリティ投信のウエブサイトを見ると、「<span class="uline">投資にＥＳＧを考慮すべき３つの理由</span>」と題するページがある。
それによると「１９７０年から２０１４年に行われた２０００以上に及ぶ研究を検証したところ、<span class="uline">その約半数でＥＳＧと企業業績との間の正の相関関係がある</span>ことが示され、負の関係があったとするものはわずか１１％だった」という。また、ＥＳＧに熱心に取り組む企業への投資は「<span class="uline">全体的にリターンが向上し、ボラテリティが低下する</span>ことが明らかになった」ともいう。
　こうした分析結果は比較的多くあるのだが、常に反対論も聞かれる。代表的な反論はこうだ。「ＥＳＧに熱心に取り組んでいるから企業業績がよくなる（従って株価推移も良好）のではなく、業績が良い（株価推移が良好）からＥＳＧに取り組む余裕があるだけではないのか」。

　ＥＳＧと企業業績・株価はどうやら相関関係はありそうだが、因果関係はまだはっきり証明されていない。
それがＥＳＧ投資の現在地だ。

　<span class="uline">だからこそ、私たち一人ひとりが、どんな投資をしたいのかをよく考える必要がある。</span></pre>


	<div class="fav" style="overflow: visible;">
		<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
	</div><!--fav-->

	<div class="next">
		<img :src="cdn + '/img/rensai/esg/esg3/next.png'">
		<p class="title">「人権問題」の意識が低い、<br>日本の現状</p>
	</div><!--next-->

	</div><!--border-->

	<div class="grey">

		<pre>筆者プロフィール</pre>
		<p class="name">小平龍四郎</p>
		<pre style="margin-top: 0;">日本経済新聞　上級論説委員兼編集委員</pre>
		<img class="img" :src="cdn + '/img/rensai/esg/esg3/photo1.png'">

		<pre>2022年4月より現職。2008年9月より8年半に渡って日本経済新聞編集委員兼論説委員(資本市場担当)。それに先立ち、経済金融部編集委員兼論説委員。2000年から2004年まで同社欧州総局（ロンドン）で金融分野を取材。それ以前は同社証券部で証券会社・市場、企業財務などを担当。1988年に早稲田大学第一文学部を卒業し、日本経済新聞社入社。1964年4月生まれ。主な著書は「グローバルコーポレートガバナンス」「アジア資本主義」「ＥＳＧはやわかり」。</pre>

		<a class="btn back" @click="back">
			<img class="arrow" :src="cdn + '/img/rensai/esg/arrow_left_white.png'">
			ESGの泉 TOPへ
		</a>

	</div><!--grey-->



</div>
</template>

<style scoped>
.border{
	border: 1px solid #EDF0F0;
	padding: 20px;
	margin: 5px;
}
h4{
	font-size: 16px;
	font-weight: 600;
	max-width: 315px;
	margin: 0 auto;

}
span.uline{
	font-weight: 600;
	background: linear-gradient(transparent 60%, rgba(109,164,170,0.3) 30%);
}
span.right{
	display: block;
	text-align: right;
}
pre{
	max-width: 315px;
	line-height: 32px;
	margin: 20px auto;
	font-size: 300;
}
.img{
	display: block;
	max-width: 315px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #8DA1AC;
	color: white;
	text-align: center;
	line-height: 24px;
	position: relative;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}
.MVWrp{
	position: relative;
	min-height: 550px;
}
.MVWrp .back{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 330px;
	object-fit: cover;
	object-position: 50% 0;
}
.MVWrp .MV{
	display: block;
	width: 230px;
	margin: 20px auto 0 auto;
}
.MVWrp h1{
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin: 40px auto;
}
.MVWrp .dt{
	display: block;
	max-width: 335px;
	margin: 20px auto;
	font-size: 12px;
}

.next{
	position: relative;
	width: 315px;
	min-height: 60px;
	background-color: #D7E9EB;
	margin: 20px auto;
}
.next img{
	position: absolute;
	width: 46px;
	top: 0;
	bottom: 0;
	left: 15px;
	margin: auto;
}
.next .title{
	margin-left: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: 600;
}

.grey{
	background-color: #F0F0F0;
	margin: 20px 0;
	padding: 20px 0;
}
.name{
	font-size: 18px;
	font-weight: 600;
	max-width: 335px;
	margin: 0 auto;
}

.btn.back{
	color: white;
	background-color: #8B9FAA;
	border: none;
	font-weight: normal;
	border-radius: 30px;
}
.btn.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 6,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
		}
	},

	methods: {
		//初期化
		init: function(){
			this.getFavs()
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},

		back: function(){
			location.href = '/rensai/esg'
		},

	},

	components: {
		Favorite,
	},
}
</script>
