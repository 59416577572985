import { comma, get_products } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import SearchTab from '../../components/SearchTab.vue'
import SearchBox from '../../components/SearchBox.vue'

export default {
	data: function(){
		return {
			themeList: [],
			products: {},
			isMore: false,
		}
	},

	mounted: async function(){
		let res = await this.$post('/webapi/client/theme', {}, true)
		if(res.code != 0){
			alert(res.message)
			return false
		}
		this.themeList = res.list
	},

	methods: {
		searchTheme: function(theme){
			this.$router.push({name: 'SearchList', params:{theme: theme}})
		},

		search: function(searchText){
			this.$router.push({name: 'SearchResult', params:{searchText: searchText}})
		},

		srcset: function(img_url){
			let tmp = img_url.replace('.jpg', '')
			return img_url + ' 1x,' + tmp + '@2x.jpg 2x'
		},

	},

	components: {
		Header,
		TopCap,
		SearchTab,
		SearchBox,
	},

}
