import { comma } from '../../../lib/utils'

import Header from '../../components/Header.vue'
import TopCap from '../../components/TopCap.vue'
import RankingItem from '../../components/RankingItem/RankingItem.vue'

export default {
	data: function(){
		return {
			isSelect: false,
			isClosing: false,
			activeRanking: 7,
			list: []
		}
	},

	mounted: async function(){
		await this.changeRanking(7, false)
	},

	methods: {
		detail: function(c_code){
			this.$router.push({name: 'SearchDetail', params:{ c_code: c_code}})
		},

		changeRanking: async function(rank_type, anim=true){
			let res = await this.$post('/webapi/client/ranking', {rank_type: rank_type}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.activeRanking = rank_type
			this.list = res.list.splice(0, 10)

			//closing
			if(anim){
				this.isClosing = true
				setTimeout(() => {
					this.isSelect = false
					this.isClosing = false
				}, 270)
			}

		},

		rankingName: function(){
			let name = '人気 急上昇'
			switch(this.activeRanking){
				case 2:
					name = '人気'
					break
				case 3:
					name = 'ポイント数 急上昇'
					break
				case 4:
					name = 'ポイント数'
					break
				case 5:
					name = '投稿数'
					break
				case 6:
					name = 'いいね数'
					break
				case 7:
					name = '愛され企業指数'
					break
			}
			return name
		},

		/*comma wrapper*/
		comma: function(num){ return comma(num) },
	},

	components: {
		Header,
		TopCap,
		RankingItem,
	},

}
