<template>
<div>
<TopCap page="event" />

<h3>現在の愛されコメント TOP10</h3>

<img class="mv" :src="$cdn + '/img/main/bnr_event_ranking.png'">

<p class="notes">みなさまから寄せられたコメントのうち、ステキなコメントTOP10のランキングです。</p>


<div v-for="(l, i) in comments" :key="i" class="commentWrapper">
	<div class="header">
		<p class="number" :style="{backgroundColor: cols[i % 3]}">{{i + 1}}</p>
		<div class="hWrp">
			<p class="nickname">{{l.nickname.slice(0, 14)}}{{l.nickname.length >= 15 ? '...' : ''}}</p>
			<p class="themeWrp">
				<span class="cTheme">{{$apc.cTheme[l.c_theme]}}</span>
				<span class="dt">{{dtFmt(l.date)}}</span>
			</p>
		</div>
	</div><!--header-->

	<div class="main">
		<p class="name"><span @click="linkTo(l.c_code)">{{l.product.name}}</span>へのコメント</p>
		<pre class="comment">{{l.comment.slice(0, 99)}}{{l.comment.length >= 100 ? '...' : ''}}</pre>
	</div><!--main-->

	<!--todo 外だしするPage2Item.vue-->
	<Favorite :favs="l.favs" :myMark="l.my_mark" @fav="" />
</div>


<Footer />

</div>
</template>

<style scoped>
.mv{
	display: block;
	margin: 20px auto;
}
.notes{
	margin: 10px;
	font-size: 14px;
	line-height: 20px;
}

.commentWrapper{
	width: 355px;
	height: 240px;
	text-align: center;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	margin: 20px auto;
}
.header{
	height: 50px;
}
.number{
	display: block;
	float: left;
	margin-top: 10px;
	margin-left: 10px;
	width: 32px;
	height: 32px;
	font-size: 20px;
	font-weight: 600;
	color: white;
	line-height: 32px;
	border-radius: 10px;
}
.hWrp{
	display: block;
	float: left;
	width: calc(100% - 52px);
	margin-top: 10px;
	margin-left: 10px;
}
.nickname{
	display: block;
	text-align: left;
	color: #333333;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}
.themeWrp{
	line-height: 14px;
	text-align: left;
}
.cTheme{
	font-size: 10px;
	color: var(--col-main);
	line-height: 14px;
}
.dt{
	font-size: 10px;
	line-height: 14px;
	opacity: 0.6;
}
.main{
	height: calc(100% - 50px - 32px - 10px);
	overflow: hidden;
	padding: 10px;
}
.name{
	height: 28px;
	line-height: 28px;
	background-color: #F8F8F8;
	padding-left: 10px;
	font-size: 14px;
	text-align: left;
}
.name span{
	font-size: 14px;
	line-height: 28px;
	color: var(--col-main);
	cursor: pointer;
}
pre.comment{
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	white-space: pre-wrap;
}
</style>

<script>
import {get_products, dtfmt2} from '../../../../lib/utils'
import TopCap from '../../../components/TopCap.vue'
import Footer from '../../../components/Footer.vue'
import Favorite from '../../../components/Favorite3.vue'

export default {
	props: {
		moveTo: {type: Function, default: (num)=>{}},
	},

	data: function(){
		return {
			products: {},
			comments: [],
			term: '',
			cols: ['#26CFB5', '#26AECF', '#D96767'],
		}
	},

	mounted: async function(){
		//銘柄マスタを取得する
		let prdObj = await get_products(false)
		this.products = prdObj.obj

		let res = await this.$post('/webapi/client/weekly_ranking/index', {id: 1}, true)
		if(res.code != 0){
			return false
		}
		this.term = res.term
		for(let i=0, l=res.list.length; i<l; i++){
			let c = res.list[i]
			c.product = this.products[c.c_code]
			if(c.product == null){ c.product = {name: ''} }
			this.comments.push(c)
		}
	},

	methods: {
		linkTo: function(c_code){
			this.$router.push({name: 'SearchDetail', params: {c_code: c_code} })
		},

		dtFmt: function(dt){
			return dtfmt2(dt)
		},

	},

	components:{
		TopCap,
		Footer,
		Favorite,
	},
}
</script>
