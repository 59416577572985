<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	長期投資コラム
</div><!--head-->

<h1>第2回<br>ファーストリテイリングの<br>スゴイ！理由</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<pre>長期投資の醍醐味は、<span class="red">これからその企業が成長し、株価が上がっていくのかどうか</span>、ということ。だから大切なのは、これから将来のこと。ただし、いきなりその本丸へ行く前に、まずは過去を一緒に振り返ってみましょう。</pre>


<h2>1. 今までの姿を見てみよう</h2>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu2/chart1.png'">

<pre>10年で4倍近くになっています！しかも、10年前というと、既にGUもリリースされていて、ファーストリテイリングは既に世間的には十分有名な企業になっていた時期です。</pre>

<div class="yellow" style="text-align: center;">
	<span class="red">株価が10年で4倍</span>
</div>

<pre>ちなみに、日経平均の同時期比較は、

10年で2倍。

あわせて、ファーストリテイリングの売上と利益の推移も確認しておきましょう。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu2/chart2.png'">

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>

長期投資で成功する法則

業績の良い企業を選べば間違いなし

</pre>

	<hr class="down">
</div><!--point-->

<h2>2. 今現在の姿を確認しよう</h2>

<div class="yellow" style="text-align: center;">
	<pre class="red">世界のアパレル企業で
売上　　 4位
当期利益 2位
の堂々たるグローバル企業、です。</pre>
</div>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu2/chart3.png'">

<pre>第1位　Inditex（インディテックス）- ZARAのスペインの会社
第2位　SHEIN - 2008年設立の中国の新興アパレル企業
第3位　H&M - H&Mブランドのスウェーデンの会社
第5位　GAP - GAPブランドのアメリカの会社</pre>

<div class="yellow" style="text-align: center;">
	<pre>会社全体では、
<span class="red">「ユニクロ」の売上が全体の80%以上</span></pre>
</div>

<pre>「ユニクロ」の売上が大きいのですが、その売上構成比では、</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu2/chart4.png'">

<pre>ご参考までに、日本のアパレル各社と比較してみると一目瞭然。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu2/chart5.png'">

<pre>ファーストリテイリングは、日本のアパレル各社の中で、断トツの規模と売上。国内においては「絶対王者」では、なぜ同社がそうなることが出来たのか。そして、今後も成長が期待できるのか？に踏み込んでいきましょう。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>ファーストリテイリング

グローバルアパレルカンパニーそして、
国内においても、もちろん、
「絶対王者」</pre>

	<hr class="down">
</div><!--point-->

<h2>3. なぜ、ファーストリテイリングは絶対王者になれたのか？</h2>

<pre><span class="red">【理由1】</span>
<span class="red">コスト競争力がめちゃくちゃ高い</span>

・シンプルでベーシックな商品にフォーカス
・製品開発から生産、販売までのサプライチェーンをマネージメントして効率化

→ 品質が良いのに価格が安いという圧倒的な優位性

<span class="red">【理由2】</span>
<span class="red">大きな市場を作り出す実行力</span>

・最初は苦労しても、あきらめずに海外市場を狙い、結果をだした現場実行力
・事業を展開する社内でのノウハウ化

<span class="red">【理由3】</span>
<span class="red">優秀な経営者・経営チーム</span>

・カリスマ経営者：柳井正氏の存在
・一流コンサル出身者や現場店長たたき上げの優秀の経営陣

→ 優秀な人材が居るので、また優秀な人が集まって来るという好循環</pre>


<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>成長する源泉

① 有利な競争構造　　　　
② 大きな市場を作る実行力
③ 優秀な経営チーム　　　
</pre>

	<hr class="down">
</div><!--point-->

<h2>4. これからの成長について</h2>

<pre>3.で述べた通り、同社の成長する源泉は当分は続くと思われます。特に重要なのは、</pre>

<div class="yellow" style="text-align: center;">
	<span class="red">海外事業の成長力</span>
</div>

<pre>です。</pre>

<pre>コロナ禍から完全復活した今、さらに海外事業がどこまで成長するか、がポイント。
やや懸念材料は、日本のインフレ。日本の経済状況は、「インフレ＞賃上げ」となっていて、低価格を武器にする企業には厳しい環境にはなっています。ここは要注意点。
とは言え、ファーストリテイリングは、</pre>

<div class="yellow" style="text-align: center;">
	<span class="red">成長する海外の市場で稼ぐ日本企業</span>
</div>

<pre>の代表的な存在です。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>ファーストリテイリング

まだまだ、今後の成長に期待大</pre>

	<hr class="down">
</div><!--point-->

<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>この会社、実は10年後が期待大！
「寿スピリッツ」</pre>
	</div><!--next-->
</div><!--nextWrp-->


<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #288924;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #288924;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
.red{
	color: #EF5C5C;
	font-weight: 600;
}
span.uline{
	background: linear-gradient(transparent 60%, #FDAA85 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #E9F5E9;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
}
.point hr{
	position: absolute;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #1A1A1A;
	margin: 0;
}
.point hr.up{
	top: 5px;
}
.point hr.down{
	bottom: 5px;
}
.point>img{
	height: 30px;
	position: absolute;
	top: -15px;
	left: -2px;
}
.point pre{
	font-weight: 600;
}

.grey{
	background-color: #F4F4F4;
	margin: 20px 0;
	padding: 20px 0;
}
.yellow{
	background-color: #FFF3C3;
	border-radius: 5px;
	padding: 20px 0;
	width: 335px;
	margin: 20px auto;
}

.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}


.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 5,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
