<template>
<div class="tabs">
	<a :class="{active: $route.name == 'SearchIndex'}" @click="linkTo('SearchIndex')">テーマ<br>から探す</a>
	<a :class="{active: $route.name == 'SearchIndexSdgs'}" @click="linkTo('SearchIndexSdgs')">SDGs<br>から探す</a>
	<a :class="{active: $route.name == 'SearchIndexComment'}" @click="linkTo('SearchIndexComment')">コメント<br>から探す</a>
	<hr>
</div><!--tabs-->
</template>

<style scoped>
.tabs{
	height: 52px;
	text-align: center;
	margin-top: 20px;
}
.tabs>a{
	display: inline-block;
	width: 110px;
	height: 50px;
	line-height: 16px;
	font-size: 12px;
	padding-top: 9px;
	color: var(--col-main);
	background-color: #F8F8F8;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	text-align: center;
	font-weight: 600;
	box-shadow: 0 1px 3px rgba(0,0,0,0.16);
	border: none;
	margin: 0 5px;
}
.tabs>a.active{
	color: white;
	background-color: var(--col-main);
}
.tabs hr{
	display: block;
	margin: 0;
	border: none;
	border-top: 1px solid var(--col-main);
}
</style>

<script>
export default {
data: function(){
	return {
	}
},

methods: {
	linkTo: function(name){
		if(this.$route.name == name) return false
		this.$router.push({name: name})
	},
},
}
</script>
