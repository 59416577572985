<template>
<div class="header">
  <router-link class="normalLink" :to="{ name: 'Index' }">
    <img class="logo" width="150" height="34" alt="トップ" :src="$cdn + '/img/main/logo2.svg'">
  </router-link>
  <router-link class="normalLink nickname" :to="{ name: 'MyPage' }">マイページ</router-link>
  <router-link class="normalLink account" :to="{ name: 'MyPage' }">
    <img width="32" height="32" alt="マイページ" :src="$cdn + '/img/main/icon_account.svg'">
  </router-link>
  <router-link class="normalLink news" :class="{notif: isNotif}" :to="{ name: 'Information' }">
    <img width="32" height="32" alt="お知らせ" :src="$cdn + '/img/main/icon_news.svg'">
  </router-link><!--news-->

	<div class="menu">
		<router-link
      :class="{active: ['Index', 'Information', 'MyPage'].includes($route.name)}"
      :to="{ name: 'Index' }"
    >
      ホーム
    </router-link>
		<router-link
      :class="{active: $route.name == 'Ranking'}"
      :to="{ name: 'Ranking' }"
    >
      ランキング
    </router-link>
		<router-link
      :class="{active: $route.name.match(/^Rensai/)}"
      :to="{ name: 'Rensai' }"
    >
      連載
    </router-link>
		<router-link
      :class="{active: $route.name.match(/^Search/)}"
      :to="{ name: 'SearchIndex' }"
    >
      企業を探す
    </router-link>
		<router-link
      :class="{active: $route.name == 'Event'}"
      :to="{ name: 'Event', params: { event_id: 0 } }"
    >
      イベント
    </router-link>
	</div><!--menu-->

</div><!--header-->
</template>

<style scoped>
.header{
	height: 96px;
	overflow: hidden;
	box-shadow: 0 1px 3px rgba(0,0,0,0.16);
	z-index: 5;
}
.logo{
	margin-top: 15px;
 	margin-left: 12px;
	height: 34px;
	cursor: pointer;
}

.nickname{
	font-size: 10px;
	font-weight: 300;
	position: absolute;
	height: 10px;
	line-height: 10px;
	top: 25px;
	right: 84px;
	max-width: 120px;
	color: var(--col-main);
	cursor: pointer;
}

.account{
	position: absolute;
	width: 32px;
	height: 32px;
	top: 14px;
	right: 48px;
	cursor: pointer;
}
.news{
	position: absolute;
	width: 32px;
	height: 32px;
	top: 14px;
	right: 10px;
	cursor: pointer;
}
.notif:after{
	content: '　';
	position: absolute;
	top: 0;
	right: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #F8397D;
}

.menu{
	width: 100%;
	height: 46px;
	overflow: hidden;
	text-align: center;
}
.menu a{
	display: inline-block;
	width: 70px;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	color: #333333;
	font-size: 12px;
	font-weight: 600;
	height: 100%;
	line-height: 46px;
	border-radius: 0;
}
.menu a.active{
	border-bottom: 4px solid #F49392;
}

</style>

<script>
import {get_news} from '../../lib/utils'

export default {
	data: function(){
		return {
			isNotif: false,
		}
	},

	mounted: function(){
		let forceFlg = this.$route.name == 'Index' ? true: false
		this.getNews(forceFlg)
	},

	methods: {
		getNews: async function(forceFlg){
			let news = await get_news(forceFlg)
			//notficationの設定
			if(news.length > 0){
				let ntime = localStorage.getItem('newsNotifTime')
				if(ntime == null || news[0].date > parseInt(ntime)) this.isNotif = true
			}
		},
	},
}
</script>
