<template>
<div class="wrapper" :class="{new: l.new_flg}">
	<div class="icon">
		<img v-lazy="l.icon != 10 ? $cdn + '/img/main/talk_icon' + l.icon + '.svg' : l.icon_url">
	</div><!--icon-->
	<img class="king" :src="iconKingSrc(l.king)" v-show="l.king != null && l.king != 0">
	<div class="ncdWrp">
		<p class="nickname">{{l.nickname}}</p>
		<p class="date">{{dispDate(l.date)}}</p>
	</div>

	<img class="subMenu" :src="$cdn + '/img/main/subMenu' + (isOpenSubMenu ? '2' : '1') + '.svg'"
		@click="isOpenSubMenu = ! isOpenSubMenu">
	<div class="subMenuList" v-show="isOpenSubMenu">
		<div class="gomi" v-show="l.my_flg" @click="delComment">
			<span>投稿を削除する</span>
			<img :src="$cdn + '/img/main/gomi1.svg'">
		</div><!--gomi-->
		<div class="ng" v-show="! l.my_flg" @click="repComment">
			<span>投稿を通報する</span>
			<img :src="$cdn + '/img/main/ng1.svg'">
		</div><!--ng-->
	</div><!--subMenuList-->

	<pre class="comment"><img :src="$cdn + '/img/main/c_theme_flg' + l.c_theme + '.png'">{{l.comment}}</pre>

	<Favorite :favs="l.favs" :myMark="l.my_mark" @fav="updateFav" />

	<div class="spReplies" v-show="l.reply1 || l.reply2">
		<p><img :src="$cdn + '/img/main/return.svg'"><span>STOCKPOINTからのコメント</span></p>
		<pre>{{l.reply1}}</pre>
		<pre>{{l.reply2}}</pre>
	</div><!--spReplies-->

</div>
</template>



<style scoped>
.wrapper{
	border-bottom: 1px solid #E5E5E5;
	padding: 20px 10px;
}
.wrapper.new{
	animation: newPost 6s;
}
@keyframes newPost{
	0%{ background-color: #FFFADE}
	80%{ background-color: #FFFADE}
	100%{ background-color: transparent}
}
.icon{
	display: block;
	position: relative;
	float: left;
	width: 32px;
	height: 32px;
}
.icon img{
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
}
.king{
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	margin-left: 5px;
}
.ncdWrp{
	display: block;
	float: left;
}
.nickname{
	display: block;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}
.date{
	display: block;
	margin-left: 10px;
	font-size: 10px;
	font-weight: 300;
	color: #999999;
	line-height: 14px;
}
.subMenu{
	position: absolute;
	top: 20px;
	right: 10px;
	width: 24px;
	height: 24px;
}
.subMenuList{
	position: absolute;
	top: 45px;
	right: 10px;
	width: 180px;
}
.gomi, .ng{
	width: 180px;
	height: 56px;
	border: 1px solid #999999;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	border-radius: 5px;
	background-color: var(--col-white);
	z-index: 10;
	text-align: center;
	line-height: 56px;
}
.gomi span, .ng span{
	font-size: 14px;
	font-weight: 300;
}
.comment{
	clear: both;
	margin-top: 38px;
	font-size: 14px;
	font-weight :300;
	line-height: 22px;
}
.comment>img{
	height: 20px;
	margin-right: 5px;
}
.spReplies{
	width: 100%;
	padding: 16px 16px 6px 16px;
	background-color: #F8F8F8;
	margin-top: 10px;
}
.spReplies p img{
	width: 16px;
}
.spReplies p span{
	font-size: 14px;
	font-weight: 600;
	color: var(--col-main);
	vertical-align: middle;
	margin-left: 8px;
}
.spReplies pre{
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 10px;
}
</style>


<script>
import {dtfmt2} from '../../lib/utils'
import Favorite from './Favorite3.vue'
import PostComment from './PostComment3.vue'

export default {
	props: {
		l: {type: Object, default: () => { return {} }},
	},

	data: function(){
		return {
			isOpenSubMenu: false,
		}
	},

	mounted: function(){
	},

	methods: {
		iconKingSrc: function(king){
			if(king == null || king == 0) return ''
			return this.$cdn + '/img/main/king_icon' + king + '.png'
		},

		//削除
		delComment: async function(){
			this.isOpenSubMenu = false
			this.$emit('del', {id: this.l.id})
		},

		//通報
		repComment: async function(){
			this.isOpenSubMenu = false
			this.$emit('rep', {id: this.l.id})
		},

		dispDate: function(dnum){
			return dtfmt2(dnum)
		},

		updateFav: function(){
			this.$emit('fav', {id: this.l.id, mark: 0})
		},

		favCb: function(){
			this.favs++
			this.myMark = 0
		},

	},

	components: {
		Favorite,
	},
}
</script>
