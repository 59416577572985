import Vue from 'vue'
import {post} from '../lib/utils'
import Loading from '../lib/Loading.vue'
import VueGtm from '@gtm-support/vue2-gtm'
import App from '../main/app.vue'
import router from '../main/router'
import VueLazyload from 'vue-lazyload'
import Ads from 'vue-google-adsense'


Vue.config.productionTip = false
Vue.prototype.$post = post
Vue.prototype.$cdn = CDN
Vue.prototype.$railsenv = RAILSENV
Vue.prototype.$apc = {
	cTheme: {
		0: '応援',
		1: '期待',
		2: 'お願い',
		3: '感謝',
		4: 'その他',
		5: 'SDGs',
	},
}

if(RAILSENV == 'production' || RAILSENV == 'staging'){
Vue.use(VueGtm, {
	id: GTM_ID,
	defer: false,
	enabled: true,
	debug: true,
	loadScript: true,
	vueRouter: router,
	trackOnNextTick: false,
})
}

Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: '/404.png',
	loading: '/loading.png',
	attempt: 1,
})

Vue.component('Loading', Loading)

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)


document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
		router,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
})
