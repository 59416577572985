<template>
<div class="main">

	<div class="MVWrp">
		<img class="back" :src="cdn + '/img/rensai/diary/MV_back.png'">
		<img class="MV" :src="cdn + '/img/rensai/diary/MV.png'">

		<pre>「愛されっちダイアリー」では、
これまでに寄せられた素敵な応援
メッセージをピックアップして紹
介していきます。</pre>

	</div><!--MVWrp-->

	<div class="bgPink">
		<img class="back1" :src="cdn + '/img/rensai/diary/back1.png'">

		<div class="back2Wrp">
			<img class="back2" :src="cdn + '/img/rensai/diary/back2.png'">
		</div><!--back2Wrp-->

		<div class="cap1Wrp">
			<img class="cap1" :src="cdn + '/img/rensai/diary/cap1.png'">
		</div><!--cap1Wrp-->

		<div v-for="(l, i) in list.filter((l) => checkDt(l.dt))" class="item" :class="l.color" @click="linkTo(l.vol)" >
			<p class="new" v-show="i == 0">new</p>
			<img :src="l.imgUrl">
			<p class="vol">Vol.<span>{{l.vol}}</span></p>
			<p class="title">{{l.title}}</p>
			<pre>{{l.txt}}</pre>
		</div><!--item-->

	</div><!--bgPink-->

</div><!--main-->
</template>


<style scoped>
*{
	font-family: 'ZenMaruGothic', sans-serif;
	font-size: 16px;
	font-weight: 300;
}
span{
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
}

.MVWrp{
	position: relative;
	overflow: hidden;
	min-height: 490px;
	width: 100%;
	background: linear-gradient(to bottom, rgba(255,255,255, 0.63) 80%, rgba(251,235, 238, 0.4));
}
.MVWrp .back{
	position: absolute;
	top: 0;
	left: -20%;
	width: 140%;
	max-width: none;
	height: 400px;
	object-fit: cover;
	object-position: center bottom;
}
.MVWrp .MV{
	position: absolute;
	width: 263px;
	top: 70px;
	left: 0;
	right: 0;
	margin: auto;
}
.MVWrp pre{
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 40px;
	width: 245px;
	margin: auto;
}
.bgPink{
	position: relative;
	overflow: visible;
	background-color: #FDF8F9;
	margin-bottom: 80px;
	padding-top: 40px;
	padding-bottom: 180px;
}
.back1{
	position: absolute;
	top: -100px;
	right: -200px;
	width: 309px;
}
.back2Wrp{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
.back2{
	width: 100%;
	height: 160px;
	object-fit: cover;
	object-position: center top;
}
.cap1Wrp{
	height: 20px;
	max-width: 335px;
	margin: 0 auto 20px auto;
	line-height: 18px;
}
.cap1{
	height: 18px;
}

.item{
	position: relative;
	width: 335px;
	padding: 20px;
	margin: 20px auto;
	border-radius: 20px;
	background-color: white;
	cursor: pointer;
}
.item .new{
	position: absolute;
	top: 20px;
	left: 20px;
	width: 40px;
	height: 18px;
	line-height: 18px;
	font-size: 14px;
	font-weight: bold;
	color: white;
	background-color: #FC8786;
	border-radius: 10px;
	text-align: center;
}
.item .vol{
	position: absolute;
	top: 20px;
	right: 20px;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	line-height: 60px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	color: white;
}
.item .vol span{
	font-size: 18px;
}
.item img{
	width: 190px;
	display: block;
	margin: 10px auto;
}
.item .title{
	font-size: 18px;
	font-weight: bold;
}

.item.orange .vol{
	background-color: #FEC87B;
}
.item.orange .title{
	color: #FEC87B;
}

.item.blue .vol{
	background-color: #7FBFF5;
}
.item.blue .title{
	color: #7FBFF5;
}

.item.green .vol{
	background-color: #8BD9B0;
}
.item.green .title{
	color: #8BD9B0;
}

</style>


<script>
export default {
	data: function(){
		return {
			cdn: CDN,
			list: [
				{
					imgUrl: CDN + '/img/rensai/diary/vol16.png',
					vol: 16,
					color: 'orange',
					title: '祝！北陸新幹線延伸!!',
					txt: '祝　北陸新幹線延伸。GW 行きたいけど、…',
					dt: new Date('2024-04-26 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol15.jpg',
					vol: 15,
					color: 'green',
					title: '大谷翔平結婚おめでとう！！！',
					txt: 'オオタニサン結婚おめでとう〜！！…',
					dt: new Date('2024-04-12 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol14.jpg',
					vol: 14,
					color: 'blue',
					title: '歴史的な一日！',
					txt: '日経平均新高値更新おめでとう🎉歴史的な…',
					dt: new Date('2024-03-29 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol13.jpg',
					vol: 13,
					color: 'orange',
					title: '良い香りで満たされます',
					txt: 'ハーブの種買ってます。庭からタイムやらバ…',
					dt: new Date('2024-03-08 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol12.jpg',
					vol: 12,
					color: 'green',
					title: 'サウナが気持ちいい',
					txt: '毎日泳ぎに行ってます。サウナが気持ちいい…',
					dt: new Date('2024-02-23 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol11.jpg',
					vol: 11,
					color: 'blue',
					title: '地球外にも目を向ける年',
					txt: '地球外にも皆さんが目を向ける年になるので…',
					dt: new Date('2024-02-09 10:00:00').getTime(),
				},

                {
			        imgUrl: CDN + '/img/rensai/diary/vol10.jpg',
            	    vol: 10,
                    color: 'orange',
                    title: '自分で自分に',
                    txt: 'クリスマスプレゼントにはじめてのアップル…',
                    dt: new Date('2024-01-26 10:00:00').getTime(),
                },
			
				{
					imgUrl: CDN + '/img/rensai/diary/vol9.jpg',
					vol: 9,
					color: 'green',
					title: 'ウーバーお願い！',
					txt: '「ウーバーお願い」と言えば、こどもが物を…',
					dt: new Date('2024-01-12 10:00:00').getTime(),
				},

				{
					imgUrl: CDN + '/img/rensai/diary/vol8.jpg',
					vol: 8,
					color: 'blue',
					title: 'ずっと前からつかっています',
					txt: '管楽器、ずっと前から使っています。…',
					dt: new Date('2023-12-27 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol7.png',
					vol: 7,
					color: 'orange',
					title: '大大大ファンです',
					txt: '孫がトミカの大大大ファンです。誕生日の…',
					dt: new Date('2023-12-13 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol6.png',
					vol: 6,
					color: 'green',
					title: '憧れてました',
					txt: '小さい頃エレベーターガールの声や仕草に…',
					dt: new Date('2023-11-29 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol5.png',
					vol: 5,
					color: 'blue',
					title: '孫と一緒に写真撮影',
					txt: '孫が出来たので一緒に写真を取りに行きます…',
					dt: new Date('2023-11-10 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol4.jpg',
					vol: 4,
					color: 'orange',
					title: 'こんなに君を。。。',
					txt: '安くなったら買おうとおもってるんだけど…',
					dt: new Date('2023-10-27 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol3.jpg',
					vol: 3,
					color: 'green',
					title: '楽天市場は外国人の強い味方',
					txt: 'いつもお世話になっております。部下の外国人達が…',
					dt: new Date('2023-10-11 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol2.jpg',
					vol: 2,
					color: 'blue',
					title: '定期的に食べに行きます',
					txt: '粉チーズ代ぐらい、払わせて下さいな…',
					dt: new Date('2023-09-22 10:00:00').getTime(),
				},
				{
					imgUrl: CDN + '/img/rensai/diary/vol1.png',
					vol: 1,
					color: 'orange',
					title: '４歳の孫からのお誘い',
					txt: '4歳の孫から今度連れて行ってあげる…',
					dt: new Date('2023-09-08 10:00:00').getTime(),
				},
			],
		}
	},

	methods: {
		//初期化
		init: function(){
		},

		linkTo: function(num){
			location.href = '/rensai/diary/' + num
		},

		checkDt: function(num){
			if(location.hostname != 'aisarekigyo.stockpoint.jp') return true
			const now = new Date().getTime()
			return now >= num
		},
	},

	components: {
	},
}
</script>
