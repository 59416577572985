<template>
<div class="rensai" :class="{big: isBig}" @click="link">
    <div class="wrp">

        <div class="imgWrp">
            <img class="img" width="120" height="91" alt="" :src="$cdn + d.img" v-if="! isBig">
            <img class="img" width="335" height="190" alt="" :src="$cdn + d.imgB" v-if="isBig">
            <img class="new" width="40" height="40" alt="" :src="$cdn + '/img/main/index/new.png'" v-if="isNew(d.dt)">
        </div><!--imgWrp-->

        <div class="txtWrp">
            <p class="sub" v-if="d.sub">{{ d.sub }}</p>
            <p class="title">{{ d.title }}</p>
            <p class="cat">{{ d.cat }}</p>
            <p class="dt">{{ dtFmt(d.dt) }} 更新</p>
        </div><!--txtWrp-->

    </div><!--wrp-->
</div><!--rensai-->
</template>

<style scoped>
.rensai{
    border-bottom: 1px solid #E2E2E2;
    padding: 10px 0;
    cursor: pointer;
}
.wrp{
    width: 345px;
    margin: 0 auto;
    padding: 10px 5px;
    overflow: visible;
}
.imgWrp{
    position: relative;
    overflow: visible;
    width: 120px;
    min-height: 60px;
    float: left;
    margin-right: 10px;
    .img{
        width: 100%;
				border: 1px solid #838383;
				border-radius: 20px;
    }
    .new{
        position: absolute;
        width: 40px;
        top: -10px;
        left: -10px;
    }
}
.txtWrp{
    position: relative;
    width: calc(100% - 120px - 10px);
    float: right;
    .title{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
    .cat{
        margin: 5px 0;
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
        padding: 0 9px;
        color: #666;
        background-color: #F5F5F5;
        border-radius: 12px;
    }
    .dt{
        font-size: 12px;
        font-weight: 300;
        color: #707070;
    }
}
.big .imgWrp{
    width: 100%;
    float: none;
    margin: 0;
    margin-bottom: 10px;
    .new{
        top: 10px;
        left: 10px;
    }
}
.big .txtWrp{
    width: 100%;
    float: none;
}
</style>

<script>
export default {
	props: {
		d: {type: Object, default: () => { return {} }},
		isBig: {type: Boolean, default: false},
	},

	data: function(){
		return {
		}
	},

	mounted: function(){
	},

	methods: {
		link: function(){
			if(this.d.link.match(/^http/)){
				window.open(this.d.link, '_blank')
			}else{
				location.href = this.d.link
			}
		},

		dtFmt: function(str){
			const dt = new Date(str)
			return [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()].join('.')
		},

		isNew: function(str){
			const dt = new Date(str)
			const now = new Date()
			now.setDate(now.getDate() - 31)
			if(dt >= now) return true
			return false
 		},
	},
}
</script>
