<template>
<div class="main">
<div class="head">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'" @click="back">
	長期投資コラム
</div><!--head-->

<h1>第1回<br>長期投資のポイント</h1>

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<h2>長期投資で成功するための、シンプルな法則。それは…</h2>


<pre><span class="red">投資や資産運用をする目的は、リターン＝儲け、を狙うこと。</span>長期投資でも短期投資でも、この目的は同じ。一方で、この同じ目的のために、どういう作戦で臨むかというスタンスは、長期投資と短期投資では大きく異なります。

長期投資でリターンを狙うための、シンプルな法則。それは、</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>長期投資で成功する法則

業績の良い企業を選ぶ

業績とは、<span class="uline">「売上」と「利益」</span></pre>

	<hr class="down">

</div><!--point-->

<pre><span class="red">長期で投資する場合</span>には、「業績が良い企業」そして、<span class="red">「業績が上がる可能性が高い企業」を選ぶ</span>、ことが基本です。</pre>


<div class="grey">

<h2>3つの将来成長する企業の見極めポイント</h2>

<pre>まず最初に、注目した企業の今までの業績をチェックしてみましょう。上場している企業は、必ず自社の売上や利益を、「決算発表」としてホームページ上に公開しています。これが、いわばその<span class="red">企業の今までの「成績表」</span>です。</pre>

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu1/graph1.png'">


<pre>でも大切なのは<span class="red">企業の業績</span>が、<span class="red">これから将来良くなりそうかどうか</span>、の見極め。
将来どうなるかはだれも正確な予言はできないのですが、実は下記の３つの重要ポイントを押さえるだけで、見極め成功確率はグーーンと上がります。</pre>

<div class="point">
	<hr class="up">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_point.png'">

<pre>将来成長する企業の見極めポイント

① <span class="uline">経営者・経営チーム</span>
　優秀な経営者と経営チームが居て、
　勝つための企業文化があること

②<span class="uline">ビジネス展開する市場規模</span>
　大きな市場、今後成長する市場、あ
　るいは今までなかった新しい市場を
　作るような規模でのビジネスである
　こと

③<span class="uline">戦略と競争優位性</span>
　明確で精度の高い戦略があり、それ
　によって簡単に覆せない競争優位性
　があること</pre>

	<hr class="down">

</div><!--point-->

<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu1/chara1.png'">

</div><!--grey-->

<h2>10年間で、20倍になった会社がある！</h2>

<pre>アウトドアメーカーの、ザ・ノースフェイス、をご存じでしょうか。機能性に優れた上に、シャープなデザインで人気の、世界的に有名なブランドです。

このノースフェイスを日本でライセンス契約して販売しているのが、ゴールドウィン社（8111）。

このゴールドウィン社の過去10年間の株価は、なんと、20倍になっています。
<span class="red">100万円投資していたら、10年後に2,000万円になっていた、</span>ということ。</pre>

<div class="grey">

	<pre>ゴールドウィン社 過去株価チャート</pre>

	<img class="img" :src="cdn + '/img/rensai/nobu_to_yasu/yasu1/graph2.png'">

</div><!--grey-->


<pre>インデックス型の投資信託で、例えば、日本株を代表する指数の「日経平均株価」で地道に運用していたとしても、過去10年間でで日経平均株価は約2倍（15,000円→32,000円に）。
　
<span class="red">20倍を狙うのか、2倍を狙うのかの差は大きい！</span>

このような、「これから10倍、20倍になる可能性が高い」企業は、日本にもまだまだたくさんあります。日本中に、「次の孫正義」「次の柳井正」になる優秀な事業家がどんどん頭角を現してきます。

そんな可能性の高い企業を毎回1社ずつピックアップして、「将来の業績を見極める重要ポイント」を具体的に紹介していくのがこのシリーズ。

次回は、既に大きく成長していて、しかも今後の成長も期待できる、ファーストリテイリング（9983）について詳しくご紹介していきましょう。</pre>


<div class="nextWrp">
	<div class="next">
		<img :src="cdn + '/img/rensai/nobu_to_yasu/icon_next.png'">
<pre>⻑期投資に合った銘柄紹介 その1
「ファーストリテイリング」</pre>
	</div><!--next-->

</div><!--nextWrp-->

<div class="fav" style="overflow: visible;">
	<Favorite :favs="favs.favs" :myMark="favs.my_mark" @fav="markFav"/>
</div><!--fav-->

<a class="btn sub back" @click="back">
	<img :src="cdn + '/img/rensai/nobu_to_yasu/arrow_left_black.png'">
	ノブとヤス TOPへ戻る
</a>


<PostComment ref="postComment" @post="postComment" />


<ul class="comments">
	<li v-for="(l, i) in comments">
		<Comment :l="l" @del="delComment" @rep="repComment" @fav="markCommentFav" />
	</li>
</ul>


</div>
</template>

<style scoped>
h1{
	display: block;
	border: 3px solid #1A1A1A;
	text-align: center;
	margin: 20px auto;
	padding: 20px 0;
	font-size: 20px;
	max-width: 335px;
	font-weight: 600;
}
h2{
	display: block;
	color: #288924;
	font-size: 24px;
	line-height: 32px;
	max-width: 335px;
	margin: 40px auto 0 auto;
	font-weight: bold;
}
h2:after{
	content: '　';
	display: block;
	width: 30px;
	border-top: 4px solid #288924;
}
pre{
	max-width: 335px;
	line-height: 24px;
	margin: 0 auto;
}
span.red{
	color: #EF5C5C;
}
span.uline{
	background: linear-gradient(transparent 60%, #FDAA85 30%);
}

.point{
	position: relative;
	overflow: visible;
	border: 2px dashed #1A1A1A;
	background-color: #E9F5E9;
	padding: 20px 0;
	max-width: 335px;
	margin: 20px auto;
	text-align: center;
}
.point hr{
	position: absolute;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #1A1A1A;
	margin: 0;
}
.point hr.up{
	top: 5px;
}
.point hr.down{
	bottom: 5px;
}
.point>img{
	height: 30px;
	position: absolute;
	top: -15px;
	left: -2px;
}
.point pre{
	font-weight: 600;
}

.grey{
	background-color: #F4F4F4;
	margin: 20px 0;
	padding: 20px 0;
}
.img{
	display: block;
	max-width: 335px;
	margin: 20px auto;
}

.head{
	padding: 15px 0;
	background-color: #ACD9AA;
	text-align: center;
	line-height: 24px;
	position: relative;
	font-weight: 600;
}
.head>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	height: 12px;
	margin: auto;
	cursor: pointer;
}

.nextWrp{
	margin: 20px 0;
	background-color: #ACD9AA;
}
.next{
	background-color: white;
	border: 1.5px solid #1A1A1A;
	position: relative;
	margin: 20px auto;
	padding: 20px 0 20px 70px;
	max-width: 335px;
}
.next>img{
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
}

.back{
	border: 2px solid #1A1A1A;
	border-radius: 30px;
	color: #1A1A1A;
	font-weight: normal;
}
.back>img{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 20px;
	margin: auto;
	height: 12px;
}

</style>


<script>
import {post} from '../../../../lib/utils'
import Favorite from '../../../components/Favorite3.vue'
import PostComment from '../../../components/PostComment3.vue'
import Comment from '../../../components/Comment2.vue'

export default {
	data: function(){
		return {
			cdn: CDN,
			rensai_id: 1,
			favs: {
				marks: [],
				favs: 0,
				my_mark: -1,
			},
			comments: [],
		}
	},


	methods: {
		//初期化
		init: function(){
			this.getFavs()
			this.getComments()
		},

		back: function(){
			location.href = '/rensai/nobu_to_yasu'
		},

		getFavs: async function(){
			let d = {
				rensai_ids: [this.rensai_id],
			}
			const res = await post('/webapi/client/rensai/favs', d)
			if(res.code == 0 && res.favs.length > 0) this.favs = res.favs[0]
		},

		getComments: async function(){
			let d = {
				rensai_id: this.rensai_id,
			}
			const res = await post('/webapi/client/rensai/comments', d)
			if(res.code == 0){
				this.comments = res.comments
			}
		},

		postComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				nickname:  params.nickname,
				comment:   params.comment,
				c_theme:   params.c_theme,
			}
			const res = await post('/webapi/client/rensai/create', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()
		},

		markFav: async function(m){
			let d = {
				rensai_id: this.rensai_id,
				mark:      0,
			}
			const res = await post('/webapi/client/rensai/mark', d)
			if(res.code != 0){
				console.error(res)
				return false
			}
			if(this.favs.marks.length < 2) this.favs.marks.push(0)
			this.favs.favs++
			this.favs.my_mark = 0
		},


		markCommentFav: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
				mark:      params.mark,
			}
			const res = await post('/webapi/client/rensai/mark_comment', d)
			if(res.code != 0){
				console.error(res)
				return false
			}

			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					const c = this.comments[i]
					//3つある場合は追加しない
					if(c.marks.length < 3) c.marks.push(params.mark)
					c.favs ++
					c.my_mark = params.mark
					break
				}
			}
		},

		delComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/del', d)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			//削除
			for(let i=0, l=this.comments.length; i<l; i++){
				if(this.comments[i].id == params.id){
					this.comments.splice(i, 1)
					break
				}
			}
		},

		repComment: async function(params){
			let d = {
				rensai_id: this.rensai_id,
				id:        params.id,
			}
			const res = await post('/webapi/client/rensai/rep', d)
			if(res.code != 0){
				console.error(res)
			}
		},

	},

	components: {
		Favorite,
		PostComment,
		Comment,
	},
}
</script>
