<template>
  <div id="app">
		<router-view />
  </div>
</template>
<script>
import { updateMetaHead } from '../lib/utils'

export default {
  mounted: function() {
    this.myUpdateMetaHead()
  },
  methods: {
    myUpdateMetaHead: function() {
      if (this.$route.meta.title) {
        if (this.$route.meta.desc) {
          updateMetaHead(this.$route.meta.title, this.$route.meta.desc)
        } else {
          updateMetaHead(this.$route.meta.title)
        }
      } else {
        updateMetaHead()
      }
    }
  },
  watch: {
    $route() {
      this.myUpdateMetaHead()
    }
  }
}
</script>
<style src="../style_zen_maru_gothic.css" />
<style src="../style.css" />

