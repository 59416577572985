const listAll = [
	{rensai_id: 14, tp: 'yasu', num: 5, name: "めちゃくちゃ高い利益率を誇る会社「ゴールドウィン（8111）」", dt: new Date('2024-02-16 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 13, tp: 'yasu', num: 4, name: "個別銘柄投資は、ハイリスクなのか？銘柄投資の醍醐味VSおまかせ運用のラクラク投信", dt: new Date('2023-01-19 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 11, tp: 'nobu', num: 3, name: "超短期投資手法その2<br>「市場の間違いを見抜く」<br>変則型逆張り投資とは", dt: new Date('2023-12-18 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 9, tp: 'yasu', num: 3, name: '躍進するスーパースイーツカンパニー「寿スピリッツ（2222）」', dt: new Date('2023-12-04 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 7, tp: 'nobu', num: 2, name: '超短期投資手法その１「3日以内で勝負を決める」超短期モーメンタム投資とは', dt: new Date('2023-11-08 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 5, tp: 'yasu', num: 2, name: 'ファーストリテイリングのスゴイ！理由', dt: new Date('2023-10-20 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 3, tp: 'nobu', num: 1, name: '短期投資のポイント', dt: new Date('2023-09-20 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
	{rensai_id: 1, tp: 'yasu', num: 1, name: '長期投資のポイント', dt: new Date('2023-09-08 10:00:00').getTime(), marks: [], favs: 0, my_mark: -1},
]

const now = new Date().getTime()
export const list = location.hostname == 'aisarekigyo.stockpoint.jp' ? listAll.filter( (d) => d.dt <= now ) : listAll

