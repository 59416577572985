import { comma, get_products, updateMetaHead } from '../../../lib/utils'
import axios from 'axios'

import Header from '../../components/Header.vue'
import PostComment from '../../components/PostComment3.vue'
import Comment from '../../components/Comment/Comment.vue'
import SortFilter from '../../components/SortFilter.vue'
import StockChart from '../../components/StockChart/StockChart.vue'
import Sdgs from '../../components/Sdgs.vue'
import CompanyNewsItem from '../../components/CompanyNewsItem/CompanyNewsItem.vue'
import Rensai from '../../components/Rensai.vue'

export default {
	data: function(){
		return {
			isReady: false,
			product: {
				img_url: '',
				name: '',
				market: '',
				favs: 0,
				fav_flg: false,
				concept1: '',
				concept2: '',
			},

			chartData:{
				latest_close: '0',
				prev: '0',
				prev_percent: '0',
				data: [],
				labels: [],
			},
			commentList: [], //コメントリスト
			sdgsList: [],
			isAdv: false, //広告
			companyNews: [], //注目の最新ニュース
			replies: {}, //返信コメントのオブジェクト
			newsReplies: {}, //ニュースへの返信コメントオブジェクト
			rensaiList: [],
		}
	},

	mounted: async function(){
		//銘柄データ取得
		const prdObj = await get_products(false)
		this.product = prdObj.obj[this.$route.params.c_code]

		updateMetaHead(this.product.name, this.product.concept1)

		this.isReady = true

		//注目の最新ニュース
		this.getNews()

		//株価
		this.getChart()

		//comments
		await this.getComments()

		//sdgs
		this.getSdgs()

		//連載取得
		this.getRensaiList()
	},

	methods: {
		linkTo: function(name){
			this.$router.push({name: name, params:{c_code: this.$route.params.c_code}})
		},

		delCb: function(id){
			//クライアントデータを削除
			for(let i=0, l=this.commentList.length; i<l; i++){
				let m = this.commentList[i]
				if(m.id == id){
					this.commentList.splice(i, 1)
					break
				}
			}
		},

		//株価
		getChart: async function(){
			const res = await this.$post('/webapi/client/chart/index', {c_code: this.$route.params.c_code}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.chartData.latest_close = comma(res.latest_close, 1).join('.')
			if(res.prev >= 0){
				this.chartData.prev = '+' + comma(res.prev, 1).join('.')
			}else{
				this.chartData.prev = comma(res.prev, 1).join('.')
			}
			if(res.prev_percent >= 0){
				this.chartData.prev_percent = '+' + comma(res.prev_percent, 2).join('.')
			}else{
				this.chartData.prev_percent = comma(res.prev_percent, 2).join('.')
			}
			this.$refs.stockChart.setData(res.data, res.labels)
		},

		//sdgs
		getSdgs: async function(){
			const res = await this.$post('/webapi/client/sdgs', {c_code: this.$route.params.c_code}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.sdgsList = res.list
		},

		//連載リスト取得
		getRensaiList: async function(){
			axios({
				url: '/static/index/rensai.json',
				responseType: 'json',
			}).then( (res) => {
				if(location.hostname == 'aisarekigyo.stockpoint.jp'){
					const now = new Date().getTime()
					this.rensaiList = res.data.filter( (r) => {
						const dtf = new Date(r.dt).getTime()
						const dte = new Date(r.dtEnd).getTime()
						return (dtf <= now && now < dte)
					})
				}else{
					this.rensaiList = res.data
				}
				this.rensaiList = this.rensaiList.slice(0, 2)
			}).catch( (e) => {
				console.error(e)
			})
		},

		postDone: async function(params){
			let d = {
				c_code:   this.product.c_code,
				nickname: params.nickname,
				comment:  params.comment,
				c_theme:  params.c_theme,
			}
			const res = await this.$post('/webapi/client/comment/create', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$refs.postComment.close()

			//投稿コメントをリストに追加
			this.commentList.unshift(res.comment)
		},

		pageScroll: function(to){
			let b = this.$refs[to]
			b.scrollIntoView({behavior: 'smooth'})
		},

		comma: function(num){
			return comma(num)
		},

		changeProductFav: async function(fav_flg){
			if(fav_flg) return false
			let res = await this.$post('/webapi/client/products/fav', {c_code: this.$route.params.c_code}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.$set(this.product, 'favs', this.product.favs + 1)
			this.product.fav_flg = true
			sessionStorage.removeItem('products')
		},

		imgError: function(){
			this.product.img_url = this.$cdn + '/img/main/noimage.svg'
		},

		//広告リンク（リダイレクト）
		advLink: function(){
			const url = this.$cdn + '/advs/' + this.$route.params.c_code + '.html'
			window.open(url, '_blank')
		},

		getComments: async function(){
			let sf = this.$refs.sortFilter
			let d = {
				c_code: this.$route.params.c_code,
				sort:   sf.sort,
			}
			if(sf.cTheme != -1) d.c_theme = sf.cTheme
			let res = await this.$post('/webapi/client/comment/search_detail', d, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.commentList = res.list.slice(0, 10)

			//返信コメント
			const ids = this.commentList.map( (c) => c.id )
			res = await this.$post('/webapi/client/reply/list', {comments_id: ids}, true)
			if(res.code != 0){
				return false
			}
			this.replies = res.replies
		},

		//注目の最新ニュース取得
		getNews: async function(){
			let res = await this.$post('/webapi/client/company_news/list', {c_code: this.$route.params.c_code, num: 3}, true)
			if(res.code != 0){
				alert(res.message)
				return false
			}
			this.companyNews = res.list

			//返信コメント
			const ids = this.companyNews.map( (c) => c.id )
			res = await this.$post('/webapi/client/reply_company_news/list', {news_id: ids}, true)
			if(res.code != 0){
				return false
			}
			this.newsReplies = res.replies
		},

	},

	components: {
		Header,
		PostComment,
		Comment,
		StockChart,
		Sdgs,
		CompanyNewsItem,
		SortFilter,
		Rensai,
	},

}
