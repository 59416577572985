let data = [
[141342,"u3099","orange","憧れてました","小さい頃エレベーターガールの声や仕草に憧れてました。","ジー","三越伊勢丹"],
[144607,"u2212","blue","朝食はほぼ山崎","朝ごはんはほぼ山崎。これからも応援しています！","たく5","山崎製パン"],
[141637,"u2212","red","糖質オフのパン","健康のために、糖質オフのパンを買うようになりました。いろんなメーカーのを試しましたが、ヤマザキの普通ルヴァンが使用されたものが一番美味しいと思います。身体に良くて、しかも美味しいパンに毎朝お世話になってます。","ウッディ2","山崎製パン"],
[144154,"u2897","orange","フォークでカップヌードル","昔あった、カップヌードルの自動販売機懐かしく思います。あの、フォークで食べる独特な食べ方懐かしいな〜と思いました。（先日、TVで懐かしの自動販売機の特集やってました。）","はぐはぐ","日清食品"],
[144331,"u2897","blue","あと一品に重宝","　茶碗のカップ麺シリーズ重宝してます。　あと一品欲しい時に助かります。","ハッピードラゴン","日清食品"],
[143443,"u3038","red","本当に助かりました","これからも、私たちの様な1人親でも子供達の食を減らさず済むような安価な食品の品揃えお願いいます。この20年本当に助かりました、ありがとうございます","ユウミク","神戸物産"],
[143726,"u3038","orange","東南アジアの食材","ベトナムやタイなどの東南アジアの食材を扱っているのが良いですね。ドリアンウエハース、ヒマワリの種など手に入るのがウレシイです。","マムトム","神戸物産"],
[144265,"u2651","blue","うれしいな","ローソンでパンを300円以上買って、午後ティーPETボトルいただきました。普通に朝ご飯でパン買っただけだったので、うれしいな。","スイミー","ローソン"],
[141377,"u2651","red","コーヒー牛乳パン","いつもお世話になっております。牛乳パンが食べやすくて好きです。コーヒー牛乳パンはレアすぎてあまり見かけないけど、見たら買い！","WatackPoint","ローソン"],
[142339,"u2651","orange","辛い焼きそば","最近冷凍の辛い焼そばにハマってます。ローソンでしか見た事ないのでローソン限定？これからも新商品期待してます！","ゆっち","ローソン"],
[142058,"u2651","blue","からあげクン ザパンチ","新しいからあげクンのザパンチ、美味しいですね！ビールと合いますよ！","半沢直樹","ローソン"],
[142211,"u2593","red","一択です","お～いお茶、一択です","むうかか","伊藤園"],
[142620,"u2802","orange","味の素ビルドアップフィルム（ABF）","半導体での【アミノ酸】に期待しています♪","HIME","味の素"],
[143822,"u2802","blue","こんなに美味しくなる","今までお恥ずかしながら味の素を使ったことがなかったのですが、料理家リュウジさんが使ってるの見て、使い始めました！こんなに美味しくなるもんなんですね笑これから愛用します！＼(^^)／","ち","味の素"],
[141350,"u2296","red","肉団子のファン","肉団子がおいしくて家族みんなファンです。夕飯作りたく無い時にメインのおかずに最適！これからも値段据え置きでお願いします。","はり","伊藤ハム米久ホールディングス"],
[143230,"u2296","orange","ビールと一緒に","アルトバイエルン。ビールと一緒にいただいてます。","デヨ","伊藤ハム米久ホールディングス"],
[144722,"u1332","blue","魚肉ソーセージ","ギョニソー大好きです","みゃう","ニッスイ"],
[142804,"u2294","red","ダブルエックス豚","ダブルエックス豚、美味いですよ。本当に感謝です。","平","柿安本店"],
[143436,"u2805","orange","お店で使っています","店舗で使う業務用カレールーを使っております。同業他社さんのもいろいろ試しましたがエスビーさんのが一番マッチしております😊カレールー以外でもジャンバラヤの素などがあり営業に助かっております。これからも新しい食材に期待しております。","ゆうさん","ヱスビー食品"],
[142111,"u7554","blue","満足のボリューム","ディナータイムの野菜炒め、量も多くていい","くまごろ","幸楽苑ホールディングス"],
[144751,"u9861","red","牛丼一筋80年","かつて、CMで『牛丼一筋80年～♪』と唄っていた時代、並盛か大盛、サイドメニューもみそ汁、お新香、たまご、牛皿のみだった頃からはや40年ほどの月日が流れ、時代の変化に対応してお品書きもバラエティに富んだものに変わりました。長い間お世話になっております。","タマゴニクロス","吉野家ホールディングス"],
[144924,"u2590","orange","飲んでミスティオ！","ダイドーってまだミスティオはあるのかな？あの炭酸が好きだったなぁ安室ちゃんやMAXのCMが懐かしいね","のちい","ダイドーグループ"],
[145086,"u7974","blue","夢や力を与えてくれる","毎日通学バスで１時間かけて支援学校に行っている小学１年生の息子に頑張っている御褒美にスーパーマリオワンダーをプレゼントしました。学校から帰ってすぐ毎日やってます。任天堂さんは子供達に夢や力を与えてくれる会社なので大好きです！！","こた","任天堂"],
[142311,"u7974","red","NO MUSIC, NO LIFE.","NO 任天堂、NO LIFE","ポムポム","任天堂"],
[144942,"u9684","orange","復活の呪文で再開","ドラクエ１をリアルタイムでプレイした時は、専用の呪文ノートを作り、いい大人がすっかりドラクエの虜になりました。それ以来、自動セーブに感動！すれ違い通信に感動！これからも、ワクワクするゲーム開発に期待しております。","ただっち","スクエニＨＤ"],
[141384,"u4452","blue","ビオレガード モスブロックセラム","あの蚊除けのローション、日本で売ってほしい。","クロック","花王"],
[141744,"u4452","red","ずっと使っている洗剤","洗剤はアタック。形は変わっているが、30年くらい使っています。大きな紙の箱からスプーンで掬っていた時代が懐かしい。","り","花王"],
[144991,"u4755","orange","楽天モバイル","楽天モバイル契約しました。いい感じです。問い合わせもしましたがオペレーターの方にも良くしてもらいました。","くー","楽天グループ"],
[143814,"u4901","blue","手術時間短縮に期待","AI手術ナビゲーションシステム市場に参入されるようですね。手術の精度向上・手術時間の短縮が期待されるので是非頑張って下さい！","ハッピードラゴン","富士フイルムホールディングス"],
[142728,"u8316","red","一生！","一生ホールド！！","t.tt.tttt","三井住友フィナンシャルグループ"],
]

export const list = data.map( (d) => {
	return {
		id:       d[0],
		c_code:   d[1],
		color:    d[2],
		title:    d[3],
		comment:  d[4],
		nickname: d[5],
		c_name:   d[6],
		marks:    [],
		favs:     0,
		my_mark:  -1,
	}
})

